/* tslint:disable */
/* eslint-disable */
/**
 * KGH PROJECT API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export const CodebookTypeEnum = {
    TypeOfEquipment: 1,
    TypeOfRefrigirationSystem: 2,
    EquipmentPurposes: 3,
    TypeOfEquipmentModification: 4,
    TypeOfQualifications: 5,
    BusinessActivity: 6,
    TypeOfCoolingSystem: 7,
    TypeOfStateOfSubstance:8,
} as const;

export type CodebookTypeEnum = typeof CodebookTypeEnum[keyof typeof CodebookTypeEnum];



