import { CodebookTypeEnum } from "api/models";
import { TFunction } from "i18next";

export const codebookClaimNames: Record<CodebookTypeEnum, string> = {
  [CodebookTypeEnum.TypeOfEquipment]: "type-of-equipments",
  [CodebookTypeEnum.TypeOfRefrigirationSystem]: "refrigeration-systems",
  [CodebookTypeEnum.EquipmentPurposes]: "equipment-purposes",
  [CodebookTypeEnum.TypeOfEquipmentModification]:
    "type-of-equipment-modification",
  [CodebookTypeEnum.TypeOfQualifications]: "type-of-qualifications",
  [CodebookTypeEnum.BusinessActivity]: "business-activities",
  [CodebookTypeEnum.TypeOfCoolingSystem]: "type-of-cooling-systems",
  [CodebookTypeEnum.TypeOfStateOfSubstance]: "type-of-state-of-substance",
};

export const getCodebookTextsByType = (
  t: TFunction<"translation", undefined>,
  type: CodebookTypeEnum
) => {
  const claimName = codebookClaimNames[type];
  const moduleName = `codebook-${claimName}`;

  const texts = {
    title: t(`${moduleName}:title`, claimName),
    add: t(`${moduleName}:add`, `Add ${claimName}`),
    deleteConfirm: t(
      `${moduleName}.delete-confirmation`,
      "Are you sure you want to delete?"
    ),
    editModalTitle: t(`${moduleName}:edit-modal-title`, `Edit ${claimName}`),
    addModalTitle: t(`${moduleName}:add-modal-title`, `Add ${claimName}`),
    editedSuccessfully: t(
      `${moduleName}:success-edit`,
      `${claimName} updated successfully!`
    ),
    addedSuccessfully: t(
      `${moduleName}:success-add`,
      `${claimName} created successfully!`
    ),
  };

  return texts;
};
