import FeatherIcon from "feather-icons-react";
import { NavLink } from "react-router-dom";

import { ProfileAuthorBox } from "./style";
import { Cards } from "components/cards/frame/cards-frame";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import loggedAsCompanyUser from "utility/loggedAsCompanyUser";
import { isInstitution } from "utility/decode-jwt";

const ProfileLink = styled(NavLink)`
  color: ${(props) => (props.active ? "#5F63F2" : "default")} !important;
  font-weight: ${(props) => (props.active ? "600" : "400")} !important;
`;

export const BoxTypes = {
  profile: "profile",
  companyInfo: "companyInfo",
  setPassword: "setPassword",
  employmentStatus: "employmentStatus",
  qualifications: 'qualifications',
};
function AuthorBox({ onClick, type, showEmploymentStatus }) {
  const { t } = useTranslation();
  const currentUserIsCompany = loggedAsCompanyUser();
  const currentUserIsInstitution = isInstitution();

  const labels = {
    profile: t("common:edit-profile", "Edit Profile"),
    companyInfo: t('common:company-info', 'Company Info'),
    setPassword: t("common:change-password", "Change Password"),
    employmentStatus: t("common:employment-status", "Employment status"),
    qualifiaction: t("common:employee-qualifications", "My Qualifications")
  };

  return (
    <ProfileAuthorBox>
      <Cards headless>
        <nav className="settings-menmulist">
          <ul>
            <li>
              <ProfileLink
                active={type === BoxTypes.profile}
                onClick={() => onClick(BoxTypes.profile)}
              >
                <FeatherIcon icon="user" size={14} />
                {labels.profile}
              </ProfileLink>
            </li>
            {(currentUserIsCompany || currentUserIsInstitution) && <li>
              <ProfileLink
                active={type === BoxTypes.companyInfo}
                onClick={() => onClick(BoxTypes.companyInfo)}
              >
                <FeatherIcon icon="server" size={14} />
                {labels.companyInfo}
              </ProfileLink>
            </li>}
            <li>
              <ProfileLink
                active={type === BoxTypes.setPassword}
                onClick={() => onClick(BoxTypes.setPassword)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-key"
                >
                  <path d="M21 2l-2 2m-7.61 7.61a5.5 5.5 0 1 1-7.778 7.778 5.5 5.5 0 0 1 7.777-7.777zm0 0L15.5 7.5m0 0l3 3L22 7l-3-3m-3.5 3.5L19 4" />
                </svg>
                {labels.setPassword}
              </ProfileLink>
            </li>
            {showEmploymentStatus && (
              <li>
                <ProfileLink
                  active={type === BoxTypes.employmentStatus}
                  onClick={() => onClick(BoxTypes.employmentStatus)}
                >
                  <FeatherIcon icon="briefcase" size={14} />
                  {labels.employmentStatus}
                </ProfileLink>
              </li>
            )}

        {showEmploymentStatus && (
              <li>
                <ProfileLink
                  active={type === BoxTypes.qualifications}
                  onClick={() => onClick(BoxTypes.qualifications)}
                >
                  <FeatherIcon icon="award" size={14} />
                  {labels.qualifiaction}
                </ProfileLink>
              </li>
            )}
          </ul>
        </nav>
      </Cards>
    </ProfileAuthorBox>
  );
}

AuthorBox.propTypes = {};

export default AuthorBox;
