import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { CustomRouteProps, MainRoutes } from "router/Routes";

const MainRoutesDictionary: Record<string, CustomRouteProps> = {};

MainRoutes.forEach((route) => {
  MainRoutesDictionary[route.path ?? ""] = route;
});

export const useCheckRouteAccess = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const currentRoute = MainRoutesDictionary[location.pathname] ?? null;

    if (!currentRoute) {
      return;
    }

    if (!currentRoute.hasAccess()) {
      navigate("/", { replace: true });
    }
  }, [location, navigate]);
};
