import { getClaims } from "utility/getClaims";

export const menuItemsList = (t: any) => ({
  dashboardLog: {
    key: "dashboardLog",
    label: t("side-bar:dashboard", "Dashboard"),
    path: "/",
    icon: "home",
    claimName: "dashboard",
  },
  dashboard: {
    key: "dashboard",
    label: t("side-bar:administration", "Administration"),
    icon: "users",
    subMenuItems: [
      {
        key: "users",
        label: t("side-bar:administration.users", "Users"),
        path: "/users",
        claimName: "users",
      },
      {
        key: "user-groups",
        label: t("side-bar:administration.user-groups", "User Groups"),
        path: "/user-groups",
        claimName: "user-groups",
      },
      {
        key: "institutions",
        label: t("side-bar:administration.institutions", "Institutions"),
        path: "/institutions",
        claimName: "institutions:list",
        checkOnlyPermission: true,
      },
      {
        key: "branches",
        label: t("side-bar:administration.branches", "Branches"),
        path: "/branches",
        claimName: "branches",
      },
      {
        key: "database-connections",
        label: t("side-bar:administration.database-connections", "Database Connections"),
        path: "/database-connections",
        claimName: 'database-connections:list',
        checkOnlyPermission: true,
      },
      {
        key: "company-technicians",
        label: t(
          "side-bar:administration.company-technicians",
          "Company Certified Technicians"
        ),
        path: "/company-technicians",
        claimName: "company-technicians:list",
        checkOnlyPermission: true,
      },
    ],
  },
  configurations: {
    key: "configurations",
    label: t("side-bar:configurations", "Configurations"),
    icon: "settings",
    subMenuItems: [
      {
        key: "email",
        label: t("side-bar:configurations.email", "Email"),
        path: "/email",
        claimName: "email-options",
      },
      {
        key: "translations",
        label: t("side-bar:configurations.translations", "Translations"),
        path: "/translations",
        claimName: "translations",
      },
      {
        key: "languages",
        label: t("side-bar:configurations.languages", "Languages"),
        path: "/languages",
        claimName: "languages",
      },
    ],
  },
  codebooks: {
    key: "codebooks",
    label: t("side-bar:codebooks", "Codebooks"),
    icon: "file-text",
    subMenuItems: [
      {
        key: "type-of-equipments",
        label: t("side-bar:codebooks.type-of-equipments", "Type of Equipments"),
        path: "/type-of-equipments",
        claimName: "type-of-equipments",
      },
      {
        key: "refrigeration-systems",
        label: t(
          "side-bar:codebooks.refrigeration-systems",
          "Refrigeration Systems"
        ),
        path: "/refrigeration-systems",
        claimName: "refrigeration-systems",
      },
      {
        key: "equipment-purposes",
        label: t("side-bar:codebooks.equipment-purpose", "Equipment Purposes"),
        path: "/equipment-purposes",
        claimName: "equipment-purposes",
      },
      {
        key: "type-of-equipment-modification",
        label: t(
          "side-bar:codebooks.type-of-equipment-modification",
          "Type of Equipment modification"
        ),
        path: "/type-of-equipment-modification",
        claimName: "type-of-equipment-modification",
      },
      {
        key: "type-of-cooling-systems",
        label: t(
          "side-bar:codebooks.type-of-cooling-systems",
          "Type of Cooling Systems"
        ),
        path: "/type-of-cooling-systems",
        claimName: "type-of-cooling-systems",
      },
      {
        key: "type-of-state-of-substance",
        label: t(
          "side-bar:codebooks.type-of-state-of-substance",
          "State Of Substance Types"
        ),
        path: "/type-of-state-of-substance",
        claimName: "type-of-state-of-substance",
      },
      {
        key: "type-of-qualifications",
        label: t(
          "side-bar:codebooks.type-of-qualifications",
          "Type of Qualifications"
        ),
        path: "/type-of-qualifications",
        claimName: "type-of-qualifications",
      },
      {
        key: "business-activities",
        label: t(
          "side-bar:codebooks.business-activities",
          "Business Activities"
        ),
        path: "/business-activities",
        claimName: "business-activities",
      },
      {
        key: "municipalities",
        label: t("side-bar:codebooks.municipalities", "Municipalities"),
        path: "/municipalities",
        claimName: "municipalities",
      },
      {
        key: "cantons",
        label: t("side-bar:codebooks.cantons", "Cantons"),
        path: "/cantons",
        claimName: "cantons",
      },
      {
        key: "entities",
        label: t("side-bar:codebooks.entities", "Entities"),
        path: "/entities",
        claimName: "entities",
      },
    ],
  },
  requests: {
    key: "requests",
    label: t("side-bar:requests", "Requests"),
    icon: "save",
    subMenuItems: [
      {
        key: "requests-awaiting-approval",
        label: t(
          "side-bar:requests.requests-awaiting-approval",
          "Requests awaiting approval"
        ),
        path: "/requests",
        claimName: "requests:list-awaiting-approval",
        checkOnlyPermission: true,
      },
      {
        key: "new-requests",
        label: t("side-bar:new-requests", "New requests"),
        subMenuItems: [
          {
            key: "add-requests-owners-operators-of-kgh-equipment",
            label: t(
              "side-bar:add-requests-owners-operators-of-kgh-equipment",
              "Owners and Operators of KGH equipment"
            ),
            path: "/requests/create/1",
            claimName:
              "requests:request-for-adding-owners-and-operators-of-kgh-equipment",
            checkOnlyPermission: true,
          },
          {
            key: "add-requests-kgh-service-companies-entrepreneurs",
            label: t(
              "side-bar:add-requests-kgh-service-companies-entrepreneurs",
              "KGH service companies/entrepreneurs"
            ),
            path: "/requests/create/2",
            claimName:
              "requests:request-for-adding-and-licensing-of-kgh-companies",
            checkOnlyPermission: true,
          },
          {
            key: "request-for-adding-importers-exporters-of-kgh-equipment",
            label: t(
              "side-bar:request-for-adding-importers-exporters-of-kgh-equipment",
              "Importers/Exporters of KGH equipment"
            ),
            path: "/requests/create/3",
            claimName:
              "requests:request-for-adding-importers-exporters-of-kgh-equipment",
            checkOnlyPermission: true,
          },
          {
            key: "add-requests-for-license-extension-of-kgh-companies",
            label: t(
              "side-bar:add-request-for-license-extension-of-kgh-companies",
              "License extension for service companies/entrepreneurs"
            ),
            path: "/requests/create/4",
            claimName:
              "requests:request-for-extending-license-of-kgh-companies",
            checkOnlyPermission: true,
          },
        ],
      },
      {
        key: "archived-requests",
        label: t("side-bar:requests.archived", "Archived requests"),
        path: "/archived-requests",
        claimName: "requests:list-archived",
        checkOnlyPermission: true,
      },
    ],
  },
  registers: {
    key: "registers",
    label: t("side-bar:registers", "Registers"),
    icon: "clipboard",
    subMenuItems: [
      {
        key: "all-registers",
        label: t(
          "side-bar:registers.all-companies-entrepreneurs",
          "All registered companies/entrepreneurs"
        ),
        path: "/registers/all-companies-entrepreneurs",
        claimName: "registers:list-all-registered-companies-entrepreneurs",
        checkOnlyPermission: true,
      },
      {
        key: "registers-owner-operators-of-kgh-equipment",
        label: t(
          "side-bar:registers.owner-operators-of-kgh-equipment",
          "Owners and Operators of KGH equipment"
        ),
        path: "/registers/owners-operators-of-kgh-equipment",
        claimName: "registers:list-of-owners-and-operators-of-kgh-equipment",
        checkOnlyPermission: true,
      },
      {
        key: "registers-recoded-marked-equipment",
        label: t(
          "side-bar:registers.marked-equipment",
          "Recorded/Marked equipment"
        ),
        path: "/registers/marked-equipment",
        claimName: "registers:list-of-marked-equipment",
        checkOnlyPermission: true,
      },
      {
        key: "registers-kgh-service-companies",
        label: t(
          "side-bar:registers.kgh-service-companies",
          "KGH service companies"
        ),
        path: "/registers/kgh-service-companies",
        claimName: "registers:list-of-kgh-companies",
        checkOnlyPermission: true,
      },
      {
        key: "registers-certified-technicians",
        label: t(
          "side-bar:registers.certified-technicians",
          "Certified Service Technicians"
        ),
        path: "/registers/certified-technicians",
        claimName: "registers:list-of-certified-technicians",
        checkOnlyPermission: true,
      },
      {
        key: "registers-importers",
        label: t("side-bar:registers.importers", "Importers"),
        path: "/registers/importers",
        claimName: "registers:list-of-importers",
        checkOnlyPermission: true,
      },
    ],
  },
  equipments: {
    key: "equipments",
    label: t("side-bar:equipments", "Equipment"),
    path: "/equipments",
    icon: "tool",
    claimName: "equipments",
  },
  reports: {
    key: "reports",
    label: t("side-bar:reports", "Reports"),
    path: "/reports",
    icon: "bar-chart-2",
    claimName: "reports",

    subMenuItems: [
      {
        key: "reports-technicians-by-training-center",
        label: t(
          "side-bar:reports.technicians-by-training-center",
          "Technicians by Training Center"
        ),
        path: "/reports/technicians-by-training-center",
        claimName: "reports:certified-technicians-by-training-center",
        checkOnlyPermission: true,
      },
      {
        key: "reports-technicians-by-qualifications",
        label: t(
          "side-bar:reports.technicians-by-qualifications",
          "Technicians by Qualifications"
        ),
        path: "/reports/technicians-by-qualifications",
        claimName: "reports:certified-technicians-by-certification-category",
        checkOnlyPermission: true,
      },
      {
        key: "reports-technicians-by-entity",
        label: t(
          "side-bar:reports.technicians-by-entity",
          "Technicians by Entity"
        ),
        path: "/reports/technicians-by-entity",
        claimName: "reports:certified-technicians-by-entity",
        checkOnlyPermission: true,
      },
      {
        key: "reports-equipments-by-municipality",
        label: t(
          "side-bar:reports.equipments-by-municipality",
          "Equipments by Municipality"
        ),
        path: "/reports/equipments-by-municipality",
        claimName: "reports:kgh-equipment-by-municipalities",
        checkOnlyPermission: true,
      },
      {
        key: "reports-equipments-by-purpose",
        label: t(
          "side-bar:reports.equipments-by-purpose",
          "Equipments by Purpose"
        ),
        path: "/reports/equipments-by-purpose",
        claimName: "reports:kgh-equipment-by-purpose-and-use",
        checkOnlyPermission: true,
      },
      {
        key: "reports-equipments-by-cooling-system",
        label: t(
          "side-bar:reports.equipments-by-cooling-system",
          "Equipments by Cooling System"
        ),
        path: "/reports/equipments-by-cooling-system",
        claimName: "reports:kgh-equipment-by-cooling-medium",
        checkOnlyPermission: true,
      },
      {
        key: "reports-companies-by-entity",
        label: t("side-bar:reports.companies-by-entity", "Companies by Entity"),
        path: "/reports/companies-by-entity",
        claimName: "reports:kgh-service-companies-by-entity",
        checkOnlyPermission: true,
      },
      {
        key: "reports-refrigerants-by-entity",
        label: t(
          "side-bar:reports.refrigerants-by-entity",
          "Refrigerants by Entity"
        ),
        path: "/reports/refrigerants-by-entity",
        claimName: "reports:refrigerants-by-entity",
        checkOnlyPermission: true,
      },
      {
        key: "reports-service-companies",
        label: t(
          "side-bar:reports.service-companies",
          "Service Companies"
        ),
        path: "/reports/service-companies",
        claimName: "reports:kgh-service-companies",
        checkOnlyPermission: true,
        },
        {
            key: "reports-annual-report-service-technician",
            label: t(
                "side-bar:reports.annual-report-on-collected-substances",
                "Annual Report On Collected Substances"
            ),
            path: "/reports/annual-report-service-technician",
            claimName: "reports:annual-report-service-technician",
            checkOnlyPermission: true,
        },
        {
            key: "reports-mvteo-annual-report-service-technician",
            label: t(
                "side-bar:reports.mvteo-annual-report-on-collected-substances",
                "MVTEO Annual Report On Collected Substances"
            ),
            path: "/reports/mvteo-annual-report-service-technician",
            claimName: "reports:mvteo-annual-report-service-technician",
            checkOnlyPermission: true,
        },
        {
            key: "reports-annual-report-on-import-export-substances",
            label: t(
                "side-bar:reports.annual-report-import-export-of-ozone-depleting-substance",
                "Annual Report On Import/Export Of Ozone Depleting Substance"
            ),
            path: "/reports/annual-report-on-import-export-substances",
            claimName: "reports:annual-report-on-import-export-substances",
            checkOnlyPermission: true,
        },
        {
            key: "reports-mvteo-annual-report-on-import-export-substances",
            label: t(
                "side-bar:reports.mvteo-annual-report-import-export-of-ozone-depleting-substance",
                "MVTEO Annual Report On Import/Export Of Ozone Depleting Substance"
            ),
            path: "/reports/mvteo-annual-report-on-import-export-substances",
            claimName: "reports:mvteo-annual-report-on-import-export-substances",
            checkOnlyPermission: true,
        },
    ],
  },
  logs: {
    key: "logs",
    label: t("side-bar:logs", "Logs"),
    path: "/logs",
    icon: "rewind",
    claimName: "logs",
  },
  notifications: {
    key: "notifications",
    label: t("side-bar:newsfeed", "Newsfeed"),
    path: "/newsfeed",
    icon: "bell",
    claimName: "notifications",
  },
});

export const getCurrentUserModules = (): string[] => {
  const claims = getClaims();
  const modules = claims.map((x) => x?.split(":")[0]);
  return [...new Set<string>(modules)];
};
