import { Button, Card, Col, Form, Input, Row, Select } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { OrganizationsApi, UsersApi } from "api/api";
import { AccessLevelType } from "api/models";
import { CommonDataContext } from "contexts/CommonDataContext/CommonDataContext";
import FeatherIcon from "feather-icons-react";
import { useAccessLevel } from "hooks/useAccessLevel";
import { useDebouncedValue } from "hooks/useDebouncedValue";
import { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import openNotificationWithIcon from "utility/notification";
import Heading from "../../components/heading/heading";
import { Steps } from "../../components/steps/steps";
import { BasicFormWrapper } from "../../container/styled";
import { WizardFour, WizardWrapper } from "../style";
import { PredefinedRoles } from "constants/constants";
import loggedAsCompanyUser from "utility/loggedAsCompanyUser";
const { Option } = Select;

const usersApi = new UsersApi();
const organizationsApi = new OrganizationsApi();

function UserWizzard({ user }) {
  const commonData = useContext(CommonDataContext);
  const { userGroups, languages } = commonData;
  const onEditMode = user !== null;

  const navigate = useNavigate();
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const accessLevel = useAccessLevel();
  const [institutions, setInstitutions] = useState([]);

  const [request, setRequest] = useState({});
  const [isSaving, setIsSaving] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [userAlreadyExists, setUserAlreadyExists] = useState();

  const emailDebouncedValue = useDebouncedValue({
    delay: 300,
    value: Form.useWatch("email", form),
  });
  const currentUserIsCompany = loggedAsCompanyUser();

  useEffect(() => {
    const fetchInstitutions = async () => {
      const {
        data: { items },
      } = await organizationsApi.apiOrganizationsGet({ pageSize: -1 });

      setInstitutions(items.sort((a, b) => a.name.localeCompare(b.name)));
    };

    fetchInstitutions();
  }, []);

  const validateUser = useCallback(async () => {
    try {
      const email = form.getFieldValue("email")?.trim();
      if (!email) return;

      const response = await usersApi.usersAvailableEmailGet({ email });

      if (!response.data) {
        setUserAlreadyExists(true);
        form.setFields([
          {
            name: "email",
            errors: [
              t(
                "validations.user-email-already-exists",
                "User email already exists!"
              ),
            ],
          },
        ]);
        return;
      }

      setUserAlreadyExists(false);
    } catch (err) {
      console.error(err);
    }
  }, [form, setUserAlreadyExists, t]);

  useEffect(() => {
    if (!onEditMode) validateUser();
  }, [onEditMode, emailDebouncedValue]);

  useEffect(() => {
    if (onEditMode) {
      form.setFieldsValue(user);
    } else {
      form.setFieldsValue({ userGroups: userGroups?.map((x) => x.name) });
    }
  }, [user, userGroups]);

  const onFinish = async (data) => {
    if (currentStep === 0) {
      if (userAlreadyExists) {
        return;
      }

      setCurrentStep(currentStep + 1);
      setRequest(data);
      return;
    }

    await upsertUser({ ...request, ...data });
  };

  const upsertUser = async (data) => {
    setIsSaving(true);

    const labels = {
      updated: t("users:user.updated", "User Updated"),
      created: t("users:user.created", "User Created"),
      updatedDescription: t(
        "users:user.updated.description",
        "User's information have been updated"
      ),
      createdDescription: t(
        "users:user.created.description",
        "New user has been successfully created!"
      ),
      updatedFailed: t("users:failed.update.user", "Update Failed!"),
      createdFailed: t("users:failed.create.user", "User Creation Failed"),
      updatedFailedDescription: t(
        "users:failed.update.user.description",
        "User couldn't be updated!"
      ),
      createdFailedDescription: t(
        "users:failed.create.user.description",
        "User couldn't be created!"
      ),
    };

    try {
      const response = onEditMode
        ? await usersApi.usersIdPut({ id: user.id, updateUserCommand: data })
        : await usersApi.usersPost({ createUserCommand: data });

      if (response.status === 200) {
        navigate("/users");
        openNotificationWithIcon(
          "success",
          onEditMode ? labels.updated : labels.created,
          onEditMode ? labels.updatedDescription : labels.createdDescription
        );
      }
    } catch (err) {
      openNotificationWithIcon(
        "error",
        onEditMode ? labels.updatedFailed : labels.createdFailed,
        onEditMode
          ? labels.updatedFailedDescription
          : labels.createdFailedDescription
      );
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <Card>
      <WizardWrapper>
        <WizardFour>
          <Steps
            key={currentStep}
            isvertical
            isswitch
            current={currentStep}
            direction="vertical"
            steps={[
              {
                title: t("users:profile", "Profile"),
                content: (
                  <BasicFormWrapper className="basic-form-inner">
                    <div className="atbd-form-checkout">
                      <Row justify="center">
                        <Col xs={24}>
                          <div className="create-account-form">
                            <Heading as="h4">
                              {t("users:users.profile", "User's Profile")}
                            </Heading>
                            <Form
                              form={form}
                              name="account"
                              requiredMark
                              onFinish={onFinish}
                            >
                              <Form.Item
                                name="email"
                                label={t(
                                  "user-view-details.email",
                                  "Email Address"
                                )}
                                requiredMark
                                tooltip={t(
                                  "users:email.usage.purpose.tooltip",
                                  "This email will be used for logging into the app"
                                )}
                                rules={[
                                  {
                                    required: true,
                                    message: t(
                                      "users.create:email-validation",
                                      "Email is required"
                                    ),
                                    pattern:
                                      /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                  },
                                ]}
                                required
                              >
                                <Input
                                  prefix={<FeatherIcon icon="mail" size={14} />}
                                  placeholder={t(
                                    "user-view-details.email",
                                    "Email Address"
                                  )}
                                  disabled={user !== null}
                                />
                              </Form.Item>
                              <Form.Item
                                name="firstName"
                                required
                                label={t("global:first.name", "First Name")}
                              >
                                <Input
                                  placeholder={
                                    t("global:first.name", "First Name") + "*"
                                  }
                                  required
                                  disabled={userAlreadyExists}
                                  prefix={<FeatherIcon icon="user" size={14} />}
                                />
                              </Form.Item>

                              <Form.Item
                                name="lastName"
                                required
                                label={t("global:last.name", "Last Name")}
                              >
                                <Input
                                  disabled={userAlreadyExists}
                                  placeholder={
                                    t("global:last.name", "Last Name") + "*"
                                  }
                                  prefix={
                                    <FeatherIcon icon="users" size={14} />
                                  }
                                  required
                                />
                              </Form.Item>

                              <Form.Item
                                name="phoneNumber"
                                label={t("global.phone-number	", "Phone Number")}
                              >
                                <Input
                                  disabled={userAlreadyExists}
                                  placeholder={t(
                                    "global.phone-number	",
                                    "Phone Number"
                                  )}
                                  prefix={
                                    <FeatherIcon icon="phone" size={14} />
                                  }
                                />
                              </Form.Item>

                              {accessLevel > AccessLevelType.NUMBER_1 && (
                                <Form.Item
                                  name="organizationId"
                                  required
                                  label={t(
                                    "global:select.institution",
                                    "Select Institution"
                                  )}
                                  requiredMark
                                  rules={[
                                    {
                                      required: true,
                                      message: t(
                                        "validations:institution.required",
                                        "Select Institution"
                                      ),
                                    },
                                  ]}
                                >
                                  <Select
                                    className="sDash_fullwidth-select"
                                    aria-required
                                    style={{ color: "rgb(90, 95, 125)" }}
                                  >
                                    {institutions &&
                                      institutions.map((item) => (
                                        <Option key={item.id} value={item.id}>
                                          {item.name}
                                        </Option>
                                      ))}
                                  </Select>
                                </Form.Item>
                              )}

                              <Form.Item
                                name="languageId"
                                required
                                label={t(
                                  "system-languages.default-language",
                                  "Default Language"
                                )}
                                requiredMark
                                rules={[
                                  {
                                    required: true,
                                    message: t(
                                      "validations:language.required",
                                      "Please select language"
                                    ),
                                  },
                                ]}
                              >
                                <Select
                                  className="sDash_fullwidth-select"
                                  aria-required
                                  style={{ color: "rgb(90, 95, 125)" }}
                                  disabled={userAlreadyExists}
                                >
                                  {languages &&
                                    languages.map((item) => (
                                      <Option key={item.id} value={item.id}>
                                        {item.name}
                                      </Option>
                                    ))}
                                </Select>
                              </Form.Item>

                              <Form.Item
                                name="comments"
                                label={t("global:comments", "Comments")}
                              >
                                <TextArea
                                  disabled={userAlreadyExists}
                                  placeholder={t("global:comments", "Comments")}
                                  rows={3}
                                  prefix={
                                    <FeatherIcon
                                      icon="message-circle"
                                      size={14}
                                    />
                                  }
                                />
                              </Form.Item>

                              <div className="steps-action">
                                <Button
                                  className="btn-next"
                                  type="primary"
                                  htmlType="submit"
                                  disabled={userAlreadyExists}
                                  style={{ height: 50, padding: "10px 20px" }}
                                >
                                  {t("global:next", "Next")}
                                  <FeatherIcon icon="arrow-right" size={16} />
                                </Button>
                              </div>
                            </Form>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </BasicFormWrapper>
                ),
              },
              {
                title: t("global:permissions", "Permissions"),
                content: (
                  <BasicFormWrapper className="basic-form-inner">
                    <div className="atbd-form-checkout">
                      <Row justify="center">
                        <Col xs={24}>
                          <div className="create-account-form">
                            <Heading as="h4">
                              {t(
                                "global:users.permission",
                                "User's Permissions"
                              )}
                            </Heading>
                            <Form
                              form={form}
                              name="account"
                              requiredMark
                              onFinish={onFinish}
                            >
                              <Form.Item
                                name="userGroups"
                                required
                                label={t(
                                  "global:select.user.groups",
                                  "Select User Groups"
                                )}
                                rules={[
                                  {
                                    required: true,
                                    message: t(
                                      "validations.user.group.required",
                                      "Please select at least one group"
                                    ),
                                  },
                                ]}
                              >
                                <Select
                                  mode="multiple"
                                  style={{ width: "100%" }}
                                  placeholder={t(
                                    "global:please.select",
                                    "Please select"
                                  )}
                                  className="basic-multi-select"
                                >
                                  {userGroups &&
                                    userGroups.map((item) => (
                                      <Option
                                        value={item?.name}
                                        key={item?.name}
                                        disabled={Object.values(PredefinedRoles).includes(item?.name) && currentUserIsCompany}
                                      >
                                        {item?.name}
                                      </Option>
                                    ))}
                                </Select>
                              </Form.Item>

                              <div
                                className="steps-action"
                                style={{ display: "flex", gap: 20 }}
                              >
                                <Button
                                  type="default"
                                  style={{ height: 50, padding: "10px 20px" }}
                                  onClick={() => setCurrentStep(0)}
                                >
                                  {t("global:go.back", "Go back")}{" "}
                                  <FeatherIcon icon="arrow-left" size={16} />
                                </Button>
                                <Button
                                  className="btn-next"
                                  type="primary"
                                  loading={isSaving}
                                  htmlType="submit"
                                  style={{ height: 50, padding: "10px 20px" }}
                                >
                                  {!onEditMode
                                    ? t(
                                      "users:complete.registration",
                                      "Complete Registration"
                                    )
                                    : t("users:update.user", "Update User")}
                                  <FeatherIcon icon="user-check" size={16} />
                                </Button>
                              </div>
                            </Form>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </BasicFormWrapper>
                ),
              },
            ]}
            isfinished
          />
        </WizardFour>
      </WizardWrapper>
    </Card>
  );
}

export default UserWizzard;
