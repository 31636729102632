/* tslint:disable */
/* eslint-disable */
/**
 * KGH PROJECT API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export const RequestType = {
    RegistrationOfOwnersAndOperators: 1,
    RegistrationAndLicensingOfServiceCompanies: 2,
    RegistrationAndLicensingOfImportersExporters: 3,
    RequestForExtensionOfLicenseOfServiceCompanies: 4
} as const;

export type RequestType = typeof RequestType[keyof typeof RequestType];



