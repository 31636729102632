/* tslint:disable */
/* eslint-disable */
/**
 * KGH PROJECT API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { OrganizationDto } from '../models';
// @ts-ignore
import { OrganizationDtoPaginatedList } from '../models';
// @ts-ignore
import { OrganizationRequest } from '../models';
// @ts-ignore
import { OrganizationStatus } from '../models';
/**
 * OrganizationsApi - axios parameter creator
 * @export
 */
export const OrganizationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [sortBy] 
         * @param {string} [sortType] 
         * @param {string} [search] 
         * @param {string} [municipalityId] 
         * @param {string} [entityId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganizationsGet: async (pageNumber?: number, pageSize?: number, sortBy?: string, sortType?: string, search?: string, municipalityId?: string, entityId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Organizations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (pageNumber !== undefined) {
                localVarQueryParameter['PageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['SortBy'] = sortBy;
            }

            if (sortType !== undefined) {
                localVarQueryParameter['SortType'] = sortType;
            }

            if (search !== undefined) {
                localVarQueryParameter['Search'] = search;
            }

            if (municipalityId !== undefined) {
                localVarQueryParameter['MunicipalityId'] = municipalityId;
            }

            if (entityId !== undefined) {
                localVarQueryParameter['EntityId'] = entityId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganizationsIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiOrganizationsIdDelete', 'id', id)
            const localVarPath = `/api/Organizations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganizationsIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiOrganizationsIdGet', 'id', id)
            const localVarPath = `/api/Organizations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} idNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganizationsIdNumberIdNumberGet: async (idNumber: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idNumber' is not null or undefined
            assertParamExists('apiOrganizationsIdNumberIdNumberGet', 'idNumber', idNumber)
            const localVarPath = `/api/Organizations/id-number/{idNumber}`
                .replace(`{${"idNumber"}}`, encodeURIComponent(String(idNumber)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganizationsIdPatch: async (id: string, body?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiOrganizationsIdPatch', 'id', id)
            const localVarPath = `/api/Organizations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {OrganizationRequest} [organizationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganizationsIdPut: async (id: string, organizationRequest?: OrganizationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiOrganizationsIdPut', 'id', id)
            const localVarPath = `/api/Organizations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(organizationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {OrganizationRequest} [organizationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganizationsPost: async (organizationRequest?: OrganizationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Organizations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(organizationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrganizationsApi - functional programming interface
 * @export
 */
export const OrganizationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrganizationsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [sortBy] 
         * @param {string} [sortType] 
         * @param {string} [search] 
         * @param {string} [municipalityId] 
         * @param {string} [entityId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOrganizationsGet(pageNumber?: number, pageSize?: number, sortBy?: string, sortType?: string, search?: string, municipalityId?: string, entityId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrganizationDtoPaginatedList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOrganizationsGet(pageNumber, pageSize, sortBy, sortType, search, municipalityId, entityId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOrganizationsIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOrganizationsIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOrganizationsIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrganizationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOrganizationsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} idNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOrganizationsIdNumberIdNumberGet(idNumber: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrganizationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOrganizationsIdNumberIdNumberGet(idNumber, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOrganizationsIdPatch(id: string, body?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOrganizationsIdPatch(id, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {OrganizationRequest} [organizationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOrganizationsIdPut(id: string, organizationRequest?: OrganizationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOrganizationsIdPut(id, organizationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {OrganizationRequest} [organizationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOrganizationsPost(organizationRequest?: OrganizationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOrganizationsPost(organizationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OrganizationsApi - factory interface
 * @export
 */
export const OrganizationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrganizationsApiFp(configuration)
    return {
        /**
         * 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [sortBy] 
         * @param {string} [sortType] 
         * @param {string} [search] 
         * @param {string} [municipalityId] 
         * @param {string} [entityId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganizationsGet(pageNumber?: number, pageSize?: number, sortBy?: string, sortType?: string, search?: string, municipalityId?: string, entityId?: string, options?: any): AxiosPromise<OrganizationDtoPaginatedList> {
            return localVarFp.apiOrganizationsGet(pageNumber, pageSize, sortBy, sortType, search, municipalityId, entityId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganizationsIdDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiOrganizationsIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganizationsIdGet(id: string, options?: any): AxiosPromise<OrganizationDto> {
            return localVarFp.apiOrganizationsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} idNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganizationsIdNumberIdNumberGet(idNumber: string, options?: any): AxiosPromise<OrganizationDto> {
            return localVarFp.apiOrganizationsIdNumberIdNumberGet(idNumber, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganizationsIdPatch(id: string, body?: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiOrganizationsIdPatch(id, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {OrganizationRequest} [organizationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganizationsIdPut(id: string, organizationRequest?: OrganizationRequest, options?: any): AxiosPromise<void> {
            return localVarFp.apiOrganizationsIdPut(id, organizationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {OrganizationRequest} [organizationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganizationsPost(organizationRequest?: OrganizationRequest, options?: any): AxiosPromise<void> {
            return localVarFp.apiOrganizationsPost(organizationRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiOrganizationsGet operation in OrganizationsApi.
 * @export
 * @interface OrganizationsApiApiOrganizationsGetRequest
 */
export interface OrganizationsApiApiOrganizationsGetRequest {
    /**
     * 
     * @type {number}
     * @memberof OrganizationsApiApiOrganizationsGet
     */
    readonly pageNumber?: number

    /**
     * 
     * @type {number}
     * @memberof OrganizationsApiApiOrganizationsGet
     */
    readonly pageSize?: number

    /**
     * 
     * @type {string}
     * @memberof OrganizationsApiApiOrganizationsGet
     */
    readonly sortBy?: string

    /**
     * 
     * @type {string}
     * @memberof OrganizationsApiApiOrganizationsGet
     */
    readonly sortType?: string

    /**
     * 
     * @type {string}
     * @memberof OrganizationsApiApiOrganizationsGet
     */
    readonly search?: string

    /**
     * 
     * @type {string}
     * @memberof OrganizationsApiApiOrganizationsGet
     */
    readonly municipalityId?: string

    /**
     * 
     * @type {string}
     * @memberof OrganizationsApiApiOrganizationsGet
     */
    readonly entityId?: string
}

/**
 * Request parameters for apiOrganizationsIdDelete operation in OrganizationsApi.
 * @export
 * @interface OrganizationsApiApiOrganizationsIdDeleteRequest
 */
export interface OrganizationsApiApiOrganizationsIdDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof OrganizationsApiApiOrganizationsIdDelete
     */
    readonly id: string
}

/**
 * Request parameters for apiOrganizationsIdGet operation in OrganizationsApi.
 * @export
 * @interface OrganizationsApiApiOrganizationsIdGetRequest
 */
export interface OrganizationsApiApiOrganizationsIdGetRequest {
    /**
     * 
     * @type {string}
     * @memberof OrganizationsApiApiOrganizationsIdGet
     */
    readonly id: string
}

/**
 * Request parameters for apiOrganizationsIdNumberIdNumberGet operation in OrganizationsApi.
 * @export
 * @interface OrganizationsApiApiOrganizationsIdNumberIdNumberGetRequest
 */
export interface OrganizationsApiApiOrganizationsIdNumberIdNumberGetRequest {
    /**
     * 
     * @type {string}
     * @memberof OrganizationsApiApiOrganizationsIdNumberIdNumberGet
     */
    readonly idNumber: string
}

/**
 * Request parameters for apiOrganizationsIdPatch operation in OrganizationsApi.
 * @export
 * @interface OrganizationsApiApiOrganizationsIdPatchRequest
 */
export interface OrganizationsApiApiOrganizationsIdPatchRequest {
    /**
     * 
     * @type {string}
     * @memberof OrganizationsApiApiOrganizationsIdPatch
     */
    readonly id: string

    /**
     * 
     * @type {number}
     * @memberof OrganizationsApiApiOrganizationsIdPatch
     */
    readonly body?: number
}

/**
 * Request parameters for apiOrganizationsIdPut operation in OrganizationsApi.
 * @export
 * @interface OrganizationsApiApiOrganizationsIdPutRequest
 */
export interface OrganizationsApiApiOrganizationsIdPutRequest {
    /**
     * 
     * @type {string}
     * @memberof OrganizationsApiApiOrganizationsIdPut
     */
    readonly id: string

    /**
     * 
     * @type {OrganizationRequest}
     * @memberof OrganizationsApiApiOrganizationsIdPut
     */
    readonly organizationRequest?: OrganizationRequest
}

/**
 * Request parameters for apiOrganizationsPost operation in OrganizationsApi.
 * @export
 * @interface OrganizationsApiApiOrganizationsPostRequest
 */
export interface OrganizationsApiApiOrganizationsPostRequest {
    /**
     * 
     * @type {OrganizationRequest}
     * @memberof OrganizationsApiApiOrganizationsPost
     */
    readonly organizationRequest?: OrganizationRequest
}

/**
 * OrganizationsApi - object-oriented interface
 * @export
 * @class OrganizationsApi
 * @extends {BaseAPI}
 */
export class OrganizationsApi extends BaseAPI {
    /**
     * 
     * @param {OrganizationsApiApiOrganizationsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApi
     */
    public apiOrganizationsGet(requestParameters: OrganizationsApiApiOrganizationsGetRequest = {}, options?: AxiosRequestConfig) {
        return OrganizationsApiFp(this.configuration).apiOrganizationsGet(requestParameters.pageNumber, requestParameters.pageSize, requestParameters.sortBy, requestParameters.sortType, requestParameters.search, requestParameters.municipalityId, requestParameters.entityId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {OrganizationsApiApiOrganizationsIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApi
     */
    public apiOrganizationsIdDelete(requestParameters: OrganizationsApiApiOrganizationsIdDeleteRequest, options?: AxiosRequestConfig) {
        return OrganizationsApiFp(this.configuration).apiOrganizationsIdDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {OrganizationsApiApiOrganizationsIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApi
     */
    public apiOrganizationsIdGet(requestParameters: OrganizationsApiApiOrganizationsIdGetRequest, options?: AxiosRequestConfig) {
        return OrganizationsApiFp(this.configuration).apiOrganizationsIdGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {OrganizationsApiApiOrganizationsIdNumberIdNumberGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApi
     */
    public apiOrganizationsIdNumberIdNumberGet(requestParameters: OrganizationsApiApiOrganizationsIdNumberIdNumberGetRequest, options?: AxiosRequestConfig) {
        return OrganizationsApiFp(this.configuration).apiOrganizationsIdNumberIdNumberGet(requestParameters.idNumber, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {OrganizationsApiApiOrganizationsIdPatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApi
     */
    public apiOrganizationsIdPatch(requestParameters: OrganizationsApiApiOrganizationsIdPatchRequest, options?: AxiosRequestConfig) {
        return OrganizationsApiFp(this.configuration).apiOrganizationsIdPatch(requestParameters.id, requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {OrganizationsApiApiOrganizationsIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApi
     */
    public apiOrganizationsIdPut(requestParameters: OrganizationsApiApiOrganizationsIdPutRequest, options?: AxiosRequestConfig) {
        return OrganizationsApiFp(this.configuration).apiOrganizationsIdPut(requestParameters.id, requestParameters.organizationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {OrganizationsApiApiOrganizationsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApi
     */
    public apiOrganizationsPost(requestParameters: OrganizationsApiApiOrganizationsPostRequest = {}, options?: AxiosRequestConfig) {
        return OrganizationsApiFp(this.configuration).apiOrganizationsPost(requestParameters.organizationRequest, options).then((request) => request(this.axios, this.basePath));
    }
}
