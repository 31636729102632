import { Col, Row } from "antd";
import Heading from "components/heading/heading";
import { Aside, Content } from "pages/authentication/style";
import { Outlet } from "react-router-dom";

const AuthLayout = () => {
  return (
    <Row>
      <Col xxl={8} xl={9} lg={12} md={8} xs={24}>
        <Aside>
          <div className="auth-side-content">
            <img
              src={require("static/img/auth/topShape.png")}
              alt=""
              className="topShape"
            />
            <img
              src={require("static/img/auth/bottomShape.png")}
              alt=""
              className="bottomShape"
            />
            <Content>
              
              <img
                className="auth-content-figure"
                src={require("static/img/auth/signin.png")}
                alt=""
                style={{marginTop: 150}}
                width={'100%'}
              />
            </Content>
          </div>
        </Aside>
      </Col>

      <Col xxl={16} xl={15} lg={12} md={16} xs={24}>
        <Outlet />
      </Col>
    </Row>
  );
};

export default AuthLayout;
