/* tslint:disable */
/* eslint-disable */
/**
 * KGH PROJECT API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ApproveRequestDto } from '../models';
// @ts-ignore
import { AreaOfExpertise } from '../models';
// @ts-ignore
import { CertificateNumberAvailabilityResult } from '../models';
// @ts-ignore
import { CheckCertificateNumberAvailabilityQuery } from '../models';
// @ts-ignore
import { ListRequestDtoPaginatedList } from '../models';
// @ts-ignore
import { RequestCompanyType } from '../models';
// @ts-ignore
import { RequestDetailsDto } from '../models';
// @ts-ignore
import { RequestStatus } from '../models';
// @ts-ignore
import { RequestType } from '../models';
/**
 * RequestsApi - axios parameter creator
 * @export
 */
export const RequestsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CheckCertificateNumberAvailabilityQuery} [checkCertificateNumberAvailabilityQuery] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRequestsCertificateNumbersValidityPost: async (checkCertificateNumberAvailabilityQuery?: CheckCertificateNumberAvailabilityQuery, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Requests/certificate-numbers-validity`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(checkCertificateNumberAvailabilityQuery, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} fileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRequestsDownloadFileIdGet: async (fileId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fileId' is not null or undefined
            assertParamExists('apiRequestsDownloadFileIdGet', 'fileId', fileId)
            const localVarPath = `/api/Requests/download/{fileId}`
                .replace(`{${"fileId"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [idNumber] 
         * @param {string} [companyName] 
         * @param {RequestType} [type] 
         * @param {boolean} [listArchived] 
         * @param {string} [search] 
         * @param {string} [sortingPropertyName] 
         * @param {boolean} [sortingIsDescending] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRequestsGet: async (pageNumber?: number, pageSize?: number, idNumber?: string, companyName?: string, type?: RequestType, listArchived?: boolean, search?: string, sortingPropertyName?: string, sortingIsDescending?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Requests`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (pageNumber !== undefined) {
                localVarQueryParameter['PageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (idNumber !== undefined) {
                localVarQueryParameter['IdNumber'] = idNumber;
            }

            if (companyName !== undefined) {
                localVarQueryParameter['CompanyName'] = companyName;
            }

            if (type !== undefined) {
                localVarQueryParameter['Type'] = type;
            }

            if (listArchived !== undefined) {
                localVarQueryParameter['ListArchived'] = listArchived;
            }

            if (search !== undefined) {
                localVarQueryParameter['Search'] = search;
            }

            if (sortingPropertyName !== undefined) {
                localVarQueryParameter['Sorting.PropertyName'] = sortingPropertyName;
            }

            if (sortingIsDescending !== undefined) {
                localVarQueryParameter['Sorting.IsDescending'] = sortingIsDescending;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {ApproveRequestDto} [approveRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRequestsIdApprovePut: async (id: string, approveRequestDto?: ApproveRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiRequestsIdApprovePut', 'id', id)
            const localVarPath = `/api/Requests/{id}/approve`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(approveRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRequestsIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiRequestsIdGet', 'id', id)
            const localVarPath = `/api/Requests/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRequestsIdRejectPut: async (id: string, body?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiRequestsIdRejectPut', 'id', id)
            const localVarPath = `/api/Requests/{id}/reject`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [currentUserId] 
         * @param {boolean} [isLoggedInAsCompany] 
         * @param {string} [companyId] 
         * @param {boolean} [isFromPublic] 
         * @param {string} [idNumber] 
         * @param {string} [companyName] 
         * @param {string} [companyEmailAddress] 
         * @param {string} [companyPhoneNumber] 
         * @param {string} [websiteUrl] 
         * @param {string} [taxNumber] 
         * @param {string} [responsiblePersonFullName] 
         * @param {string} [responsiblePersonFunction] 
         * @param {string} [contactPersonFirstName] 
         * @param {string} [contactPersonLastName] 
         * @param {string} [contactPersonEmail] 
         * @param {string} [address] 
         * @param {string} [place] 
         * @param {string} [postCode] 
         * @param {string} [comments] 
         * @param {string} [municipalityId] 
         * @param {string} [stateEntityId] 
         * @param {string} [licenseId] 
         * @param {string} [licenseDuration] 
         * @param {Array<string>} [certificationNumbers] 
         * @param {number} [totalNumberOfServiceTechnians] 
         * @param {boolean} [meetsEquipmentRegulations] 
         * @param {string} [languageId] 
         * @param {RequestType} [type] 
         * @param {RequestStatus} [status] 
         * @param {RequestCompanyType} [companyType] 
         * @param {AreaOfExpertise} [areaOfExpertise] 
         * @param {string} [businessActivityId] 
         * @param {Array<File>} [attachments] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRequestsPost: async (currentUserId?: string, isLoggedInAsCompany?: boolean, companyId?: string, isFromPublic?: boolean, idNumber?: string, companyName?: string, companyEmailAddress?: string, companyPhoneNumber?: string, websiteUrl?: string, taxNumber?: string, responsiblePersonFullName?: string, responsiblePersonFunction?: string, contactPersonFirstName?: string, contactPersonLastName?: string, contactPersonEmail?: string, address?: string, place?: string, postCode?: string, comments?: string, municipalityId?: string, stateEntityId?: string, licenseId?: string, licenseDuration?: string, certificationNumbers?: Array<string>, totalNumberOfServiceTechnians?: number, meetsEquipmentRegulations?: boolean, languageId?: string, type?: RequestType, status?: RequestStatus, companyType?: RequestCompanyType, areaOfExpertise?: AreaOfExpertise, businessActivityId?: string, attachments?: Array<File>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Requests`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


            if (currentUserId !== undefined) { 
                localVarFormParams.append('CurrentUserId', currentUserId as any);
            }
    
            if (isLoggedInAsCompany !== undefined) { 
                localVarFormParams.append('IsLoggedInAsCompany', isLoggedInAsCompany as any);
            }
    
            if (companyId !== undefined) { 
                localVarFormParams.append('CompanyId', companyId as any);
            }
    
            if (isFromPublic !== undefined) { 
                localVarFormParams.append('IsFromPublic', isFromPublic as any);
            }
    
            if (idNumber !== undefined) { 
                localVarFormParams.append('IdNumber', idNumber as any);
            }
    
            if (companyName !== undefined) { 
                localVarFormParams.append('CompanyName', companyName as any);
            }
    
            if (companyEmailAddress !== undefined) { 
                localVarFormParams.append('CompanyEmailAddress', companyEmailAddress as any);
            }
    
            if (companyPhoneNumber !== undefined) { 
                localVarFormParams.append('CompanyPhoneNumber', companyPhoneNumber as any);
            }
    
            if (websiteUrl !== undefined) { 
                localVarFormParams.append('WebsiteUrl', websiteUrl as any);
            }
    
            if (taxNumber !== undefined) { 
                localVarFormParams.append('TaxNumber', taxNumber as any);
            }
    
            if (responsiblePersonFullName !== undefined) { 
                localVarFormParams.append('ResponsiblePersonFullName', responsiblePersonFullName as any);
            }
    
            if (responsiblePersonFunction !== undefined) { 
                localVarFormParams.append('ResponsiblePersonFunction', responsiblePersonFunction as any);
            }
    
            if (contactPersonFirstName !== undefined) { 
                localVarFormParams.append('ContactPersonFirstName', contactPersonFirstName as any);
            }
    
            if (contactPersonLastName !== undefined) { 
                localVarFormParams.append('ContactPersonLastName', contactPersonLastName as any);
            }
    
            if (contactPersonEmail !== undefined) { 
                localVarFormParams.append('ContactPersonEmail', contactPersonEmail as any);
            }
    
            if (address !== undefined) { 
                localVarFormParams.append('Address', address as any);
            }
    
            if (place !== undefined) { 
                localVarFormParams.append('Place', place as any);
            }
    
            if (postCode !== undefined) { 
                localVarFormParams.append('PostCode', postCode as any);
            }
    
            if (comments !== undefined) { 
                localVarFormParams.append('Comments', comments as any);
            }
    
            if (municipalityId !== undefined) { 
                localVarFormParams.append('MunicipalityId', municipalityId as any);
            }
    
            if (stateEntityId !== undefined) { 
                localVarFormParams.append('StateEntityId', stateEntityId as any);
            }
    
            if (licenseId !== undefined) { 
                localVarFormParams.append('LicenseId', licenseId as any);
            }
    
            if (licenseDuration !== undefined) { 
                localVarFormParams.append('LicenseDuration', licenseDuration as any);
            }
                if (certificationNumbers) {
                certificationNumbers.forEach((element) => {
                    localVarFormParams.append('CertificationNumbers', element as any);
                })
            }

    
            if (totalNumberOfServiceTechnians !== undefined) { 
                localVarFormParams.append('TotalNumberOfServiceTechnians', totalNumberOfServiceTechnians as any);
            }
    
            if (meetsEquipmentRegulations !== undefined) { 
                localVarFormParams.append('MeetsEquipmentRegulations', meetsEquipmentRegulations as any);
            }
    
            if (languageId !== undefined) { 
                localVarFormParams.append('LanguageId', languageId as any);
            }
    
            if (type !== undefined) {
                localVarFormParams.append('Type', type as any);
            }

            if (status !== undefined) {
                localVarFormParams.append('Status', status as any);
            }

            if (companyType !== undefined) {
                localVarFormParams.append('CompanyType', companyType as any);
            }

            if (areaOfExpertise !== undefined) {
                localVarFormParams.append('AreaOfExpertise', areaOfExpertise as any);
            }
    
            if (businessActivityId !== undefined) { 
                localVarFormParams.append('BusinessActivityId', businessActivityId as any);
            }
                if (attachments) {
                attachments.forEach((element) => {
                    localVarFormParams.append('Attachments', element as any);
                })
            }

    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RequestsApi - functional programming interface
 * @export
 */
export const RequestsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RequestsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CheckCertificateNumberAvailabilityQuery} [checkCertificateNumberAvailabilityQuery] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiRequestsCertificateNumbersValidityPost(checkCertificateNumberAvailabilityQuery?: CheckCertificateNumberAvailabilityQuery, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CertificateNumberAvailabilityResult>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiRequestsCertificateNumbersValidityPost(checkCertificateNumberAvailabilityQuery, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} fileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiRequestsDownloadFileIdGet(fileId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiRequestsDownloadFileIdGet(fileId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [idNumber] 
         * @param {string} [companyName] 
         * @param {RequestType} [type] 
         * @param {boolean} [listArchived] 
         * @param {string} [search] 
         * @param {string} [sortingPropertyName] 
         * @param {boolean} [sortingIsDescending] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiRequestsGet(pageNumber?: number, pageSize?: number, idNumber?: string, companyName?: string, type?: RequestType, listArchived?: boolean, search?: string, sortingPropertyName?: string, sortingIsDescending?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListRequestDtoPaginatedList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiRequestsGet(pageNumber, pageSize, idNumber, companyName, type, listArchived, search, sortingPropertyName, sortingIsDescending, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {ApproveRequestDto} [approveRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiRequestsIdApprovePut(id: string, approveRequestDto?: ApproveRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiRequestsIdApprovePut(id, approveRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiRequestsIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RequestDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiRequestsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiRequestsIdRejectPut(id: string, body?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiRequestsIdRejectPut(id, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [currentUserId] 
         * @param {boolean} [isLoggedInAsCompany] 
         * @param {string} [companyId] 
         * @param {boolean} [isFromPublic] 
         * @param {string} [idNumber] 
         * @param {string} [companyName] 
         * @param {string} [companyEmailAddress] 
         * @param {string} [companyPhoneNumber] 
         * @param {string} [websiteUrl] 
         * @param {string} [taxNumber] 
         * @param {string} [responsiblePersonFullName] 
         * @param {string} [responsiblePersonFunction] 
         * @param {string} [contactPersonFirstName] 
         * @param {string} [contactPersonLastName] 
         * @param {string} [contactPersonEmail] 
         * @param {string} [address] 
         * @param {string} [place] 
         * @param {string} [postCode] 
         * @param {string} [comments] 
         * @param {string} [municipalityId] 
         * @param {string} [stateEntityId] 
         * @param {string} [licenseId] 
         * @param {string} [licenseDuration] 
         * @param {Array<string>} [certificationNumbers] 
         * @param {number} [totalNumberOfServiceTechnians] 
         * @param {boolean} [meetsEquipmentRegulations] 
         * @param {string} [languageId] 
         * @param {RequestType} [type] 
         * @param {RequestStatus} [status] 
         * @param {RequestCompanyType} [companyType] 
         * @param {AreaOfExpertise} [areaOfExpertise] 
         * @param {string} [businessActivityId] 
         * @param {Array<File>} [attachments] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiRequestsPost(currentUserId?: string, isLoggedInAsCompany?: boolean, companyId?: string, isFromPublic?: boolean, idNumber?: string, companyName?: string, companyEmailAddress?: string, companyPhoneNumber?: string, websiteUrl?: string, taxNumber?: string, responsiblePersonFullName?: string, responsiblePersonFunction?: string, contactPersonFirstName?: string, contactPersonLastName?: string, contactPersonEmail?: string, address?: string, place?: string, postCode?: string, comments?: string, municipalityId?: string, stateEntityId?: string, licenseId?: string, licenseDuration?: string, certificationNumbers?: Array<string>, totalNumberOfServiceTechnians?: number, meetsEquipmentRegulations?: boolean, languageId?: string, type?: RequestType, status?: RequestStatus, companyType?: RequestCompanyType, areaOfExpertise?: AreaOfExpertise, businessActivityId?: string, attachments?: Array<File>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiRequestsPost(currentUserId, isLoggedInAsCompany, companyId, isFromPublic, idNumber, companyName, companyEmailAddress, companyPhoneNumber, websiteUrl, taxNumber, responsiblePersonFullName, responsiblePersonFunction, contactPersonFirstName, contactPersonLastName, contactPersonEmail, address, place, postCode, comments, municipalityId, stateEntityId, licenseId, licenseDuration, certificationNumbers, totalNumberOfServiceTechnians, meetsEquipmentRegulations, languageId, type, status, companyType, areaOfExpertise, businessActivityId, attachments, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RequestsApi - factory interface
 * @export
 */
export const RequestsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RequestsApiFp(configuration)
    return {
        /**
         * 
         * @param {CheckCertificateNumberAvailabilityQuery} [checkCertificateNumberAvailabilityQuery] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRequestsCertificateNumbersValidityPost(checkCertificateNumberAvailabilityQuery?: CheckCertificateNumberAvailabilityQuery, options?: any): AxiosPromise<Array<CertificateNumberAvailabilityResult>> {
            return localVarFp.apiRequestsCertificateNumbersValidityPost(checkCertificateNumberAvailabilityQuery, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} fileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRequestsDownloadFileIdGet(fileId: string, options?: any): AxiosPromise<File> {
            return localVarFp.apiRequestsDownloadFileIdGet(fileId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [idNumber] 
         * @param {string} [companyName] 
         * @param {RequestType} [type] 
         * @param {boolean} [listArchived] 
         * @param {string} [search] 
         * @param {string} [sortingPropertyName] 
         * @param {boolean} [sortingIsDescending] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRequestsGet(pageNumber?: number, pageSize?: number, idNumber?: string, companyName?: string, type?: RequestType, listArchived?: boolean, search?: string, sortingPropertyName?: string, sortingIsDescending?: boolean, options?: any): AxiosPromise<ListRequestDtoPaginatedList> {
            return localVarFp.apiRequestsGet(pageNumber, pageSize, idNumber, companyName, type, listArchived, search, sortingPropertyName, sortingIsDescending, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {ApproveRequestDto} [approveRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRequestsIdApprovePut(id: string, approveRequestDto?: ApproveRequestDto, options?: any): AxiosPromise<void> {
            return localVarFp.apiRequestsIdApprovePut(id, approveRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRequestsIdGet(id: string, options?: any): AxiosPromise<RequestDetailsDto> {
            return localVarFp.apiRequestsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRequestsIdRejectPut(id: string, body?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiRequestsIdRejectPut(id, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [currentUserId] 
         * @param {boolean} [isLoggedInAsCompany] 
         * @param {string} [companyId] 
         * @param {boolean} [isFromPublic] 
         * @param {string} [idNumber] 
         * @param {string} [companyName] 
         * @param {string} [companyEmailAddress] 
         * @param {string} [companyPhoneNumber] 
         * @param {string} [websiteUrl] 
         * @param {string} [taxNumber] 
         * @param {string} [responsiblePersonFullName] 
         * @param {string} [responsiblePersonFunction] 
         * @param {string} [contactPersonFirstName] 
         * @param {string} [contactPersonLastName] 
         * @param {string} [contactPersonEmail] 
         * @param {string} [address] 
         * @param {string} [place] 
         * @param {string} [postCode] 
         * @param {string} [comments] 
         * @param {string} [municipalityId] 
         * @param {string} [stateEntityId] 
         * @param {string} [licenseId] 
         * @param {string} [licenseDuration] 
         * @param {Array<string>} [certificationNumbers] 
         * @param {number} [totalNumberOfServiceTechnians] 
         * @param {boolean} [meetsEquipmentRegulations] 
         * @param {string} [languageId] 
         * @param {RequestType} [type] 
         * @param {RequestStatus} [status] 
         * @param {RequestCompanyType} [companyType] 
         * @param {AreaOfExpertise} [areaOfExpertise] 
         * @param {string} [businessActivityId] 
         * @param {Array<File>} [attachments] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRequestsPost(currentUserId?: string, isLoggedInAsCompany?: boolean, companyId?: string, isFromPublic?: boolean, idNumber?: string, companyName?: string, companyEmailAddress?: string, companyPhoneNumber?: string, websiteUrl?: string, taxNumber?: string, responsiblePersonFullName?: string, responsiblePersonFunction?: string, contactPersonFirstName?: string, contactPersonLastName?: string, contactPersonEmail?: string, address?: string, place?: string, postCode?: string, comments?: string, municipalityId?: string, stateEntityId?: string, licenseId?: string, licenseDuration?: string, certificationNumbers?: Array<string>, totalNumberOfServiceTechnians?: number, meetsEquipmentRegulations?: boolean, languageId?: string, type?: RequestType, status?: RequestStatus, companyType?: RequestCompanyType, areaOfExpertise?: AreaOfExpertise, businessActivityId?: string, attachments?: Array<File>, options?: any): AxiosPromise<void> {
            return localVarFp.apiRequestsPost(currentUserId, isLoggedInAsCompany, companyId, isFromPublic, idNumber, companyName, companyEmailAddress, companyPhoneNumber, websiteUrl, taxNumber, responsiblePersonFullName, responsiblePersonFunction, contactPersonFirstName, contactPersonLastName, contactPersonEmail, address, place, postCode, comments, municipalityId, stateEntityId, licenseId, licenseDuration, certificationNumbers, totalNumberOfServiceTechnians, meetsEquipmentRegulations, languageId, type, status, companyType, areaOfExpertise, businessActivityId, attachments, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiRequestsCertificateNumbersValidityPost operation in RequestsApi.
 * @export
 * @interface RequestsApiApiRequestsCertificateNumbersValidityPostRequest
 */
export interface RequestsApiApiRequestsCertificateNumbersValidityPostRequest {
    /**
     * 
     * @type {CheckCertificateNumberAvailabilityQuery}
     * @memberof RequestsApiApiRequestsCertificateNumbersValidityPost
     */
    readonly checkCertificateNumberAvailabilityQuery?: CheckCertificateNumberAvailabilityQuery
}

/**
 * Request parameters for apiRequestsDownloadFileIdGet operation in RequestsApi.
 * @export
 * @interface RequestsApiApiRequestsDownloadFileIdGetRequest
 */
export interface RequestsApiApiRequestsDownloadFileIdGetRequest {
    /**
     * 
     * @type {string}
     * @memberof RequestsApiApiRequestsDownloadFileIdGet
     */
    readonly fileId: string
}

/**
 * Request parameters for apiRequestsGet operation in RequestsApi.
 * @export
 * @interface RequestsApiApiRequestsGetRequest
 */
export interface RequestsApiApiRequestsGetRequest {
    /**
     * 
     * @type {number}
     * @memberof RequestsApiApiRequestsGet
     */
    readonly pageNumber?: number

    /**
     * 
     * @type {number}
     * @memberof RequestsApiApiRequestsGet
     */
    readonly pageSize?: number

    /**
     * 
     * @type {string}
     * @memberof RequestsApiApiRequestsGet
     */
    readonly idNumber?: string

    /**
     * 
     * @type {string}
     * @memberof RequestsApiApiRequestsGet
     */
    readonly companyName?: string

    /**
     * 
     * @type {RequestType}
     * @memberof RequestsApiApiRequestsGet
     */
    readonly type?: RequestType

    /**
     * 
     * @type {boolean}
     * @memberof RequestsApiApiRequestsGet
     */
    readonly listArchived?: boolean

    /**
     * 
     * @type {string}
     * @memberof RequestsApiApiRequestsGet
     */
    readonly search?: string

    /**
     * 
     * @type {string}
     * @memberof RequestsApiApiRequestsGet
     */
    readonly sortingPropertyName?: string

    /**
     * 
     * @type {boolean}
     * @memberof RequestsApiApiRequestsGet
     */
    readonly sortingIsDescending?: boolean
}

/**
 * Request parameters for apiRequestsIdApprovePut operation in RequestsApi.
 * @export
 * @interface RequestsApiApiRequestsIdApprovePutRequest
 */
export interface RequestsApiApiRequestsIdApprovePutRequest {
    /**
     * 
     * @type {string}
     * @memberof RequestsApiApiRequestsIdApprovePut
     */
    readonly id: string

    /**
     * 
     * @type {ApproveRequestDto}
     * @memberof RequestsApiApiRequestsIdApprovePut
     */
    readonly approveRequestDto?: ApproveRequestDto
}

/**
 * Request parameters for apiRequestsIdGet operation in RequestsApi.
 * @export
 * @interface RequestsApiApiRequestsIdGetRequest
 */
export interface RequestsApiApiRequestsIdGetRequest {
    /**
     * 
     * @type {string}
     * @memberof RequestsApiApiRequestsIdGet
     */
    readonly id: string
}

/**
 * Request parameters for apiRequestsIdRejectPut operation in RequestsApi.
 * @export
 * @interface RequestsApiApiRequestsIdRejectPutRequest
 */
export interface RequestsApiApiRequestsIdRejectPutRequest {
    /**
     * 
     * @type {string}
     * @memberof RequestsApiApiRequestsIdRejectPut
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof RequestsApiApiRequestsIdRejectPut
     */
    readonly body?: string
}

/**
 * Request parameters for apiRequestsPost operation in RequestsApi.
 * @export
 * @interface RequestsApiApiRequestsPostRequest
 */
export interface RequestsApiApiRequestsPostRequest {
    /**
     * 
     * @type {string}
     * @memberof RequestsApiApiRequestsPost
     */
    readonly currentUserId?: string

    /**
     * 
     * @type {boolean}
     * @memberof RequestsApiApiRequestsPost
     */
    readonly isLoggedInAsCompany?: boolean

    /**
     * 
     * @type {string}
     * @memberof RequestsApiApiRequestsPost
     */
    readonly companyId?: string

    /**
     * 
     * @type {boolean}
     * @memberof RequestsApiApiRequestsPost
     */
    readonly isFromPublic?: boolean

    /**
     * 
     * @type {string}
     * @memberof RequestsApiApiRequestsPost
     */
    readonly idNumber?: string

    /**
     * 
     * @type {string}
     * @memberof RequestsApiApiRequestsPost
     */
    readonly companyName?: string

    /**
     * 
     * @type {string}
     * @memberof RequestsApiApiRequestsPost
     */
    readonly companyEmailAddress?: string

    /**
     * 
     * @type {string}
     * @memberof RequestsApiApiRequestsPost
     */
    readonly companyPhoneNumber?: string

    /**
     * 
     * @type {string}
     * @memberof RequestsApiApiRequestsPost
     */
    readonly websiteUrl?: string

    /**
     * 
     * @type {string}
     * @memberof RequestsApiApiRequestsPost
     */
    readonly taxNumber?: string

    /**
     * 
     * @type {string}
     * @memberof RequestsApiApiRequestsPost
     */
    readonly responsiblePersonFullName?: string

    /**
     * 
     * @type {string}
     * @memberof RequestsApiApiRequestsPost
     */
    readonly responsiblePersonFunction?: string

    /**
     * 
     * @type {string}
     * @memberof RequestsApiApiRequestsPost
     */
    readonly contactPersonFirstName?: string

    /**
     * 
     * @type {string}
     * @memberof RequestsApiApiRequestsPost
     */
    readonly contactPersonLastName?: string

    /**
     * 
     * @type {string}
     * @memberof RequestsApiApiRequestsPost
     */
    readonly contactPersonEmail?: string

    /**
     * 
     * @type {string}
     * @memberof RequestsApiApiRequestsPost
     */
    readonly address?: string

    /**
     * 
     * @type {string}
     * @memberof RequestsApiApiRequestsPost
     */
    readonly place?: string

    /**
     * 
     * @type {string}
     * @memberof RequestsApiApiRequestsPost
     */
    readonly postCode?: string

    /**
     * 
     * @type {string}
     * @memberof RequestsApiApiRequestsPost
     */
    readonly comments?: string

    /**
     * 
     * @type {string}
     * @memberof RequestsApiApiRequestsPost
     */
    readonly municipalityId?: string

    /**
     * 
     * @type {string}
     * @memberof RequestsApiApiRequestsPost
     */
    readonly stateEntityId?: string

    /**
     * 
     * @type {string}
     * @memberof RequestsApiApiRequestsPost
     */
    readonly licenseId?: string

    /**
     * 
     * @type {string}
     * @memberof RequestsApiApiRequestsPost
     */
    readonly licenseDuration?: string

    /**
     * 
     * @type {Array<string>}
     * @memberof RequestsApiApiRequestsPost
     */
    readonly certificationNumbers?: Array<string>

    /**
     * 
     * @type {number}
     * @memberof RequestsApiApiRequestsPost
     */
    readonly totalNumberOfServiceTechnians?: number

    /**
     * 
     * @type {boolean}
     * @memberof RequestsApiApiRequestsPost
     */
    readonly meetsEquipmentRegulations?: boolean

    /**
     * 
     * @type {string}
     * @memberof RequestsApiApiRequestsPost
     */
    readonly languageId?: string

    /**
     * 
     * @type {RequestType}
     * @memberof RequestsApiApiRequestsPost
     */
    readonly type?: RequestType

    /**
     * 
     * @type {RequestStatus}
     * @memberof RequestsApiApiRequestsPost
     */
    readonly status?: RequestStatus

    /**
     * 
     * @type {RequestCompanyType}
     * @memberof RequestsApiApiRequestsPost
     */
    readonly companyType?: RequestCompanyType

    /**
     * 
     * @type {AreaOfExpertise}
     * @memberof RequestsApiApiRequestsPost
     */
    readonly areaOfExpertise?: AreaOfExpertise

    /**
     * 
     * @type {string}
     * @memberof RequestsApiApiRequestsPost
     */
    readonly businessActivityId?: string

    /**
     * 
     * @type {Array<File>}
     * @memberof RequestsApiApiRequestsPost
     */
    readonly attachments?: Array<File>
}

/**
 * RequestsApi - object-oriented interface
 * @export
 * @class RequestsApi
 * @extends {BaseAPI}
 */
export class RequestsApi extends BaseAPI {
    /**
     * 
     * @param {RequestsApiApiRequestsCertificateNumbersValidityPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RequestsApi
     */
    public apiRequestsCertificateNumbersValidityPost(requestParameters: RequestsApiApiRequestsCertificateNumbersValidityPostRequest = {}, options?: AxiosRequestConfig) {
        return RequestsApiFp(this.configuration).apiRequestsCertificateNumbersValidityPost(requestParameters.checkCertificateNumberAvailabilityQuery, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RequestsApiApiRequestsDownloadFileIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RequestsApi
     */
    public apiRequestsDownloadFileIdGet(requestParameters: RequestsApiApiRequestsDownloadFileIdGetRequest, options?: AxiosRequestConfig) {
        return RequestsApiFp(this.configuration).apiRequestsDownloadFileIdGet(requestParameters.fileId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RequestsApiApiRequestsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RequestsApi
     */
    public apiRequestsGet(requestParameters: RequestsApiApiRequestsGetRequest = {}, options?: AxiosRequestConfig) {
        return RequestsApiFp(this.configuration).apiRequestsGet(requestParameters.pageNumber, requestParameters.pageSize, requestParameters.idNumber, requestParameters.companyName, requestParameters.type, requestParameters.listArchived, requestParameters.search, requestParameters.sortingPropertyName, requestParameters.sortingIsDescending, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RequestsApiApiRequestsIdApprovePutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RequestsApi
     */
    public apiRequestsIdApprovePut(requestParameters: RequestsApiApiRequestsIdApprovePutRequest, options?: AxiosRequestConfig) {
        return RequestsApiFp(this.configuration).apiRequestsIdApprovePut(requestParameters.id, requestParameters.approveRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RequestsApiApiRequestsIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RequestsApi
     */
    public apiRequestsIdGet(requestParameters: RequestsApiApiRequestsIdGetRequest, options?: AxiosRequestConfig) {
        return RequestsApiFp(this.configuration).apiRequestsIdGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RequestsApiApiRequestsIdRejectPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RequestsApi
     */
    public apiRequestsIdRejectPut(requestParameters: RequestsApiApiRequestsIdRejectPutRequest, options?: AxiosRequestConfig) {
        return RequestsApiFp(this.configuration).apiRequestsIdRejectPut(requestParameters.id, requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RequestsApiApiRequestsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RequestsApi
     */
    public apiRequestsPost(requestParameters: RequestsApiApiRequestsPostRequest = {}, options?: AxiosRequestConfig) {
        return RequestsApiFp(this.configuration).apiRequestsPost(requestParameters.currentUserId, requestParameters.isLoggedInAsCompany, requestParameters.companyId, requestParameters.isFromPublic, requestParameters.idNumber, requestParameters.companyName, requestParameters.companyEmailAddress, requestParameters.companyPhoneNumber, requestParameters.websiteUrl, requestParameters.taxNumber, requestParameters.responsiblePersonFullName, requestParameters.responsiblePersonFunction, requestParameters.contactPersonFirstName, requestParameters.contactPersonLastName, requestParameters.contactPersonEmail, requestParameters.address, requestParameters.place, requestParameters.postCode, requestParameters.comments, requestParameters.municipalityId, requestParameters.stateEntityId, requestParameters.licenseId, requestParameters.licenseDuration, requestParameters.certificationNumbers, requestParameters.totalNumberOfServiceTechnians, requestParameters.meetsEquipmentRegulations, requestParameters.languageId, requestParameters.type, requestParameters.status, requestParameters.companyType, requestParameters.areaOfExpertise, requestParameters.businessActivityId, requestParameters.attachments, options).then((request) => request(this.axios, this.basePath));
    }
}
