/* tslint:disable */
/* eslint-disable */
/**
 * KGH PROJECT API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CertifiedTechnicianDto } from '../models';
// @ts-ignore
import { CertifiedTechnicianDtoPaginatedList } from '../models';
// @ts-ignore
import { RecordEmploymentDto } from '../models';
// @ts-ignore
import { UpdateUserCommand } from '../models';
// @ts-ignore
import { UserFilterType } from '../models';
/**
 * CertifiedTechniciansApi - axios parameter creator
 * @export
 */
export const CertifiedTechniciansApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} serialNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCertifiedTechniciansBySerialNumberSerialNumberGet: async (serialNumber: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'serialNumber' is not null or undefined
            assertParamExists('apiCertifiedTechniciansBySerialNumberSerialNumberGet', 'serialNumber', serialNumber)
            const localVarPath = `/api/CertifiedTechnicians/by-serial-number/{serialNumber}`
                .replace(`{${"serialNumber"}}`, encodeURIComponent(String(serialNumber)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [search] 
         * @param {string} [sortingPropertyName] 
         * @param {boolean} [sortingIsDescending] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCertifiedTechniciansCompanyTechniciansGet: async (pageNumber?: number, pageSize?: number, search?: string, sortingPropertyName?: string, sortingIsDescending?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/CertifiedTechnicians/company-technicians`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (pageNumber !== undefined) {
                localVarQueryParameter['PageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (search !== undefined) {
                localVarQueryParameter['Search'] = search;
            }

            if (sortingPropertyName !== undefined) {
                localVarQueryParameter['Sorting.PropertyName'] = sortingPropertyName;
            }

            if (sortingIsDescending !== undefined) {
                localVarQueryParameter['Sorting.IsDescending'] = sortingIsDescending;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCertifiedTechniciansEmploymentHistoryGet: async (id?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/CertifiedTechnicians/employment-history`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCertifiedTechniciansEndCurrentEmploymentPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/CertifiedTechnicians/end-current-employment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCertifiedTechniciansIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCertifiedTechniciansIdGet', 'id', id)
            const localVarPath = `/api/CertifiedTechnicians/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateUserCommand} [updateUserCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCertifiedTechniciansIdPut: async (id: string, updateUserCommand?: UpdateUserCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCertifiedTechniciansIdPut', 'id', id)
            const localVarPath = `/api/CertifiedTechnicians/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateUserCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCertifiedTechniciansIdQualificationsGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCertifiedTechniciansIdQualificationsGet', 'id', id)
            const localVarPath = `/api/CertifiedTechnicians/{id}/qualifications`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {RecordEmploymentDto} [recordEmploymentDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCertifiedTechniciansIdRecordEmploymentPost: async (id: string, recordEmploymentDto?: RecordEmploymentDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCertifiedTechniciansIdRecordEmploymentPost', 'id', id)
            const localVarPath = `/api/CertifiedTechnicians/{id}/record-employment`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(recordEmploymentDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCertifiedTechniciansIdTerminateEmploymentPost: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCertifiedTechniciansIdTerminateEmploymentPost', 'id', id)
            const localVarPath = `/api/CertifiedTechnicians/{id}/terminate-employment`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [search] 
         * @param {string} [organizationId] 
         * @param {UserFilterType} [filterType] 
         * @param {string} [sortingPropertyName] 
         * @param {boolean} [sortingIsDescending] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCertifiedTechniciansListGet: async (pageNumber?: number, pageSize?: number, search?: string, organizationId?: string, filterType?: UserFilterType, sortingPropertyName?: string, sortingIsDescending?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/CertifiedTechnicians/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (pageNumber !== undefined) {
                localVarQueryParameter['PageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (search !== undefined) {
                localVarQueryParameter['Search'] = search;
            }

            if (organizationId !== undefined) {
                localVarQueryParameter['OrganizationId'] = organizationId;
            }

            if (filterType !== undefined) {
                localVarQueryParameter['FilterType'] = filterType;
            }

            if (sortingPropertyName !== undefined) {
                localVarQueryParameter['Sorting.PropertyName'] = sortingPropertyName;
            }

            if (sortingIsDescending !== undefined) {
                localVarQueryParameter['Sorting.IsDescending'] = sortingIsDescending;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [firstName] 
         * @param {string} [lastName] 
         * @param {string} [email] 
         * @param {string} [languageId] 
         * @param {string} [municipalityId] 
         * @param {string} [placeOfBirth] 
         * @param {string} [address] 
         * @param {string} [personalNumber] 
         * @param {string} [organizationId] 
         * @param {string} [dateOfExam] 
         * @param {string} [certificateNumber] 
         * @param {string} [certificateDuration] 
         * @param {string} [qualificationTypeId] 
         * @param {string} [comments] 
         * @param {Array<File>} [files] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCertifiedTechniciansPost: async (firstName?: string, lastName?: string, email?: string, languageId?: string, municipalityId?: string, placeOfBirth?: string, address?: string, personalNumber?: string, organizationId?: string, dateOfExam?: string, certificateNumber?: string, certificateDuration?: string, qualificationTypeId?: string, comments?: string, files?: Array<File>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/CertifiedTechnicians`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


            if (firstName !== undefined) { 
                localVarFormParams.append('FirstName', firstName as any);
            }
    
            if (lastName !== undefined) { 
                localVarFormParams.append('LastName', lastName as any);
            }
    
            if (email !== undefined) { 
                localVarFormParams.append('Email', email as any);
            }
    
            if (languageId !== undefined) { 
                localVarFormParams.append('LanguageId', languageId as any);
            }
    
            if (municipalityId !== undefined) { 
                localVarFormParams.append('MunicipalityId', municipalityId as any);
            }
    
            if (placeOfBirth !== undefined) { 
                localVarFormParams.append('PlaceOfBirth', placeOfBirth as any);
            }
    
            if (address !== undefined) { 
                localVarFormParams.append('Address', address as any);
            }
    
            if (personalNumber !== undefined) { 
                localVarFormParams.append('PersonalNumber', personalNumber as any);
            }
    
            if (organizationId !== undefined) { 
                localVarFormParams.append('OrganizationId', organizationId as any);
            }
    
            if (dateOfExam !== undefined) { 
                localVarFormParams.append('DateOfExam', dateOfExam as any);
            }
    
            if (certificateNumber !== undefined) { 
                localVarFormParams.append('CertificateNumber', certificateNumber as any);
            }
    
            if (certificateDuration !== undefined) { 
                localVarFormParams.append('CertificateDuration', certificateDuration as any);
            }
    
            if (qualificationTypeId !== undefined) { 
                localVarFormParams.append('QualificationTypeId', qualificationTypeId as any);
            }
    
            if (comments !== undefined) { 
                localVarFormParams.append('Comments', comments as any);
            }
                if (files) {
                files.forEach((element) => {
                    localVarFormParams.append('Files', element as any);
                })
            }

    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [certifiedTechnicianId] 
         * @param {string} [certificateNumber] 
         * @param {string} [qualificationTypeId] 
         * @param {string} [trainingCenterId] 
         * @param {string} [dateOfExam] 
         * @param {string} [certificateDuration] 
         * @param {Array<File>} [files] 
         * @param {boolean} [ignoreSendingNotifications] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCertifiedTechniciansQualificationsAddPost: async (certifiedTechnicianId?: string, certificateNumber?: string, qualificationTypeId?: string, trainingCenterId?: string, dateOfExam?: string, certificateDuration?: string, files?: Array<File>, ignoreSendingNotifications?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/CertifiedTechnicians/qualifications/add`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


            if (certifiedTechnicianId !== undefined) { 
                localVarFormParams.append('CertifiedTechnicianId', certifiedTechnicianId as any);
            }
    
            if (certificateNumber !== undefined) { 
                localVarFormParams.append('CertificateNumber', certificateNumber as any);
            }
    
            if (qualificationTypeId !== undefined) { 
                localVarFormParams.append('QualificationTypeId', qualificationTypeId as any);
            }
    
            if (trainingCenterId !== undefined) { 
                localVarFormParams.append('TrainingCenterId', trainingCenterId as any);
            }
    
            if (dateOfExam !== undefined) { 
                localVarFormParams.append('DateOfExam', dateOfExam as any);
            }
    
            if (certificateDuration !== undefined) { 
                localVarFormParams.append('CertificateDuration', certificateDuration as any);
            }
                if (files) {
                files.forEach((element) => {
                    localVarFormParams.append('Files', element as any);
                })
            }

    
            if (ignoreSendingNotifications !== undefined) { 
                localVarFormParams.append('IgnoreSendingNotifications', ignoreSendingNotifications as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [qualificationId] 
         * @param {string} [certificateNumber] 
         * @param {string} [qualificationTypeId] 
         * @param {string} [dateOfExam] 
         * @param {string} [certificateDuration] 
         * @param {Array<string>} [existingFileNames] 
         * @param {Array<string>} [toBeDeletedFileNames] 
         * @param {Array<File>} [files] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCertifiedTechniciansQualificationsUpdatePost: async (qualificationId?: string, certificateNumber?: string, qualificationTypeId?: string, dateOfExam?: string, certificateDuration?: string, existingFileNames?: Array<string>, toBeDeletedFileNames?: Array<string>, files?: Array<File>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/CertifiedTechnicians/qualifications/update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


            if (qualificationId !== undefined) { 
                localVarFormParams.append('QualificationId', qualificationId as any);
            }
    
            if (certificateNumber !== undefined) { 
                localVarFormParams.append('CertificateNumber', certificateNumber as any);
            }
    
            if (qualificationTypeId !== undefined) { 
                localVarFormParams.append('QualificationTypeId', qualificationTypeId as any);
            }
    
            if (dateOfExam !== undefined) { 
                localVarFormParams.append('DateOfExam', dateOfExam as any);
            }
    
            if (certificateDuration !== undefined) { 
                localVarFormParams.append('CertificateDuration', certificateDuration as any);
            }
                if (existingFileNames) {
                existingFileNames.forEach((element) => {
                    localVarFormParams.append('ExistingFileNames', element as any);
                })
            }

                if (toBeDeletedFileNames) {
                toBeDeletedFileNames.forEach((element) => {
                    localVarFormParams.append('ToBeDeletedFileNames', element as any);
                })
            }

                if (files) {
                files.forEach((element) => {
                    localVarFormParams.append('Files', element as any);
                })
            }

    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CertifiedTechniciansApi - functional programming interface
 * @export
 */
export const CertifiedTechniciansApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CertifiedTechniciansApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} serialNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCertifiedTechniciansBySerialNumberSerialNumberGet(serialNumber: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CertifiedTechnicianDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCertifiedTechniciansBySerialNumberSerialNumberGet(serialNumber, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [search] 
         * @param {string} [sortingPropertyName] 
         * @param {boolean} [sortingIsDescending] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCertifiedTechniciansCompanyTechniciansGet(pageNumber?: number, pageSize?: number, search?: string, sortingPropertyName?: string, sortingIsDescending?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CertifiedTechnicianDtoPaginatedList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCertifiedTechniciansCompanyTechniciansGet(pageNumber, pageSize, search, sortingPropertyName, sortingIsDescending, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCertifiedTechniciansEmploymentHistoryGet(id?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CertifiedTechnicianDtoPaginatedList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCertifiedTechniciansEmploymentHistoryGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCertifiedTechniciansEndCurrentEmploymentPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCertifiedTechniciansEndCurrentEmploymentPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCertifiedTechniciansIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CertifiedTechnicianDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCertifiedTechniciansIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateUserCommand} [updateUserCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCertifiedTechniciansIdPut(id: string, updateUserCommand?: UpdateUserCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCertifiedTechniciansIdPut(id, updateUserCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCertifiedTechniciansIdQualificationsGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CertifiedTechnicianDtoPaginatedList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCertifiedTechniciansIdQualificationsGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {RecordEmploymentDto} [recordEmploymentDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCertifiedTechniciansIdRecordEmploymentPost(id: string, recordEmploymentDto?: RecordEmploymentDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCertifiedTechniciansIdRecordEmploymentPost(id, recordEmploymentDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCertifiedTechniciansIdTerminateEmploymentPost(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCertifiedTechniciansIdTerminateEmploymentPost(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [search] 
         * @param {string} [organizationId] 
         * @param {UserFilterType} [filterType] 
         * @param {string} [sortingPropertyName] 
         * @param {boolean} [sortingIsDescending] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCertifiedTechniciansListGet(pageNumber?: number, pageSize?: number, search?: string, organizationId?: string, filterType?: UserFilterType, sortingPropertyName?: string, sortingIsDescending?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CertifiedTechnicianDtoPaginatedList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCertifiedTechniciansListGet(pageNumber, pageSize, search, organizationId, filterType, sortingPropertyName, sortingIsDescending, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [firstName] 
         * @param {string} [lastName] 
         * @param {string} [email] 
         * @param {string} [languageId] 
         * @param {string} [municipalityId] 
         * @param {string} [placeOfBirth] 
         * @param {string} [address] 
         * @param {string} [personalNumber] 
         * @param {string} [organizationId] 
         * @param {string} [dateOfExam] 
         * @param {string} [certificateNumber] 
         * @param {string} [certificateDuration] 
         * @param {string} [qualificationTypeId] 
         * @param {string} [comments] 
         * @param {Array<File>} [files] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCertifiedTechniciansPost(firstName?: string, lastName?: string, email?: string, languageId?: string, municipalityId?: string, placeOfBirth?: string, address?: string, personalNumber?: string, organizationId?: string, dateOfExam?: string, certificateNumber?: string, certificateDuration?: string, qualificationTypeId?: string, comments?: string, files?: Array<File>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCertifiedTechniciansPost(firstName, lastName, email, languageId, municipalityId, placeOfBirth, address, personalNumber, organizationId, dateOfExam, certificateNumber, certificateDuration, qualificationTypeId, comments, files, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [certifiedTechnicianId] 
         * @param {string} [certificateNumber] 
         * @param {string} [qualificationTypeId] 
         * @param {string} [trainingCenterId] 
         * @param {string} [dateOfExam] 
         * @param {string} [certificateDuration] 
         * @param {Array<File>} [files] 
         * @param {boolean} [ignoreSendingNotifications] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCertifiedTechniciansQualificationsAddPost(certifiedTechnicianId?: string, certificateNumber?: string, qualificationTypeId?: string, trainingCenterId?: string, dateOfExam?: string, certificateDuration?: string, files?: Array<File>, ignoreSendingNotifications?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCertifiedTechniciansQualificationsAddPost(certifiedTechnicianId, certificateNumber, qualificationTypeId, trainingCenterId, dateOfExam, certificateDuration, files, ignoreSendingNotifications, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [qualificationId] 
         * @param {string} [certificateNumber] 
         * @param {string} [qualificationTypeId] 
         * @param {string} [dateOfExam] 
         * @param {string} [certificateDuration] 
         * @param {Array<string>} [existingFileNames] 
         * @param {Array<string>} [toBeDeletedFileNames] 
         * @param {Array<File>} [files] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCertifiedTechniciansQualificationsUpdatePost(qualificationId?: string, certificateNumber?: string, qualificationTypeId?: string, dateOfExam?: string, certificateDuration?: string, existingFileNames?: Array<string>, toBeDeletedFileNames?: Array<string>, files?: Array<File>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCertifiedTechniciansQualificationsUpdatePost(qualificationId, certificateNumber, qualificationTypeId, dateOfExam, certificateDuration, existingFileNames, toBeDeletedFileNames, files, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CertifiedTechniciansApi - factory interface
 * @export
 */
export const CertifiedTechniciansApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CertifiedTechniciansApiFp(configuration)
    return {
        /**
         * 
         * @param {string} serialNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCertifiedTechniciansBySerialNumberSerialNumberGet(serialNumber: string, options?: any): AxiosPromise<CertifiedTechnicianDto> {
            return localVarFp.apiCertifiedTechniciansBySerialNumberSerialNumberGet(serialNumber, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [search] 
         * @param {string} [sortingPropertyName] 
         * @param {boolean} [sortingIsDescending] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCertifiedTechniciansCompanyTechniciansGet(pageNumber?: number, pageSize?: number, search?: string, sortingPropertyName?: string, sortingIsDescending?: boolean, options?: any): AxiosPromise<CertifiedTechnicianDtoPaginatedList> {
            return localVarFp.apiCertifiedTechniciansCompanyTechniciansGet(pageNumber, pageSize, search, sortingPropertyName, sortingIsDescending, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCertifiedTechniciansEmploymentHistoryGet(id?: string, options?: any): AxiosPromise<CertifiedTechnicianDtoPaginatedList> {
            return localVarFp.apiCertifiedTechniciansEmploymentHistoryGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCertifiedTechniciansEndCurrentEmploymentPost(options?: any): AxiosPromise<void> {
            return localVarFp.apiCertifiedTechniciansEndCurrentEmploymentPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCertifiedTechniciansIdGet(id: string, options?: any): AxiosPromise<CertifiedTechnicianDto> {
            return localVarFp.apiCertifiedTechniciansIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateUserCommand} [updateUserCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCertifiedTechniciansIdPut(id: string, updateUserCommand?: UpdateUserCommand, options?: any): AxiosPromise<void> {
            return localVarFp.apiCertifiedTechniciansIdPut(id, updateUserCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCertifiedTechniciansIdQualificationsGet(id: string, options?: any): AxiosPromise<CertifiedTechnicianDtoPaginatedList> {
            return localVarFp.apiCertifiedTechniciansIdQualificationsGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {RecordEmploymentDto} [recordEmploymentDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCertifiedTechniciansIdRecordEmploymentPost(id: string, recordEmploymentDto?: RecordEmploymentDto, options?: any): AxiosPromise<void> {
            return localVarFp.apiCertifiedTechniciansIdRecordEmploymentPost(id, recordEmploymentDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCertifiedTechniciansIdTerminateEmploymentPost(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiCertifiedTechniciansIdTerminateEmploymentPost(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [search] 
         * @param {string} [organizationId] 
         * @param {UserFilterType} [filterType] 
         * @param {string} [sortingPropertyName] 
         * @param {boolean} [sortingIsDescending] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCertifiedTechniciansListGet(pageNumber?: number, pageSize?: number, search?: string, organizationId?: string, filterType?: UserFilterType, sortingPropertyName?: string, sortingIsDescending?: boolean, options?: any): AxiosPromise<CertifiedTechnicianDtoPaginatedList> {
            return localVarFp.apiCertifiedTechniciansListGet(pageNumber, pageSize, search, organizationId, filterType, sortingPropertyName, sortingIsDescending, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [firstName] 
         * @param {string} [lastName] 
         * @param {string} [email] 
         * @param {string} [languageId] 
         * @param {string} [municipalityId] 
         * @param {string} [placeOfBirth] 
         * @param {string} [address] 
         * @param {string} [personalNumber] 
         * @param {string} [organizationId] 
         * @param {string} [dateOfExam] 
         * @param {string} [certificateNumber] 
         * @param {string} [certificateDuration] 
         * @param {string} [qualificationTypeId] 
         * @param {string} [comments] 
         * @param {Array<File>} [files] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCertifiedTechniciansPost(firstName?: string, lastName?: string, email?: string, languageId?: string, municipalityId?: string, placeOfBirth?: string, address?: string, personalNumber?: string, organizationId?: string, dateOfExam?: string, certificateNumber?: string, certificateDuration?: string, qualificationTypeId?: string, comments?: string, files?: Array<File>, options?: any): AxiosPromise<void> {
            return localVarFp.apiCertifiedTechniciansPost(firstName, lastName, email, languageId, municipalityId, placeOfBirth, address, personalNumber, organizationId, dateOfExam, certificateNumber, certificateDuration, qualificationTypeId, comments, files, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [certifiedTechnicianId] 
         * @param {string} [certificateNumber] 
         * @param {string} [qualificationTypeId] 
         * @param {string} [trainingCenterId] 
         * @param {string} [dateOfExam] 
         * @param {string} [certificateDuration] 
         * @param {Array<File>} [files] 
         * @param {boolean} [ignoreSendingNotifications] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCertifiedTechniciansQualificationsAddPost(certifiedTechnicianId?: string, certificateNumber?: string, qualificationTypeId?: string, trainingCenterId?: string, dateOfExam?: string, certificateDuration?: string, files?: Array<File>, ignoreSendingNotifications?: boolean, options?: any): AxiosPromise<void> {
            return localVarFp.apiCertifiedTechniciansQualificationsAddPost(certifiedTechnicianId, certificateNumber, qualificationTypeId, trainingCenterId, dateOfExam, certificateDuration, files, ignoreSendingNotifications, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [qualificationId] 
         * @param {string} [certificateNumber] 
         * @param {string} [qualificationTypeId] 
         * @param {string} [dateOfExam] 
         * @param {string} [certificateDuration] 
         * @param {Array<string>} [existingFileNames] 
         * @param {Array<string>} [toBeDeletedFileNames] 
         * @param {Array<File>} [files] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCertifiedTechniciansQualificationsUpdatePost(qualificationId?: string, certificateNumber?: string, qualificationTypeId?: string, dateOfExam?: string, certificateDuration?: string, existingFileNames?: Array<string>, toBeDeletedFileNames?: Array<string>, files?: Array<File>, options?: any): AxiosPromise<void> {
            return localVarFp.apiCertifiedTechniciansQualificationsUpdatePost(qualificationId, certificateNumber, qualificationTypeId, dateOfExam, certificateDuration, existingFileNames, toBeDeletedFileNames, files, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiCertifiedTechniciansBySerialNumberSerialNumberGet operation in CertifiedTechniciansApi.
 * @export
 * @interface CertifiedTechniciansApiApiCertifiedTechniciansBySerialNumberSerialNumberGetRequest
 */
export interface CertifiedTechniciansApiApiCertifiedTechniciansBySerialNumberSerialNumberGetRequest {
    /**
     * 
     * @type {string}
     * @memberof CertifiedTechniciansApiApiCertifiedTechniciansBySerialNumberSerialNumberGet
     */
    readonly serialNumber: string
}

/**
 * Request parameters for apiCertifiedTechniciansCompanyTechniciansGet operation in CertifiedTechniciansApi.
 * @export
 * @interface CertifiedTechniciansApiApiCertifiedTechniciansCompanyTechniciansGetRequest
 */
export interface CertifiedTechniciansApiApiCertifiedTechniciansCompanyTechniciansGetRequest {
    /**
     * 
     * @type {number}
     * @memberof CertifiedTechniciansApiApiCertifiedTechniciansCompanyTechniciansGet
     */
    readonly pageNumber?: number

    /**
     * 
     * @type {number}
     * @memberof CertifiedTechniciansApiApiCertifiedTechniciansCompanyTechniciansGet
     */
    readonly pageSize?: number

    /**
     * 
     * @type {string}
     * @memberof CertifiedTechniciansApiApiCertifiedTechniciansCompanyTechniciansGet
     */
    readonly search?: string

    /**
     * 
     * @type {string}
     * @memberof CertifiedTechniciansApiApiCertifiedTechniciansCompanyTechniciansGet
     */
    readonly sortingPropertyName?: string

    /**
     * 
     * @type {boolean}
     * @memberof CertifiedTechniciansApiApiCertifiedTechniciansCompanyTechniciansGet
     */
    readonly sortingIsDescending?: boolean
}

/**
 * Request parameters for apiCertifiedTechniciansEmploymentHistoryGet operation in CertifiedTechniciansApi.
 * @export
 * @interface CertifiedTechniciansApiApiCertifiedTechniciansEmploymentHistoryGetRequest
 */
export interface CertifiedTechniciansApiApiCertifiedTechniciansEmploymentHistoryGetRequest {
    /**
     * 
     * @type {string}
     * @memberof CertifiedTechniciansApiApiCertifiedTechniciansEmploymentHistoryGet
     */
    readonly id?: string
}

/**
 * Request parameters for apiCertifiedTechniciansIdGet operation in CertifiedTechniciansApi.
 * @export
 * @interface CertifiedTechniciansApiApiCertifiedTechniciansIdGetRequest
 */
export interface CertifiedTechniciansApiApiCertifiedTechniciansIdGetRequest {
    /**
     * 
     * @type {string}
     * @memberof CertifiedTechniciansApiApiCertifiedTechniciansIdGet
     */
    readonly id: string
}

/**
 * Request parameters for apiCertifiedTechniciansIdPut operation in CertifiedTechniciansApi.
 * @export
 * @interface CertifiedTechniciansApiApiCertifiedTechniciansIdPutRequest
 */
export interface CertifiedTechniciansApiApiCertifiedTechniciansIdPutRequest {
    /**
     * 
     * @type {string}
     * @memberof CertifiedTechniciansApiApiCertifiedTechniciansIdPut
     */
    readonly id: string

    /**
     * 
     * @type {UpdateUserCommand}
     * @memberof CertifiedTechniciansApiApiCertifiedTechniciansIdPut
     */
    readonly updateUserCommand?: UpdateUserCommand
}

/**
 * Request parameters for apiCertifiedTechniciansIdQualificationsGet operation in CertifiedTechniciansApi.
 * @export
 * @interface CertifiedTechniciansApiApiCertifiedTechniciansIdQualificationsGetRequest
 */
export interface CertifiedTechniciansApiApiCertifiedTechniciansIdQualificationsGetRequest {
    /**
     * 
     * @type {string}
     * @memberof CertifiedTechniciansApiApiCertifiedTechniciansIdQualificationsGet
     */
    readonly id: string
}

/**
 * Request parameters for apiCertifiedTechniciansIdRecordEmploymentPost operation in CertifiedTechniciansApi.
 * @export
 * @interface CertifiedTechniciansApiApiCertifiedTechniciansIdRecordEmploymentPostRequest
 */
export interface CertifiedTechniciansApiApiCertifiedTechniciansIdRecordEmploymentPostRequest {
    /**
     * 
     * @type {string}
     * @memberof CertifiedTechniciansApiApiCertifiedTechniciansIdRecordEmploymentPost
     */
    readonly id: string

    /**
     * 
     * @type {RecordEmploymentDto}
     * @memberof CertifiedTechniciansApiApiCertifiedTechniciansIdRecordEmploymentPost
     */
    readonly recordEmploymentDto?: RecordEmploymentDto
}

/**
 * Request parameters for apiCertifiedTechniciansIdTerminateEmploymentPost operation in CertifiedTechniciansApi.
 * @export
 * @interface CertifiedTechniciansApiApiCertifiedTechniciansIdTerminateEmploymentPostRequest
 */
export interface CertifiedTechniciansApiApiCertifiedTechniciansIdTerminateEmploymentPostRequest {
    /**
     * 
     * @type {string}
     * @memberof CertifiedTechniciansApiApiCertifiedTechniciansIdTerminateEmploymentPost
     */
    readonly id: string
}

/**
 * Request parameters for apiCertifiedTechniciansListGet operation in CertifiedTechniciansApi.
 * @export
 * @interface CertifiedTechniciansApiApiCertifiedTechniciansListGetRequest
 */
export interface CertifiedTechniciansApiApiCertifiedTechniciansListGetRequest {
    /**
     * 
     * @type {number}
     * @memberof CertifiedTechniciansApiApiCertifiedTechniciansListGet
     */
    readonly pageNumber?: number

    /**
     * 
     * @type {number}
     * @memberof CertifiedTechniciansApiApiCertifiedTechniciansListGet
     */
    readonly pageSize?: number

    /**
     * 
     * @type {string}
     * @memberof CertifiedTechniciansApiApiCertifiedTechniciansListGet
     */
    readonly search?: string

    /**
     * 
     * @type {string}
     * @memberof CertifiedTechniciansApiApiCertifiedTechniciansListGet
     */
    readonly organizationId?: string

    /**
     * 
     * @type {UserFilterType}
     * @memberof CertifiedTechniciansApiApiCertifiedTechniciansListGet
     */
    readonly filterType?: UserFilterType

    /**
     * 
     * @type {string}
     * @memberof CertifiedTechniciansApiApiCertifiedTechniciansListGet
     */
    readonly sortingPropertyName?: string

    /**
     * 
     * @type {boolean}
     * @memberof CertifiedTechniciansApiApiCertifiedTechniciansListGet
     */
    readonly sortingIsDescending?: boolean
}

/**
 * Request parameters for apiCertifiedTechniciansPost operation in CertifiedTechniciansApi.
 * @export
 * @interface CertifiedTechniciansApiApiCertifiedTechniciansPostRequest
 */
export interface CertifiedTechniciansApiApiCertifiedTechniciansPostRequest {
    /**
     * 
     * @type {string}
     * @memberof CertifiedTechniciansApiApiCertifiedTechniciansPost
     */
    readonly firstName?: string

    /**
     * 
     * @type {string}
     * @memberof CertifiedTechniciansApiApiCertifiedTechniciansPost
     */
    readonly lastName?: string

    /**
     * 
     * @type {string}
     * @memberof CertifiedTechniciansApiApiCertifiedTechniciansPost
     */
    readonly email?: string

    /**
     * 
     * @type {string}
     * @memberof CertifiedTechniciansApiApiCertifiedTechniciansPost
     */
    readonly languageId?: string

    /**
     * 
     * @type {string}
     * @memberof CertifiedTechniciansApiApiCertifiedTechniciansPost
     */
    readonly municipalityId?: string

    /**
     * 
     * @type {string}
     * @memberof CertifiedTechniciansApiApiCertifiedTechniciansPost
     */
    readonly placeOfBirth?: string

    /**
     * 
     * @type {string}
     * @memberof CertifiedTechniciansApiApiCertifiedTechniciansPost
     */
    readonly address?: string

    /**
     * 
     * @type {string}
     * @memberof CertifiedTechniciansApiApiCertifiedTechniciansPost
     */
    readonly personalNumber?: string

    /**
     * 
     * @type {string}
     * @memberof CertifiedTechniciansApiApiCertifiedTechniciansPost
     */
    readonly organizationId?: string

    /**
     * 
     * @type {string}
     * @memberof CertifiedTechniciansApiApiCertifiedTechniciansPost
     */
    readonly dateOfExam?: string

    /**
     * 
     * @type {string}
     * @memberof CertifiedTechniciansApiApiCertifiedTechniciansPost
     */
    readonly certificateNumber?: string

    /**
     * 
     * @type {string}
     * @memberof CertifiedTechniciansApiApiCertifiedTechniciansPost
     */
    readonly certificateDuration?: string

    /**
     * 
     * @type {string}
     * @memberof CertifiedTechniciansApiApiCertifiedTechniciansPost
     */
    readonly qualificationTypeId?: string

    /**
     * 
     * @type {string}
     * @memberof CertifiedTechniciansApiApiCertifiedTechniciansPost
     */
    readonly comments?: string

    /**
     * 
     * @type {Array<File>}
     * @memberof CertifiedTechniciansApiApiCertifiedTechniciansPost
     */
    readonly files?: Array<File>
}

/**
 * Request parameters for apiCertifiedTechniciansQualificationsAddPost operation in CertifiedTechniciansApi.
 * @export
 * @interface CertifiedTechniciansApiApiCertifiedTechniciansQualificationsAddPostRequest
 */
export interface CertifiedTechniciansApiApiCertifiedTechniciansQualificationsAddPostRequest {
    /**
     * 
     * @type {string}
     * @memberof CertifiedTechniciansApiApiCertifiedTechniciansQualificationsAddPost
     */
    readonly certifiedTechnicianId?: string

    /**
     * 
     * @type {string}
     * @memberof CertifiedTechniciansApiApiCertifiedTechniciansQualificationsAddPost
     */
    readonly certificateNumber?: string

    /**
     * 
     * @type {string}
     * @memberof CertifiedTechniciansApiApiCertifiedTechniciansQualificationsAddPost
     */
    readonly qualificationTypeId?: string

    /**
     * 
     * @type {string}
     * @memberof CertifiedTechniciansApiApiCertifiedTechniciansQualificationsAddPost
     */
    readonly trainingCenterId?: string

    /**
     * 
     * @type {string}
     * @memberof CertifiedTechniciansApiApiCertifiedTechniciansQualificationsAddPost
     */
    readonly dateOfExam?: string

    /**
     * 
     * @type {string}
     * @memberof CertifiedTechniciansApiApiCertifiedTechniciansQualificationsAddPost
     */
    readonly certificateDuration?: string

    /**
     * 
     * @type {Array<File>}
     * @memberof CertifiedTechniciansApiApiCertifiedTechniciansQualificationsAddPost
     */
    readonly files?: Array<File>

    /**
     * 
     * @type {boolean}
     * @memberof CertifiedTechniciansApiApiCertifiedTechniciansQualificationsAddPost
     */
    readonly ignoreSendingNotifications?: boolean
}

/**
 * Request parameters for apiCertifiedTechniciansQualificationsUpdatePost operation in CertifiedTechniciansApi.
 * @export
 * @interface CertifiedTechniciansApiApiCertifiedTechniciansQualificationsUpdatePostRequest
 */
export interface CertifiedTechniciansApiApiCertifiedTechniciansQualificationsUpdatePostRequest {
    /**
     * 
     * @type {string}
     * @memberof CertifiedTechniciansApiApiCertifiedTechniciansQualificationsUpdatePost
     */
    readonly qualificationId?: string

    /**
     * 
     * @type {string}
     * @memberof CertifiedTechniciansApiApiCertifiedTechniciansQualificationsUpdatePost
     */
    readonly certificateNumber?: string

    /**
     * 
     * @type {string}
     * @memberof CertifiedTechniciansApiApiCertifiedTechniciansQualificationsUpdatePost
     */
    readonly qualificationTypeId?: string

    /**
     * 
     * @type {string}
     * @memberof CertifiedTechniciansApiApiCertifiedTechniciansQualificationsUpdatePost
     */
    readonly dateOfExam?: string

    /**
     * 
     * @type {string}
     * @memberof CertifiedTechniciansApiApiCertifiedTechniciansQualificationsUpdatePost
     */
    readonly certificateDuration?: string

    /**
     * 
     * @type {Array<string>}
     * @memberof CertifiedTechniciansApiApiCertifiedTechniciansQualificationsUpdatePost
     */
    readonly existingFileNames?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof CertifiedTechniciansApiApiCertifiedTechniciansQualificationsUpdatePost
     */
    readonly toBeDeletedFileNames?: Array<string>

    /**
     * 
     * @type {Array<File>}
     * @memberof CertifiedTechniciansApiApiCertifiedTechniciansQualificationsUpdatePost
     */
    readonly files?: Array<File>
}

/**
 * CertifiedTechniciansApi - object-oriented interface
 * @export
 * @class CertifiedTechniciansApi
 * @extends {BaseAPI}
 */
export class CertifiedTechniciansApi extends BaseAPI {
    /**
     * 
     * @param {CertifiedTechniciansApiApiCertifiedTechniciansBySerialNumberSerialNumberGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CertifiedTechniciansApi
     */
    public apiCertifiedTechniciansBySerialNumberSerialNumberGet(requestParameters: CertifiedTechniciansApiApiCertifiedTechniciansBySerialNumberSerialNumberGetRequest, options?: AxiosRequestConfig) {
        return CertifiedTechniciansApiFp(this.configuration).apiCertifiedTechniciansBySerialNumberSerialNumberGet(requestParameters.serialNumber, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CertifiedTechniciansApiApiCertifiedTechniciansCompanyTechniciansGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CertifiedTechniciansApi
     */
    public apiCertifiedTechniciansCompanyTechniciansGet(requestParameters: CertifiedTechniciansApiApiCertifiedTechniciansCompanyTechniciansGetRequest = {}, options?: AxiosRequestConfig) {
        return CertifiedTechniciansApiFp(this.configuration).apiCertifiedTechniciansCompanyTechniciansGet(requestParameters.pageNumber, requestParameters.pageSize, requestParameters.search, requestParameters.sortingPropertyName, requestParameters.sortingIsDescending, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CertifiedTechniciansApiApiCertifiedTechniciansEmploymentHistoryGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CertifiedTechniciansApi
     */
    public apiCertifiedTechniciansEmploymentHistoryGet(requestParameters: CertifiedTechniciansApiApiCertifiedTechniciansEmploymentHistoryGetRequest = {}, options?: AxiosRequestConfig) {
        return CertifiedTechniciansApiFp(this.configuration).apiCertifiedTechniciansEmploymentHistoryGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CertifiedTechniciansApi
     */
    public apiCertifiedTechniciansEndCurrentEmploymentPost(options?: AxiosRequestConfig) {
        return CertifiedTechniciansApiFp(this.configuration).apiCertifiedTechniciansEndCurrentEmploymentPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CertifiedTechniciansApiApiCertifiedTechniciansIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CertifiedTechniciansApi
     */
    public apiCertifiedTechniciansIdGet(requestParameters: CertifiedTechniciansApiApiCertifiedTechniciansIdGetRequest, options?: AxiosRequestConfig) {
        return CertifiedTechniciansApiFp(this.configuration).apiCertifiedTechniciansIdGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CertifiedTechniciansApiApiCertifiedTechniciansIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CertifiedTechniciansApi
     */
    public apiCertifiedTechniciansIdPut(requestParameters: CertifiedTechniciansApiApiCertifiedTechniciansIdPutRequest, options?: AxiosRequestConfig) {
        return CertifiedTechniciansApiFp(this.configuration).apiCertifiedTechniciansIdPut(requestParameters.id, requestParameters.updateUserCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CertifiedTechniciansApiApiCertifiedTechniciansIdQualificationsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CertifiedTechniciansApi
     */
    public apiCertifiedTechniciansIdQualificationsGet(requestParameters: CertifiedTechniciansApiApiCertifiedTechniciansIdQualificationsGetRequest, options?: AxiosRequestConfig) {
        return CertifiedTechniciansApiFp(this.configuration).apiCertifiedTechniciansIdQualificationsGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CertifiedTechniciansApiApiCertifiedTechniciansIdRecordEmploymentPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CertifiedTechniciansApi
     */
    public apiCertifiedTechniciansIdRecordEmploymentPost(requestParameters: CertifiedTechniciansApiApiCertifiedTechniciansIdRecordEmploymentPostRequest, options?: AxiosRequestConfig) {
        return CertifiedTechniciansApiFp(this.configuration).apiCertifiedTechniciansIdRecordEmploymentPost(requestParameters.id, requestParameters.recordEmploymentDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CertifiedTechniciansApiApiCertifiedTechniciansIdTerminateEmploymentPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CertifiedTechniciansApi
     */
    public apiCertifiedTechniciansIdTerminateEmploymentPost(requestParameters: CertifiedTechniciansApiApiCertifiedTechniciansIdTerminateEmploymentPostRequest, options?: AxiosRequestConfig) {
        return CertifiedTechniciansApiFp(this.configuration).apiCertifiedTechniciansIdTerminateEmploymentPost(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CertifiedTechniciansApiApiCertifiedTechniciansListGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CertifiedTechniciansApi
     */
    public apiCertifiedTechniciansListGet(requestParameters: CertifiedTechniciansApiApiCertifiedTechniciansListGetRequest = {}, options?: AxiosRequestConfig) {
        return CertifiedTechniciansApiFp(this.configuration).apiCertifiedTechniciansListGet(requestParameters.pageNumber, requestParameters.pageSize, requestParameters.search, requestParameters.organizationId, requestParameters.filterType, requestParameters.sortingPropertyName, requestParameters.sortingIsDescending, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CertifiedTechniciansApiApiCertifiedTechniciansPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CertifiedTechniciansApi
     */
    public apiCertifiedTechniciansPost(requestParameters: CertifiedTechniciansApiApiCertifiedTechniciansPostRequest = {}, options?: AxiosRequestConfig) {
        return CertifiedTechniciansApiFp(this.configuration).apiCertifiedTechniciansPost(requestParameters.firstName, requestParameters.lastName, requestParameters.email, requestParameters.languageId, requestParameters.municipalityId, requestParameters.placeOfBirth, requestParameters.address, requestParameters.personalNumber, requestParameters.organizationId, requestParameters.dateOfExam, requestParameters.certificateNumber, requestParameters.certificateDuration, requestParameters.qualificationTypeId, requestParameters.comments, requestParameters.files, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CertifiedTechniciansApiApiCertifiedTechniciansQualificationsAddPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CertifiedTechniciansApi
     */
    public apiCertifiedTechniciansQualificationsAddPost(requestParameters: CertifiedTechniciansApiApiCertifiedTechniciansQualificationsAddPostRequest = {}, options?: AxiosRequestConfig) {
        return CertifiedTechniciansApiFp(this.configuration).apiCertifiedTechniciansQualificationsAddPost(requestParameters.certifiedTechnicianId, requestParameters.certificateNumber, requestParameters.qualificationTypeId, requestParameters.trainingCenterId, requestParameters.dateOfExam, requestParameters.certificateDuration, requestParameters.files, requestParameters.ignoreSendingNotifications, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CertifiedTechniciansApiApiCertifiedTechniciansQualificationsUpdatePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CertifiedTechniciansApi
     */
    public apiCertifiedTechniciansQualificationsUpdatePost(requestParameters: CertifiedTechniciansApiApiCertifiedTechniciansQualificationsUpdatePostRequest = {}, options?: AxiosRequestConfig) {
        return CertifiedTechniciansApiFp(this.configuration).apiCertifiedTechniciansQualificationsUpdatePost(requestParameters.qualificationId, requestParameters.certificateNumber, requestParameters.qualificationTypeId, requestParameters.dateOfExam, requestParameters.certificateDuration, requestParameters.existingFileNames, requestParameters.toBeDeletedFileNames, requestParameters.files, options).then((request) => request(this.axios, this.basePath));
    }
}
