import { Button, Checkbox, Form, Input } from "antd";
import { NavLink, useNavigate } from "react-router-dom";
import { AuthWrapper } from "./style";
import { AuthApi } from "api";

//@ts-ignore
import FeatherIcon from "feather-icons-react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { decodeJWT } from "utility/decode-jwt";

const authApi = new AuthApi();

function SignIn() {
  const { t, i18n } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const navigate = useNavigate();
  const [form] = Form.useForm();

  useEffect(() => {
    const isLoggedIn = localStorage.getItem("user");
    if (isLoggedIn) {
      navigate("/", { replace: true });
    }
  }, [navigate]);
  const handleFormSubmit = async () => {
    try {
      setIsLoading(true);

      const payload = form.getFieldsValue();

      const { data } = await authApi.apiAuthLoginPost({
        loginCommand: { ...payload, rememberMe },
      });

      localStorage.setItem("user", JSON.stringify(data));

      const token: any = decodeJWT();
      if (token) {
        const languagecode = token?.language;
        i18n.changeLanguage(languagecode);
      }

      let navigateTo = "/";
      if (data.shouldShowOnboarding) navigateTo = "/set-new-password";

      navigate(navigateTo, { replace: true });
    } catch (error) {
      // Handle error
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <AuthWrapper>
      <div className="auth-contents">
        <Form
          name="signIn"
          form={form}
          style={{ paddingTop: 100 }}
          onFinish={handleFormSubmit}
          layout="vertical"
        >
          <div style={{ fontWeight: "bold", fontSize: 40 }}>KGH</div>
          <div
            style={{ fontWeight: "bold", fontSize: 30, paddingBottom: 10 }}
            className="color-secondary"
          >
            Web Platforma
          </div>
          <Form.Item
            name="email"
            rules={[
              {
                message: t("signIn.emailRequired", {
                  defaultValue: "Please input your Email!",
                }),
                required: true,
              },
            ]}
          >
            <Input
              placeholder={t("signIn.emailPlaceholder", {
                defaultValue: "Email",
              })}
              prefix={<FeatherIcon icon="mail" size={14} />}
            />
          </Form.Item>
          <Form.Item name="password">
            <Input.Password
              placeholder={t("signIn.passwordPlaceholder", {
                defaultValue: "Password",
              })}
              prefix={<FeatherIcon icon="lock" size={14} />}
            />
          </Form.Item>
          <div className="auth-form-action">
            <Checkbox onChange={() => setRememberMe(!rememberMe)}>
              {t("signIn.remember-me", { defaultValue: "Remember me" })}
            </Checkbox>
            <NavLink className="forgot-pass-link" to="/forgot-password">
              {t("signIn.forgotPassword", {
                defaultValue: "Forgot password?",
              })}
            </NavLink>
          </div>
          <Form.Item>
            <Button
              className="btn-signin"
              htmlType="submit"
              type="primary"
              size="large"
              disabled={isLoading}
            >
              {isLoading
                ? t("signIn.loading", { defaultValue: "Loading..." })
                : t("signIn.signIn", { defaultValue: "Sign In" })}
            </Button>
          </Form.Item>
          <p className="form-divider" style={{ marginTop: 50 }}>
            <span>{t("signIn.or-read", { defaultValue: "or read" })}</span>
          </p>
        </Form>
      </div>
      <div className="auth-contents">
        <ul className="social-login">
          <li>
            <Link to="#">
              <span>FAQ</span>
            </Link>
          </li>
          <li>
            <Link to="#">
              <span>Guidelines</span>
            </Link>
          </li>
          <li>
            <Link to="#">
              <span>Legislation</span>
            </Link>
          </li>
          <li>
            <Link to="#">
              <span>Privacy Policy</span>
            </Link>
          </li>
        </ul>
      </div>
    </AuthWrapper>
  );
}

export default SignIn;
