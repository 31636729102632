/* tslint:disable */
/* eslint-disable */
/**
 * KGH PROJECT API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CompanyEntrepreneurDtoOrdinalPaginatedList } from '../models';
// @ts-ignore
import { ImporterExporterCompanyDtoOrdinalPaginatedList } from '../models';
// @ts-ignore
import { KGHServiceCompanyDtoOrdinalPaginatedList } from '../models';
// @ts-ignore
import { MarkedEquipmentDtoOrdinalPaginatedList } from '../models';
// @ts-ignore
import { OrganizationTypeEnum } from '../models';
// @ts-ignore
import { OwnerOperatorOfEquipmentDtoOrdinalPaginatedList } from '../models';
// @ts-ignore
import { RegisterDetailsDto } from '../models';
/**
 * RegistersApi - axios parameter creator
 * @export
 */
export const RegistersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [search] 
         * @param {string} [municipalityId] 
         * @param {string} [entityId] 
         * @param {boolean} [skipAccessCheck] 
         * @param {string} [sortingPropertyName] 
         * @param {boolean} [sortingIsDescending] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRegistersCompaniesEntrepreneursGet: async (pageNumber?: number, pageSize?: number, search?: string, municipalityId?: string, entityId?: string, skipAccessCheck?: boolean, sortingPropertyName?: string, sortingIsDescending?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Registers/companies-entrepreneurs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (pageNumber !== undefined) {
                localVarQueryParameter['PageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (search !== undefined) {
                localVarQueryParameter['Search'] = search;
            }

            if (municipalityId !== undefined) {
                localVarQueryParameter['MunicipalityId'] = municipalityId;
            }

            if (entityId !== undefined) {
                localVarQueryParameter['EntityId'] = entityId;
            }

            if (skipAccessCheck !== undefined) {
                localVarQueryParameter['SkipAccessCheck'] = skipAccessCheck;
            }

            if (sortingPropertyName !== undefined) {
                localVarQueryParameter['Sorting.PropertyName'] = sortingPropertyName;
            }

            if (sortingIsDescending !== undefined) {
                localVarQueryParameter['Sorting.IsDescending'] = sortingIsDescending;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
        * 
        * @param {number} [pageNumber] 
        * @param {number} [pageSize] 
        * @param {string} [search] 
        * @param {string} [municipalityId] 
        * @param {string} [entityId] 
        * @param {boolean} [skipAccessCheck] 
        * @param {string} [sortingPropertyName] 
        * @param {boolean} [sortingIsDescending] 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        apiYearGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ServiceTechnicianReport/year`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },


        /**
       * 
       * @param {number} [pageNumber] 
       * @param {number} [pageSize] 
       * @param {string} [search] 
       * @param {string} [municipalityId] 
       * @param {string} [entityId] 
       * @param {boolean} [skipAccessCheck] 
       * @param {string} [sortingPropertyName] 
       * @param {boolean} [sortingIsDescending] 
       * @param {*} [options] Override http request option.
       * @throws {RequiredError}
       */
        apiYearImportExportGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ImportExportSubstancesReport/year`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },

        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRegistersIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiRegistersIdGet', 'id', id)
            const localVarPath = `/api/Registers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [search] 
         * @param {string} [municipalityId] 
         * @param {OrganizationTypeEnum} [type] 
         * @param {boolean} [skipAccessCheck] 
         * @param {string} [sortingPropertyName] 
         * @param {boolean} [sortingIsDescending] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRegistersImportersExportersGet: async (pageNumber?: number, pageSize?: number, search?: string, municipalityId?: string, type?: OrganizationTypeEnum, skipAccessCheck?: boolean, sortingPropertyName?: string, sortingIsDescending?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Registers/importers-exporters`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (pageNumber !== undefined) {
                localVarQueryParameter['PageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (search !== undefined) {
                localVarQueryParameter['Search'] = search;
            }

            if (municipalityId !== undefined) {
                localVarQueryParameter['MunicipalityId'] = municipalityId;
            }

            if (type !== undefined) {
                localVarQueryParameter['Type'] = type;
            }

            if (skipAccessCheck !== undefined) {
                localVarQueryParameter['SkipAccessCheck'] = skipAccessCheck;
            }

            if (sortingPropertyName !== undefined) {
                localVarQueryParameter['Sorting.PropertyName'] = sortingPropertyName;
            }

            if (sortingIsDescending !== undefined) {
                localVarQueryParameter['Sorting.IsDescending'] = sortingIsDescending;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [search] 
         * @param {string} [municipalityId] 
         * @param {string} [businessActivityId] 
         * @param {OrganizationTypeEnum} [type] 
         * @param {boolean} [skipAccessCheck] 
         * @param {string} [sortingPropertyName] 
         * @param {boolean} [sortingIsDescending] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRegistersKghServiceCompaniesGet: async (pageNumber?: number, pageSize?: number, search?: string, municipalityId?: string, businessActivityId?: string, type?: OrganizationTypeEnum, skipAccessCheck?: boolean, sortingPropertyName?: string, sortingIsDescending?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Registers/kgh-service-companies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (pageNumber !== undefined) {
                localVarQueryParameter['PageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (search !== undefined) {
                localVarQueryParameter['Search'] = search;
            }

            if (municipalityId !== undefined) {
                localVarQueryParameter['MunicipalityId'] = municipalityId;
            }

            if (businessActivityId !== undefined) {
                localVarQueryParameter['BusinessActivityId'] = businessActivityId;
            }

            if (type !== undefined) {
                localVarQueryParameter['Type'] = type;
            }

            if (skipAccessCheck !== undefined) {
                localVarQueryParameter['SkipAccessCheck'] = skipAccessCheck;
            }

            if (sortingPropertyName !== undefined) {
                localVarQueryParameter['Sorting.PropertyName'] = sortingPropertyName;
            }

            if (sortingIsDescending !== undefined) {
                localVarQueryParameter['Sorting.IsDescending'] = sortingIsDescending;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [search] 
         * @param {string} [municipalityId] 
         * @param {string} [companyId] 
         * @param {string} [typeOfEquipmentId] 
         * @param {boolean} [skipAccessCheck] 
         * @param {string} [sortingPropertyName] 
         * @param {boolean} [sortingIsDescending] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRegistersMarkedEquipmentsGet: async (pageNumber?: number, pageSize?: number, search?: string, municipalityId?: string, companyId?: string, typeOfEquipmentId?: string, skipAccessCheck?: boolean, sortingPropertyName?: string, sortingIsDescending?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Registers/marked-equipments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (pageNumber !== undefined) {
                localVarQueryParameter['PageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (search !== undefined) {
                localVarQueryParameter['Search'] = search;
            }

            if (municipalityId !== undefined) {
                localVarQueryParameter['MunicipalityId'] = municipalityId;
            }

            if (companyId !== undefined) {
                localVarQueryParameter['CompanyId'] = companyId;
            }

            if (typeOfEquipmentId !== undefined) {
                localVarQueryParameter['TypeOfEquipmentId'] = typeOfEquipmentId;
            }

            if (skipAccessCheck !== undefined) {
                localVarQueryParameter['SkipAccessCheck'] = skipAccessCheck;
            }

            if (sortingPropertyName !== undefined) {
                localVarQueryParameter['Sorting.PropertyName'] = sortingPropertyName;
            }

            if (sortingIsDescending !== undefined) {
                localVarQueryParameter['Sorting.IsDescending'] = sortingIsDescending;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [search] 
         * @param {string} [municipalityId] 
         * @param {boolean} [skipAccessCheck] 
         * @param {string} [sortingPropertyName] 
         * @param {boolean} [sortingIsDescending] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRegistersOwnersOperatorsGet: async (pageNumber?: number, pageSize?: number, search?: string, municipalityId?: string, skipAccessCheck?: boolean, sortingPropertyName?: string, sortingIsDescending?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Registers/owners-operators`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (pageNumber !== undefined) {
                localVarQueryParameter['PageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (search !== undefined) {
                localVarQueryParameter['Search'] = search;
            }

            if (municipalityId !== undefined) {
                localVarQueryParameter['MunicipalityId'] = municipalityId;
            }

            if (skipAccessCheck !== undefined) {
                localVarQueryParameter['SkipAccessCheck'] = skipAccessCheck;
            }

            if (sortingPropertyName !== undefined) {
                localVarQueryParameter['Sorting.PropertyName'] = sortingPropertyName;
            }

            if (sortingIsDescending !== undefined) {
                localVarQueryParameter['Sorting.IsDescending'] = sortingIsDescending;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RegistersApi - functional programming interface
 * @export
 */
export const RegistersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RegistersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [search] 
         * @param {string} [municipalityId] 
         * @param {string} [entityId] 
         * @param {boolean} [skipAccessCheck] 
         * @param {string} [sortingPropertyName] 
         * @param {boolean} [sortingIsDescending] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiRegistersCompaniesEntrepreneursGet(pageNumber?: number, pageSize?: number, search?: string, municipalityId?: string, entityId?: string, skipAccessCheck?: boolean, sortingPropertyName?: string, sortingIsDescending?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyEntrepreneurDtoOrdinalPaginatedList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiRegistersCompaniesEntrepreneursGet(pageNumber, pageSize, search, municipalityId, entityId, skipAccessCheck, sortingPropertyName, sortingIsDescending, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },


        /**
      
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        async apiYearGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiYearGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },

        /**
   
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
        async apiYearImportExportGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiYearImportExportGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },

        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiRegistersIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RegisterDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiRegistersIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [search] 
         * @param {string} [municipalityId] 
         * @param {OrganizationTypeEnum} [type] 
         * @param {boolean} [skipAccessCheck] 
         * @param {string} [sortingPropertyName] 
         * @param {boolean} [sortingIsDescending] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiRegistersImportersExportersGet(pageNumber?: number, pageSize?: number, search?: string, municipalityId?: string, type?: OrganizationTypeEnum, skipAccessCheck?: boolean, sortingPropertyName?: string, sortingIsDescending?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ImporterExporterCompanyDtoOrdinalPaginatedList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiRegistersImportersExportersGet(pageNumber, pageSize, search, municipalityId, type, skipAccessCheck, sortingPropertyName, sortingIsDescending, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [search] 
         * @param {string} [municipalityId] 
         * @param {string} [businessActivityId] 
         * @param {OrganizationTypeEnum} [type] 
         * @param {boolean} [skipAccessCheck] 
         * @param {string} [sortingPropertyName] 
         * @param {boolean} [sortingIsDescending] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiRegistersKghServiceCompaniesGet(pageNumber?: number, pageSize?: number, search?: string, municipalityId?: string, businessActivityId?: string, type?: OrganizationTypeEnum, skipAccessCheck?: boolean, sortingPropertyName?: string, sortingIsDescending?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KGHServiceCompanyDtoOrdinalPaginatedList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiRegistersKghServiceCompaniesGet(pageNumber, pageSize, search, municipalityId, businessActivityId, type, skipAccessCheck, sortingPropertyName, sortingIsDescending, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [search] 
         * @param {string} [municipalityId] 
         * @param {string} [companyId] 
         * @param {string} [typeOfEquipmentId] 
         * @param {boolean} [skipAccessCheck] 
         * @param {string} [sortingPropertyName] 
         * @param {boolean} [sortingIsDescending] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiRegistersMarkedEquipmentsGet(pageNumber?: number, pageSize?: number, search?: string, municipalityId?: string, companyId?: string, typeOfEquipmentId?: string, skipAccessCheck?: boolean, sortingPropertyName?: string, sortingIsDescending?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MarkedEquipmentDtoOrdinalPaginatedList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiRegistersMarkedEquipmentsGet(pageNumber, pageSize, search, municipalityId, companyId, typeOfEquipmentId, skipAccessCheck, sortingPropertyName, sortingIsDescending, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [search] 
         * @param {string} [municipalityId] 
         * @param {boolean} [skipAccessCheck] 
         * @param {string} [sortingPropertyName] 
         * @param {boolean} [sortingIsDescending] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiRegistersOwnersOperatorsGet(pageNumber?: number, pageSize?: number, search?: string, municipalityId?: string, skipAccessCheck?: boolean, sortingPropertyName?: string, sortingIsDescending?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OwnerOperatorOfEquipmentDtoOrdinalPaginatedList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiRegistersOwnersOperatorsGet(pageNumber, pageSize, search, municipalityId, skipAccessCheck, sortingPropertyName, sortingIsDescending, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RegistersApi - factory interface
 * @export
 */
export const RegistersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RegistersApiFp(configuration)
    return {
        /**
         * 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [search] 
         * @param {string} [municipalityId] 
         * @param {string} [entityId] 
         * @param {boolean} [skipAccessCheck] 
         * @param {string} [sortingPropertyName] 
         * @param {boolean} [sortingIsDescending] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRegistersCompaniesEntrepreneursGet(pageNumber?: number, pageSize?: number, search?: string, municipalityId?: string, entityId?: string, skipAccessCheck?: boolean, sortingPropertyName?: string, sortingIsDescending?: boolean, options?: any): AxiosPromise<CompanyEntrepreneurDtoOrdinalPaginatedList> {
            return localVarFp.apiRegistersCompaniesEntrepreneursGet(pageNumber, pageSize, search, municipalityId, entityId, skipAccessCheck, sortingPropertyName, sortingIsDescending, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRegistersIdGet(id: string, options?: any): AxiosPromise<RegisterDetailsDto> {
            return localVarFp.apiRegistersIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [search] 
         * @param {string} [municipalityId] 
         * @param {OrganizationTypeEnum} [type] 
         * @param {boolean} [skipAccessCheck] 
         * @param {string} [sortingPropertyName] 
         * @param {boolean} [sortingIsDescending] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRegistersImportersExportersGet(pageNumber?: number, pageSize?: number, search?: string, municipalityId?: string, type?: OrganizationTypeEnum, skipAccessCheck?: boolean, sortingPropertyName?: string, sortingIsDescending?: boolean, options?: any): AxiosPromise<ImporterExporterCompanyDtoOrdinalPaginatedList> {
            return localVarFp.apiRegistersImportersExportersGet(pageNumber, pageSize, search, municipalityId, type, skipAccessCheck, sortingPropertyName, sortingIsDescending, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [search] 
         * @param {string} [municipalityId] 
         * @param {string} [businessActivityId] 
         * @param {OrganizationTypeEnum} [type] 
         * @param {boolean} [skipAccessCheck] 
         * @param {string} [sortingPropertyName] 
         * @param {boolean} [sortingIsDescending] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRegistersKghServiceCompaniesGet(pageNumber?: number, pageSize?: number, search?: string, municipalityId?: string, businessActivityId?: string, type?: OrganizationTypeEnum, skipAccessCheck?: boolean, sortingPropertyName?: string, sortingIsDescending?: boolean, options?: any): AxiosPromise<KGHServiceCompanyDtoOrdinalPaginatedList> {
            return localVarFp.apiRegistersKghServiceCompaniesGet(pageNumber, pageSize, search, municipalityId, businessActivityId, type, skipAccessCheck, sortingPropertyName, sortingIsDescending, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [search] 
         * @param {string} [municipalityId] 
         * @param {string} [companyId] 
         * @param {string} [typeOfEquipmentId] 
         * @param {boolean} [skipAccessCheck] 
         * @param {string} [sortingPropertyName] 
         * @param {boolean} [sortingIsDescending] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRegistersMarkedEquipmentsGet(pageNumber?: number, pageSize?: number, search?: string, municipalityId?: string, companyId?: string, typeOfEquipmentId?: string, skipAccessCheck?: boolean, sortingPropertyName?: string, sortingIsDescending?: boolean, options?: any): AxiosPromise<MarkedEquipmentDtoOrdinalPaginatedList> {
            return localVarFp.apiRegistersMarkedEquipmentsGet(pageNumber, pageSize, search, municipalityId, companyId, typeOfEquipmentId, skipAccessCheck, sortingPropertyName, sortingIsDescending, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [search] 
         * @param {string} [municipalityId] 
         * @param {boolean} [skipAccessCheck] 
         * @param {string} [sortingPropertyName] 
         * @param {boolean} [sortingIsDescending] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRegistersOwnersOperatorsGet(pageNumber?: number, pageSize?: number, search?: string, municipalityId?: string, skipAccessCheck?: boolean, sortingPropertyName?: string, sortingIsDescending?: boolean, options?: any): AxiosPromise<OwnerOperatorOfEquipmentDtoOrdinalPaginatedList> {
            return localVarFp.apiRegistersOwnersOperatorsGet(pageNumber, pageSize, search, municipalityId, skipAccessCheck, sortingPropertyName, sortingIsDescending, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiRegistersCompaniesEntrepreneursGet operation in RegistersApi.
 * @export
 * @interface RegistersApiApiRegistersCompaniesEntrepreneursGetRequest
 */
export interface RegistersApiApiRegistersCompaniesEntrepreneursGetRequest {
    /**
     * 
     * @type {number}
     * @memberof RegistersApiApiRegistersCompaniesEntrepreneursGet
     */
    readonly pageNumber?: number

    /**
     * 
     * @type {number}
     * @memberof RegistersApiApiRegistersCompaniesEntrepreneursGet
     */
    readonly pageSize?: number

    /**
     * 
     * @type {string}
     * @memberof RegistersApiApiRegistersCompaniesEntrepreneursGet
     */
    readonly search?: string

    /**
     * 
     * @type {string}
     * @memberof RegistersApiApiRegistersCompaniesEntrepreneursGet
     */
    readonly municipalityId?: string

    /**
     * 
     * @type {string}
     * @memberof RegistersApiApiRegistersCompaniesEntrepreneursGet
     */
    readonly entityId?: string

    /**
     * 
     * @type {boolean}
     * @memberof RegistersApiApiRegistersCompaniesEntrepreneursGet
     */
    readonly skipAccessCheck?: boolean

    /**
     * 
     * @type {string}
     * @memberof RegistersApiApiRegistersCompaniesEntrepreneursGet
     */
    readonly sortingPropertyName?: string

    /**
     * 
     * @type {boolean}
     * @memberof RegistersApiApiRegistersCompaniesEntrepreneursGet
     */
    readonly sortingIsDescending?: boolean
}

/**
 * Request parameters for apiRegistersIdGet operation in RegistersApi.
 * @export
 * @interface RegistersApiApiRegistersIdGetRequest
 */
export interface RegistersApiApiRegistersIdGetRequest {
    /**
     * 
     * @type {string}
     * @memberof RegistersApiApiRegistersIdGet
     */
    readonly id: string
}

/**
 * Request parameters for apiRegistersImportersExportersGet operation in RegistersApi.
 * @export
 * @interface RegistersApiApiRegistersImportersExportersGetRequest
 */
export interface RegistersApiApiRegistersImportersExportersGetRequest {
    /**
     * 
     * @type {number}
     * @memberof RegistersApiApiRegistersImportersExportersGet
     */
    readonly pageNumber?: number

    /**
     * 
     * @type {number}
     * @memberof RegistersApiApiRegistersImportersExportersGet
     */
    readonly pageSize?: number

    /**
     * 
     * @type {string}
     * @memberof RegistersApiApiRegistersImportersExportersGet
     */
    readonly search?: string

    /**
     * 
     * @type {string}
     * @memberof RegistersApiApiRegistersImportersExportersGet
     */
    readonly municipalityId?: string

    /**
     * 
     * @type {OrganizationTypeEnum}
     * @memberof RegistersApiApiRegistersImportersExportersGet
     */
    readonly type?: OrganizationTypeEnum

    /**
     * 
     * @type {boolean}
     * @memberof RegistersApiApiRegistersImportersExportersGet
     */
    readonly skipAccessCheck?: boolean

    /**
     * 
     * @type {string}
     * @memberof RegistersApiApiRegistersImportersExportersGet
     */
    readonly sortingPropertyName?: string

    /**
     * 
     * @type {boolean}
     * @memberof RegistersApiApiRegistersImportersExportersGet
     */
    readonly sortingIsDescending?: boolean
}

/**
 * Request parameters for apiRegistersKghServiceCompaniesGet operation in RegistersApi.
 * @export
 * @interface RegistersApiApiRegistersKghServiceCompaniesGetRequest
 */
export interface RegistersApiApiRegistersKghServiceCompaniesGetRequest {
    /**
     * 
     * @type {number}
     * @memberof RegistersApiApiRegistersKghServiceCompaniesGet
     */
    readonly pageNumber?: number

    /**
     * 
     * @type {number}
     * @memberof RegistersApiApiRegistersKghServiceCompaniesGet
     */
    readonly pageSize?: number

    /**
     * 
     * @type {string}
     * @memberof RegistersApiApiRegistersKghServiceCompaniesGet
     */
    readonly search?: string

    /**
     * 
     * @type {string}
     * @memberof RegistersApiApiRegistersKghServiceCompaniesGet
     */
    readonly municipalityId?: string

    /**
     * 
     * @type {string}
     * @memberof RegistersApiApiRegistersKghServiceCompaniesGet
     */
    readonly businessActivityId?: string

    /**
     * 
     * @type {OrganizationTypeEnum}
     * @memberof RegistersApiApiRegistersKghServiceCompaniesGet
     */
    readonly type?: OrganizationTypeEnum

    /**
     * 
     * @type {boolean}
     * @memberof RegistersApiApiRegistersKghServiceCompaniesGet
     */
    readonly skipAccessCheck?: boolean

    /**
     * 
     * @type {string}
     * @memberof RegistersApiApiRegistersKghServiceCompaniesGet
     */
    readonly sortingPropertyName?: string

    /**
     * 
     * @type {boolean}
     * @memberof RegistersApiApiRegistersKghServiceCompaniesGet
     */
    readonly sortingIsDescending?: boolean
}

/**
 * Request parameters for apiRegistersMarkedEquipmentsGet operation in RegistersApi.
 * @export
 * @interface RegistersApiApiRegistersMarkedEquipmentsGetRequest
 */
export interface RegistersApiApiRegistersMarkedEquipmentsGetRequest {
    /**
     * 
     * @type {number}
     * @memberof RegistersApiApiRegistersMarkedEquipmentsGet
     */
    readonly pageNumber?: number

    /**
     * 
     * @type {number}
     * @memberof RegistersApiApiRegistersMarkedEquipmentsGet
     */
    readonly pageSize?: number

    /**
     * 
     * @type {string}
     * @memberof RegistersApiApiRegistersMarkedEquipmentsGet
     */
    readonly search?: string

    /**
     * 
     * @type {string}
     * @memberof RegistersApiApiRegistersMarkedEquipmentsGet
     */
    readonly municipalityId?: string

    /**
     * 
     * @type {string}
     * @memberof RegistersApiApiRegistersMarkedEquipmentsGet
     */
    readonly companyId?: string

    /**
     * 
     * @type {string}
     * @memberof RegistersApiApiRegistersMarkedEquipmentsGet
     */
    readonly typeOfEquipmentId?: string

    /**
     * 
     * @type {boolean}
     * @memberof RegistersApiApiRegistersMarkedEquipmentsGet
     */
    readonly skipAccessCheck?: boolean

    /**
     * 
     * @type {string}
     * @memberof RegistersApiApiRegistersMarkedEquipmentsGet
     */
    readonly sortingPropertyName?: string

    /**
     * 
     * @type {boolean}
     * @memberof RegistersApiApiRegistersMarkedEquipmentsGet
     */
    readonly sortingIsDescending?: boolean
}

/**
 * Request parameters for apiRegistersOwnersOperatorsGet operation in RegistersApi.
 * @export
 * @interface RegistersApiApiRegistersOwnersOperatorsGetRequest
 */
export interface RegistersApiApiRegistersOwnersOperatorsGetRequest {
    /**
     * 
     * @type {number}
     * @memberof RegistersApiApiRegistersOwnersOperatorsGet
     */
    readonly pageNumber?: number

    /**
     * 
     * @type {number}
     * @memberof RegistersApiApiRegistersOwnersOperatorsGet
     */
    readonly pageSize?: number

    /**
     * 
     * @type {string}
     * @memberof RegistersApiApiRegistersOwnersOperatorsGet
     */
    readonly search?: string

    /**
     * 
     * @type {string}
     * @memberof RegistersApiApiRegistersOwnersOperatorsGet
     */
    readonly municipalityId?: string

    /**
     * 
     * @type {boolean}
     * @memberof RegistersApiApiRegistersOwnersOperatorsGet
     */
    readonly skipAccessCheck?: boolean

    /**
     * 
     * @type {string}
     * @memberof RegistersApiApiRegistersOwnersOperatorsGet
     */
    readonly sortingPropertyName?: string

    /**
     * 
     * @type {boolean}
     * @memberof RegistersApiApiRegistersOwnersOperatorsGet
     */
    readonly sortingIsDescending?: boolean
}

/**
 * RegistersApi - object-oriented interface
 * @export
 * @class RegistersApi
 * @extends {BaseAPI}
 */
export class RegistersApi extends BaseAPI {
    /**
     * 
     * @param {RegistersApiApiRegistersCompaniesEntrepreneursGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegistersApi
     */
    public apiRegistersCompaniesEntrepreneursGet(requestParameters: RegistersApiApiRegistersCompaniesEntrepreneursGetRequest = {}, options?: AxiosRequestConfig) {
        return RegistersApiFp(this.configuration).apiRegistersCompaniesEntrepreneursGet(requestParameters.pageNumber, requestParameters.pageSize, requestParameters.search, requestParameters.municipalityId, requestParameters.entityId, requestParameters.skipAccessCheck, requestParameters.sortingPropertyName, requestParameters.sortingIsDescending, options).then((request) => request(this.axios, this.basePath));
    }

    /**
  * 
  * @param {any} requestParameters Request parameters.
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  * @memberof RegistersApi
  */
    public apiYearGet(options?: AxiosRequestConfig) {
        return RegistersApiFp(this.configuration).apiYearGet(options).then((request) => request(this.axios, this.basePath));
    }


    /**
 * 
 * @param {any} requestParameters Request parameters.
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
 * @memberof RegistersApi
 */
    public apiYearImportExportGet(options?: AxiosRequestConfig) {
        return RegistersApiFp(this.configuration).apiYearImportExportGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RegistersApiApiRegistersIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegistersApi
     */
    public apiRegistersIdGet(requestParameters: RegistersApiApiRegistersIdGetRequest, options?: AxiosRequestConfig) {
        return RegistersApiFp(this.configuration).apiRegistersIdGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RegistersApiApiRegistersImportersExportersGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegistersApi
     */
    public apiRegistersImportersExportersGet(requestParameters: RegistersApiApiRegistersImportersExportersGetRequest = {}, options?: AxiosRequestConfig) {
        return RegistersApiFp(this.configuration).apiRegistersImportersExportersGet(requestParameters.pageNumber, requestParameters.pageSize, requestParameters.search, requestParameters.municipalityId, requestParameters.type, requestParameters.skipAccessCheck, requestParameters.sortingPropertyName, requestParameters.sortingIsDescending, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RegistersApiApiRegistersKghServiceCompaniesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegistersApi
     */
    public apiRegistersKghServiceCompaniesGet(requestParameters: RegistersApiApiRegistersKghServiceCompaniesGetRequest = {}, options?: AxiosRequestConfig) {
        return RegistersApiFp(this.configuration).apiRegistersKghServiceCompaniesGet(requestParameters.pageNumber, requestParameters.pageSize, requestParameters.search, requestParameters.municipalityId, requestParameters.businessActivityId, requestParameters.type, requestParameters.skipAccessCheck, requestParameters.sortingPropertyName, requestParameters.sortingIsDescending, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RegistersApiApiRegistersMarkedEquipmentsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegistersApi
     */
    public apiRegistersMarkedEquipmentsGet(requestParameters: RegistersApiApiRegistersMarkedEquipmentsGetRequest = {}, options?: AxiosRequestConfig) {
        return RegistersApiFp(this.configuration).apiRegistersMarkedEquipmentsGet(requestParameters.pageNumber, requestParameters.pageSize, requestParameters.search, requestParameters.municipalityId, requestParameters.companyId, requestParameters.typeOfEquipmentId, requestParameters.skipAccessCheck, requestParameters.sortingPropertyName, requestParameters.sortingIsDescending, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RegistersApiApiRegistersOwnersOperatorsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegistersApi
     */
    public apiRegistersOwnersOperatorsGet(requestParameters: RegistersApiApiRegistersOwnersOperatorsGetRequest = {}, options?: AxiosRequestConfig) {
        return RegistersApiFp(this.configuration).apiRegistersOwnersOperatorsGet(requestParameters.pageNumber, requestParameters.pageSize, requestParameters.search, requestParameters.municipalityId, requestParameters.skipAccessCheck, requestParameters.sortingPropertyName, requestParameters.sortingIsDescending, options).then((request) => request(this.axios, this.basePath));
    }
}
