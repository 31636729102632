/* tslint:disable */
/* eslint-disable */
/**
 * KGH PROJECT API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CreateRefrigerantTypeCommand } from '../models';
// @ts-ignore
import { RefrigerantTypeDtoOrdinalPaginatedList } from '../models';
// @ts-ignore
import { UpdateRefrigerantTypeCommand } from '../models';
/**
 * RefrigerantTypesApi - axios parameter creator
 * @export
 */
export const RefrigerantTypesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [search] 
         * @param {string} [sortingPropertyName] 
         * @param {boolean} [sortingIsDescending] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRefrigerantTypesGet: async (pageNumber?: number, pageSize?: number, search?: string, sortingPropertyName?: string, sortingIsDescending?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/RefrigerantTypes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (pageNumber !== undefined) {
                localVarQueryParameter['PageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (search !== undefined) {
                localVarQueryParameter['Search'] = search;
            }

            if (sortingPropertyName !== undefined) {
                localVarQueryParameter['Sorting.PropertyName'] = sortingPropertyName;
            }

            if (sortingIsDescending !== undefined) {
                localVarQueryParameter['Sorting.IsDescending'] = sortingIsDescending;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRefrigerantTypesIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiRefrigerantTypesIdDelete', 'id', id)
            const localVarPath = `/api/RefrigerantTypes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRefrigerantTypesIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiRefrigerantTypesIdGet', 'id', id)
            const localVarPath = `/api/RefrigerantTypes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateRefrigerantTypeCommand} [updateRefrigerantTypeCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRefrigerantTypesIdPut: async (id: string, updateRefrigerantTypeCommand?: UpdateRefrigerantTypeCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiRefrigerantTypesIdPut', 'id', id)
            const localVarPath = `/api/RefrigerantTypes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateRefrigerantTypeCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateRefrigerantTypeCommand} [createRefrigerantTypeCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRefrigerantTypesPost: async (createRefrigerantTypeCommand?: CreateRefrigerantTypeCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/RefrigerantTypes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createRefrigerantTypeCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RefrigerantTypesApi - functional programming interface
 * @export
 */
export const RefrigerantTypesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RefrigerantTypesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [search] 
         * @param {string} [sortingPropertyName] 
         * @param {boolean} [sortingIsDescending] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiRefrigerantTypesGet(pageNumber?: number, pageSize?: number, search?: string, sortingPropertyName?: string, sortingIsDescending?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RefrigerantTypeDtoOrdinalPaginatedList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiRefrigerantTypesGet(pageNumber, pageSize, search, sortingPropertyName, sortingIsDescending, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiRefrigerantTypesIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiRefrigerantTypesIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiRefrigerantTypesIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiRefrigerantTypesIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateRefrigerantTypeCommand} [updateRefrigerantTypeCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiRefrigerantTypesIdPut(id: string, updateRefrigerantTypeCommand?: UpdateRefrigerantTypeCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiRefrigerantTypesIdPut(id, updateRefrigerantTypeCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateRefrigerantTypeCommand} [createRefrigerantTypeCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiRefrigerantTypesPost(createRefrigerantTypeCommand?: CreateRefrigerantTypeCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiRefrigerantTypesPost(createRefrigerantTypeCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RefrigerantTypesApi - factory interface
 * @export
 */
export const RefrigerantTypesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RefrigerantTypesApiFp(configuration)
    return {
        /**
         * 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [search] 
         * @param {string} [sortingPropertyName] 
         * @param {boolean} [sortingIsDescending] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRefrigerantTypesGet(pageNumber?: number, pageSize?: number, search?: string, sortingPropertyName?: string, sortingIsDescending?: boolean, options?: any): AxiosPromise<RefrigerantTypeDtoOrdinalPaginatedList> {
            return localVarFp.apiRefrigerantTypesGet(pageNumber, pageSize, search, sortingPropertyName, sortingIsDescending, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRefrigerantTypesIdDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiRefrigerantTypesIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRefrigerantTypesIdGet(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiRefrigerantTypesIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateRefrigerantTypeCommand} [updateRefrigerantTypeCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRefrigerantTypesIdPut(id: string, updateRefrigerantTypeCommand?: UpdateRefrigerantTypeCommand, options?: any): AxiosPromise<void> {
            return localVarFp.apiRefrigerantTypesIdPut(id, updateRefrigerantTypeCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateRefrigerantTypeCommand} [createRefrigerantTypeCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRefrigerantTypesPost(createRefrigerantTypeCommand?: CreateRefrigerantTypeCommand, options?: any): AxiosPromise<void> {
            return localVarFp.apiRefrigerantTypesPost(createRefrigerantTypeCommand, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiRefrigerantTypesGet operation in RefrigerantTypesApi.
 * @export
 * @interface RefrigerantTypesApiApiRefrigerantTypesGetRequest
 */
export interface RefrigerantTypesApiApiRefrigerantTypesGetRequest {
    /**
     * 
     * @type {number}
     * @memberof RefrigerantTypesApiApiRefrigerantTypesGet
     */
    readonly pageNumber?: number

    /**
     * 
     * @type {number}
     * @memberof RefrigerantTypesApiApiRefrigerantTypesGet
     */
    readonly pageSize?: number

    /**
     * 
     * @type {string}
     * @memberof RefrigerantTypesApiApiRefrigerantTypesGet
     */
    readonly search?: string

    /**
     * 
     * @type {string}
     * @memberof RefrigerantTypesApiApiRefrigerantTypesGet
     */
    readonly sortingPropertyName?: string

    /**
     * 
     * @type {boolean}
     * @memberof RefrigerantTypesApiApiRefrigerantTypesGet
     */
    readonly sortingIsDescending?: boolean
}

/**
 * Request parameters for apiRefrigerantTypesIdDelete operation in RefrigerantTypesApi.
 * @export
 * @interface RefrigerantTypesApiApiRefrigerantTypesIdDeleteRequest
 */
export interface RefrigerantTypesApiApiRefrigerantTypesIdDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof RefrigerantTypesApiApiRefrigerantTypesIdDelete
     */
    readonly id: string
}

/**
 * Request parameters for apiRefrigerantTypesIdGet operation in RefrigerantTypesApi.
 * @export
 * @interface RefrigerantTypesApiApiRefrigerantTypesIdGetRequest
 */
export interface RefrigerantTypesApiApiRefrigerantTypesIdGetRequest {
    /**
     * 
     * @type {string}
     * @memberof RefrigerantTypesApiApiRefrigerantTypesIdGet
     */
    readonly id: string
}

/**
 * Request parameters for apiRefrigerantTypesIdPut operation in RefrigerantTypesApi.
 * @export
 * @interface RefrigerantTypesApiApiRefrigerantTypesIdPutRequest
 */
export interface RefrigerantTypesApiApiRefrigerantTypesIdPutRequest {
    /**
     * 
     * @type {string}
     * @memberof RefrigerantTypesApiApiRefrigerantTypesIdPut
     */
    readonly id: string

    /**
     * 
     * @type {UpdateRefrigerantTypeCommand}
     * @memberof RefrigerantTypesApiApiRefrigerantTypesIdPut
     */
    readonly updateRefrigerantTypeCommand?: UpdateRefrigerantTypeCommand
}

/**
 * Request parameters for apiRefrigerantTypesPost operation in RefrigerantTypesApi.
 * @export
 * @interface RefrigerantTypesApiApiRefrigerantTypesPostRequest
 */
export interface RefrigerantTypesApiApiRefrigerantTypesPostRequest {
    /**
     * 
     * @type {CreateRefrigerantTypeCommand}
     * @memberof RefrigerantTypesApiApiRefrigerantTypesPost
     */
    readonly createRefrigerantTypeCommand?: CreateRefrigerantTypeCommand
}

/**
 * RefrigerantTypesApi - object-oriented interface
 * @export
 * @class RefrigerantTypesApi
 * @extends {BaseAPI}
 */
export class RefrigerantTypesApi extends BaseAPI {
    /**
     * 
     * @param {RefrigerantTypesApiApiRefrigerantTypesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RefrigerantTypesApi
     */
    public apiRefrigerantTypesGet(requestParameters: RefrigerantTypesApiApiRefrigerantTypesGetRequest = {}, options?: AxiosRequestConfig) {
        return RefrigerantTypesApiFp(this.configuration).apiRefrigerantTypesGet(requestParameters.pageNumber, requestParameters.pageSize, requestParameters.search, requestParameters.sortingPropertyName, requestParameters.sortingIsDescending, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RefrigerantTypesApiApiRefrigerantTypesIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RefrigerantTypesApi
     */
    public apiRefrigerantTypesIdDelete(requestParameters: RefrigerantTypesApiApiRefrigerantTypesIdDeleteRequest, options?: AxiosRequestConfig) {
        return RefrigerantTypesApiFp(this.configuration).apiRefrigerantTypesIdDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RefrigerantTypesApiApiRefrigerantTypesIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RefrigerantTypesApi
     */
    public apiRefrigerantTypesIdGet(requestParameters: RefrigerantTypesApiApiRefrigerantTypesIdGetRequest, options?: AxiosRequestConfig) {
        return RefrigerantTypesApiFp(this.configuration).apiRefrigerantTypesIdGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RefrigerantTypesApiApiRefrigerantTypesIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RefrigerantTypesApi
     */
    public apiRefrigerantTypesIdPut(requestParameters: RefrigerantTypesApiApiRefrigerantTypesIdPutRequest, options?: AxiosRequestConfig) {
        return RefrigerantTypesApiFp(this.configuration).apiRefrigerantTypesIdPut(requestParameters.id, requestParameters.updateRefrigerantTypeCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RefrigerantTypesApiApiRefrigerantTypesPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RefrigerantTypesApi
     */
    public apiRefrigerantTypesPost(requestParameters: RefrigerantTypesApiApiRefrigerantTypesPostRequest = {}, options?: AxiosRequestConfig) {
        return RefrigerantTypesApiFp(this.configuration).apiRefrigerantTypesPost(requestParameters.createRefrigerantTypeCommand, options).then((request) => request(this.axios, this.basePath));
    }
}
