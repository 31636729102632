/* tslint:disable */
/* eslint-disable */
/**
 * KGH PROJECT API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AddUserGroupCommand } from '../models';
// @ts-ignore
import { EditUserGroupCommand } from '../models';
// @ts-ignore
import { GroupOverviewDto } from '../models';
// @ts-ignore
import { UserGroupDetailsDto } from '../models';
/**
 * UserGroupsApi - axios parameter creator
 * @export
 */
export const UserGroupsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userGroupsAvailableNameGet: async (name: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('userGroupsAvailableNameGet', 'name', name)
            const localVarPath = `/user-groups/available/{name}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *  @param {string} search 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userGroupsGet: async (search?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/user-groups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)
            if (search !== undefined) {
                localVarQueryParameter['Search'] = search;
            }
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userGroupsNameDelete: async (name: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('userGroupsNameDelete', 'name', name)
            const localVarPath = `/user-groups/{name}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userGroupsNameGet: async (name: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('userGroupsNameGet', 'name', name)
            const localVarPath = `/user-groups/{name}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AddUserGroupCommand} [addUserGroupCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userGroupsPost: async (addUserGroupCommand?: AddUserGroupCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/user-groups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addUserGroupCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {EditUserGroupCommand} [editUserGroupCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userGroupsPut: async (editUserGroupCommand?: EditUserGroupCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/user-groups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(editUserGroupCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserGroupsApi - functional programming interface
 * @export
 */
export const UserGroupsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserGroupsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userGroupsAvailableNameGet(name: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userGroupsAvailableNameGet(name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
        * @param {string} search 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        async userGroupsGet(search?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GroupOverviewDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userGroupsGet(search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userGroupsNameDelete(name: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userGroupsNameDelete(name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userGroupsNameGet(name: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserGroupDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userGroupsNameGet(name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AddUserGroupCommand} [addUserGroupCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userGroupsPost(addUserGroupCommand?: AddUserGroupCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userGroupsPost(addUserGroupCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {EditUserGroupCommand} [editUserGroupCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userGroupsPut(editUserGroupCommand?: EditUserGroupCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userGroupsPut(editUserGroupCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserGroupsApi - factory interface
 * @export
 */
export const UserGroupsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserGroupsApiFp(configuration)
    return {
        /**
         * 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userGroupsAvailableNameGet(name: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.userGroupsAvailableNameGet(name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userGroupsGet(options?: any): AxiosPromise<Array<GroupOverviewDto>> {
            return localVarFp.userGroupsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userGroupsNameDelete(name: string, options?: any): AxiosPromise<void> {
            return localVarFp.userGroupsNameDelete(name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userGroupsNameGet(name: string, options?: any): AxiosPromise<UserGroupDetailsDto> {
            return localVarFp.userGroupsNameGet(name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AddUserGroupCommand} [addUserGroupCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userGroupsPost(addUserGroupCommand?: AddUserGroupCommand, options?: any): AxiosPromise<void> {
            return localVarFp.userGroupsPost(addUserGroupCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EditUserGroupCommand} [editUserGroupCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userGroupsPut(editUserGroupCommand?: EditUserGroupCommand, options?: any): AxiosPromise<void> {
            return localVarFp.userGroupsPut(editUserGroupCommand, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for userGroupsAvailableNameGet operation in UserGroupsApi.
 * @export
 * @interface UserGroupsApiUserGroupsAvailableNameGetRequest
 */
export interface UserGroupsApiUserGroupsAvailableNameGetRequest {
    /**
     * 
     * @type {string}
     * @memberof UserGroupsApiUserGroupsAvailableNameGet
     */
    readonly name: string
}

/**
 * Request parameters for userGroupsNameDelete operation in UserGroupsApi.
 * @export
 * @interface UserGroupsApiUserGroupsNameDeleteRequest
 */
export interface UserGroupsApiUserGroupsNameDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof UserGroupsApiUserGroupsNameDelete
     */
    readonly name: string
}

/**
 * Request parameters for userGroupsNameGet operation in UserGroupsApi.
 * @export
 * @interface UserGroupsApiUserGroupsNameGetRequest
 */
export interface UserGroupsApiUserGroupsNameGetRequest {
    /**
     * 
     * @type {string}
     * @memberof UserGroupsApiUserGroupsNameGet
     */
    readonly name: string
}

/**
 * Request parameters for userGroupsPost operation in UserGroupsApi.
 * @export
 * @interface UserGroupsApiUserGroupsPostRequest
 */
export interface UserGroupsApiUserGroupsPostRequest {
    /**
     * 
     * @type {AddUserGroupCommand}
     * @memberof UserGroupsApiUserGroupsPost
     */
    readonly addUserGroupCommand?: AddUserGroupCommand
}

/**
 * Request parameters for UserGroupsNameSearch operation in UserGroupsApi.
 * @export
 * @interface UserGroupsApiUserGroupsNameSearchRequest
 */
export interface UserGroupsApiUserGroupsNameSearchRequest {
    /**
     * 
     * @type {string}
     * @memberof UserGroupsApiUserGroupsNameSearch
     */
    readonly search?: string
}

/**
 * Request parameters for userGroupsPut operation in UserGroupsApi.
 * @export
 * @interface UserGroupsApiUserGroupsPutRequest
 */
export interface UserGroupsApiUserGroupsPutRequest {
    /**
     * 
     * @type {EditUserGroupCommand}
     * @memberof UserGroupsApiUserGroupsPut
     */
    readonly editUserGroupCommand?: EditUserGroupCommand
}

/**
 * UserGroupsApi - object-oriented interface
 * @export
 * @class UserGroupsApi
 * @extends {BaseAPI}
 */
export class UserGroupsApi extends BaseAPI {
    /**
     * 
     * @param {UserGroupsApiUserGroupsAvailableNameGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserGroupsApi
     */
    public userGroupsAvailableNameGet(requestParameters: UserGroupsApiUserGroupsAvailableNameGetRequest, options?: AxiosRequestConfig) {
        return UserGroupsApiFp(this.configuration).userGroupsAvailableNameGet(requestParameters.name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
    * @param {UserGroupsApiUserGroupsNameSearchRequest} requestParameters
    * @param {*} [options] Override http request option.
    * @throws {RequiredError}
    * @memberof UserGroupsApi
    */
    public userGroupsGet(requestParameters: UserGroupsApiUserGroupsNameSearchRequest = {}, options?: AxiosRequestConfig) {
        return UserGroupsApiFp(this.configuration).userGroupsGet(requestParameters.search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UserGroupsApiUserGroupsNameDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserGroupsApi
     */
    public userGroupsNameDelete(requestParameters: UserGroupsApiUserGroupsNameDeleteRequest, options?: AxiosRequestConfig) {
        return UserGroupsApiFp(this.configuration).userGroupsNameDelete(requestParameters.name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UserGroupsApiUserGroupsNameGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserGroupsApi
     */
    public userGroupsNameGet(requestParameters: UserGroupsApiUserGroupsNameGetRequest, options?: AxiosRequestConfig) {
        return UserGroupsApiFp(this.configuration).userGroupsNameGet(requestParameters.name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UserGroupsApiUserGroupsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserGroupsApi
     */
    public userGroupsPost(requestParameters: UserGroupsApiUserGroupsPostRequest = {}, options?: AxiosRequestConfig) {
        return UserGroupsApiFp(this.configuration).userGroupsPost(requestParameters.addUserGroupCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UserGroupsApiUserGroupsPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserGroupsApi
     */
    public userGroupsPut(requestParameters: UserGroupsApiUserGroupsPutRequest = {}, options?: AxiosRequestConfig) {
        return UserGroupsApiFp(this.configuration).userGroupsPut(requestParameters.editUserGroupCommand, options).then((request) => request(this.axios, this.basePath));
    }
}
