/* tslint:disable */
/* eslint-disable */
/**
 * KGH PROJECT API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { EquipmentActivityDto } from '../models';
// @ts-ignore
import { EquipmentDto } from '../models';
// @ts-ignore
import { EquipmentDtoOrdinalPaginatedList } from '../models';
// @ts-ignore
import { TechnicianForActivityDto } from '../models';
/**
 * EquipmentsApi - axios parameter creator
 * @export
 */
export const EquipmentsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [dateOfChange] 
         * @param {string} [typeOfChangeId] 
         * @param {string} [newOperatorName] 
         * @param {string} [newCoolantId] 
         * @param {string} [technicianCertificateNumber] 
         * @param {string} [comments] 
         * @param {string} [equipmentId] 
         * @param {Array<File>} [files] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEquipmentsCreateActivityPost: async (dateOfChange?: string, typeOfChangeId?: string, newOperatorName?: string, newCoolantId?: string, technicianCertificateNumber?: string, comments?: string, equipmentId?: string, files?: Array<File>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Equipments/create-activity`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


            if (dateOfChange !== undefined) { 
                localVarFormParams.append('DateOfChange', dateOfChange as any);
            }
    
            if (typeOfChangeId !== undefined) { 
                localVarFormParams.append('TypeOfChangeId', typeOfChangeId as any);
            }
    
            if (newOperatorName !== undefined) { 
                localVarFormParams.append('NewOperatorName', newOperatorName as any);
            }
    
            if (newCoolantId !== undefined) { 
                localVarFormParams.append('NewCoolantId', newCoolantId as any);
            }
    
            if (technicianCertificateNumber !== undefined) { 
                localVarFormParams.append('TechnicianCertificateNumber', technicianCertificateNumber as any);
            }
    
            if (comments !== undefined) { 
                localVarFormParams.append('Comments', comments as any);
            }
    
            if (equipmentId !== undefined) { 
                localVarFormParams.append('EquipmentId', equipmentId as any);
            }
                if (files) {
                files.forEach((element) => {
                    localVarFormParams.append('Files', element as any);
                })
            }

    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [search] 
         * @param {boolean} [isArchived] 
         * @param {string} [sortingPropertyName] 
         * @param {boolean} [sortingIsDescending] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEquipmentsGet: async (pageNumber?: number, pageSize?: number, search?: string, isArchived?: boolean, sortingPropertyName?: string, sortingIsDescending?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Equipments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (pageNumber !== undefined) {
                localVarQueryParameter['PageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (search !== undefined) {
                localVarQueryParameter['Search'] = search;
            }

            if (isArchived !== undefined) {
                localVarQueryParameter['IsArchived'] = isArchived;
            }

            if (sortingPropertyName !== undefined) {
                localVarQueryParameter['Sorting.PropertyName'] = sortingPropertyName;
            }

            if (sortingIsDescending !== undefined) {
                localVarQueryParameter['Sorting.IsDescending'] = sortingIsDescending;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEquipmentsIdActivitiesGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiEquipmentsIdActivitiesGet', 'id', id)
            const localVarPath = `/api/Equipments/{id}/activities`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEquipmentsIdArchivePost: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiEquipmentsIdArchivePost', 'id', id)
            const localVarPath = `/api/Equipments/{id}/archive`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEquipmentsIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiEquipmentsIdDelete', 'id', id)
            const localVarPath = `/api/Equipments/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEquipmentsIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiEquipmentsIdGet', 'id', id)
            const localVarPath = `/api/Equipments/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} certificateNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEquipmentsIdTechnicianCertificateNumberCertificateNumberGet: async (id: string, certificateNumber: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiEquipmentsIdTechnicianCertificateNumberCertificateNumberGet', 'id', id)
            // verify required parameter 'certificateNumber' is not null or undefined
            assertParamExists('apiEquipmentsIdTechnicianCertificateNumberCertificateNumberGet', 'certificateNumber', certificateNumber)
            const localVarPath = `/api/Equipments/{id}/technician-certificate-number/{certificateNumber}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"certificateNumber"}}`, encodeURIComponent(String(certificateNumber)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [companyBranchId] 
         * @param {string} [typeOfEquipmentId] 
         * @param {string} [typeOfEquipmentOther] 
         * @param {string} [typeOfCoolingSystemId] 
         * @param {string} [typeOfCoolingSystemOther] 
         * @param {string} [manufacturer] 
         * @param {string} [type] 
         * @param {string} [model] 
         * @param {string} [serialNumber] 
         * @param {number} [yearOfProduction] 
         * @param {string} [dateOfPurchase] 
         * @param {string} [refrigerantTypeId] 
         * @param {number} [massOfRefrigerantKg] 
         * @param {string} [purposeOfEquipmentId] 
         * @param {number} [coolingTemperature] 
         * @param {number} [coolingEffectKw] 
         * @param {number} [compressorConnectionPowerKw] 
         * @param {number} [liquidCollectorVolume] 
         * @param {number} [massAddedLastYearInKg] 
         * @param {string} [commissioningDate] 
         * @param {string} [comments] 
         * @param {Array<File>} [files] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEquipmentsPost: async (companyBranchId?: string, typeOfEquipmentId?: string, typeOfEquipmentOther?: string, typeOfCoolingSystemId?: string, typeOfCoolingSystemOther?: string, manufacturer?: string, type?: string, model?: string, serialNumber?: string, yearOfProduction?: number, dateOfPurchase?: string, refrigerantTypeId?: string, massOfRefrigerantKg?: number, purposeOfEquipmentId?: string, coolingTemperature?: number, coolingEffectKw?: number, compressorConnectionPowerKw?: number, liquidCollectorVolume?: number, massAddedLastYearInKg?: number, commissioningDate?: string, comments?: string, files?: Array<File>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Equipments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


            if (companyBranchId !== undefined) { 
                localVarFormParams.append('CompanyBranchId', companyBranchId as any);
            }
    
            if (typeOfEquipmentId !== undefined) { 
                localVarFormParams.append('TypeOfEquipmentId', typeOfEquipmentId as any);
            }
    
            if (typeOfEquipmentOther !== undefined) { 
                localVarFormParams.append('TypeOfEquipmentOther', typeOfEquipmentOther as any);
            }
    
            if (typeOfCoolingSystemId !== undefined) { 
                localVarFormParams.append('TypeOfCoolingSystemId', typeOfCoolingSystemId as any);
            }
    
            if (typeOfCoolingSystemOther !== undefined) { 
                localVarFormParams.append('TypeOfCoolingSystemOther', typeOfCoolingSystemOther as any);
            }
    
            if (manufacturer !== undefined) { 
                localVarFormParams.append('Manufacturer', manufacturer as any);
            }
    
            if (type !== undefined) { 
                localVarFormParams.append('Type', type as any);
            }
    
            if (model !== undefined) { 
                localVarFormParams.append('Model', model as any);
            }
    
            if (serialNumber !== undefined) { 
                localVarFormParams.append('SerialNumber', serialNumber as any);
            }
    
            if (yearOfProduction !== undefined) { 
                localVarFormParams.append('YearOfProduction', yearOfProduction as any);
            }
    
            if (dateOfPurchase !== undefined) { 
                localVarFormParams.append('DateOfPurchase', dateOfPurchase as any);
            }
    
            if (refrigerantTypeId !== undefined) { 
                localVarFormParams.append('RefrigerantTypeId', refrigerantTypeId as any);
            }
    
            if (massOfRefrigerantKg !== undefined) { 
                localVarFormParams.append('MassOfRefrigerantKg', massOfRefrigerantKg as any);
            }
    
            if (purposeOfEquipmentId !== undefined) { 
                localVarFormParams.append('PurposeOfEquipmentId', purposeOfEquipmentId as any);
            }
    
            if (coolingTemperature !== undefined) { 
                localVarFormParams.append('CoolingTemperature', coolingTemperature as any);
            }
    
            if (coolingEffectKw !== undefined) { 
                localVarFormParams.append('CoolingEffectKw', coolingEffectKw as any);
            }
    
            if (compressorConnectionPowerKw !== undefined) { 
                localVarFormParams.append('CompressorConnectionPowerKw', compressorConnectionPowerKw as any);
            }
    
            if (liquidCollectorVolume !== undefined) { 
                localVarFormParams.append('LiquidCollectorVolume', liquidCollectorVolume as any);
            }
    
            if (massAddedLastYearInKg !== undefined) { 
                localVarFormParams.append('MassAddedLastYearInKg', massAddedLastYearInKg as any);
            }
    
            if (commissioningDate !== undefined) { 
                localVarFormParams.append('CommissioningDate', commissioningDate as any);
            }
    
            if (comments !== undefined) { 
                localVarFormParams.append('Comments', comments as any);
            }
                if (files) {
                files.forEach((element) => {
                    localVarFormParams.append('Files', element as any);
                })
            }

    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [id] 
         * @param {string} [companyBranchId] 
         * @param {string} [typeOfEquipmentId] 
         * @param {string} [typeOfEquipmentOther] 
         * @param {string} [typeOfCoolingSystemId] 
         * @param {string} [typeOfCoolingSystemOther] 
         * @param {string} [manufacturer] 
         * @param {string} [type] 
         * @param {string} [model] 
         * @param {string} [serialNumber] 
         * @param {number} [yearOfProduction] 
         * @param {string} [dateOfPurchase] 
         * @param {number} [massOfRefrigerantKg] 
         * @param {string} [purposeOfEquipmentId] 
         * @param {number} [coolingTemperature] 
         * @param {number} [coolingEffectKw] 
         * @param {number} [compressorConnectionPowerKw] 
         * @param {number} [liquidCollectorVolume] 
         * @param {number} [massAddedLastYearInKg] 
         * @param {string} [commissioningDate] 
         * @param {string} [comments] 
         * @param {string} [refrigerantTypeId] 
         * @param {Array<string>} [existingFileNames] 
         * @param {Array<string>} [toBeDeletedFileNames] 
         * @param {Array<File>} [files] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEquipmentsPut: async (id?: string, companyBranchId?: string, typeOfEquipmentId?: string, typeOfEquipmentOther?: string, typeOfCoolingSystemId?: string, typeOfCoolingSystemOther?: string, manufacturer?: string, type?: string, model?: string, serialNumber?: string, yearOfProduction?: number, dateOfPurchase?: string, massOfRefrigerantKg?: number, purposeOfEquipmentId?: string, coolingTemperature?: number, coolingEffectKw?: number, compressorConnectionPowerKw?: number, liquidCollectorVolume?: number, massAddedLastYearInKg?: number, commissioningDate?: string, comments?: string, refrigerantTypeId?: string, existingFileNames?: Array<string>, toBeDeletedFileNames?: Array<string>, files?: Array<File>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Equipments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


            if (id !== undefined) { 
                localVarFormParams.append('Id', id as any);
            }
    
            if (companyBranchId !== undefined) { 
                localVarFormParams.append('CompanyBranchId', companyBranchId as any);
            }
    
            if (typeOfEquipmentId !== undefined) { 
                localVarFormParams.append('TypeOfEquipmentId', typeOfEquipmentId as any);
            }
    
            if (typeOfEquipmentOther !== undefined) { 
                localVarFormParams.append('TypeOfEquipmentOther', typeOfEquipmentOther as any);
            }
    
            if (typeOfCoolingSystemId !== undefined) { 
                localVarFormParams.append('TypeOfCoolingSystemId', typeOfCoolingSystemId as any);
            }
    
            if (typeOfCoolingSystemOther !== undefined) { 
                localVarFormParams.append('TypeOfCoolingSystemOther', typeOfCoolingSystemOther as any);
            }
    
            if (manufacturer !== undefined) { 
                localVarFormParams.append('Manufacturer', manufacturer as any);
            }
    
            if (type !== undefined) { 
                localVarFormParams.append('Type', type as any);
            }
    
            if (model !== undefined) { 
                localVarFormParams.append('Model', model as any);
            }
    
            if (serialNumber !== undefined) { 
                localVarFormParams.append('SerialNumber', serialNumber as any);
            }
    
            if (yearOfProduction !== undefined) { 
                localVarFormParams.append('YearOfProduction', yearOfProduction as any);
            }
    
            if (dateOfPurchase !== undefined) { 
                localVarFormParams.append('DateOfPurchase', dateOfPurchase as any);
            }
    
            if (massOfRefrigerantKg !== undefined) { 
                localVarFormParams.append('MassOfRefrigerantKg', massOfRefrigerantKg as any);
            }
    
            if (purposeOfEquipmentId !== undefined) { 
                localVarFormParams.append('PurposeOfEquipmentId', purposeOfEquipmentId as any);
            }
    
            if (coolingTemperature !== undefined) { 
                localVarFormParams.append('CoolingTemperature', coolingTemperature as any);
            }
    
            if (coolingEffectKw !== undefined) { 
                localVarFormParams.append('CoolingEffectKw', coolingEffectKw as any);
            }
    
            if (compressorConnectionPowerKw !== undefined) { 
                localVarFormParams.append('CompressorConnectionPowerKw', compressorConnectionPowerKw as any);
            }
    
            if (liquidCollectorVolume !== undefined) { 
                localVarFormParams.append('LiquidCollectorVolume', liquidCollectorVolume as any);
            }
    
            if (massAddedLastYearInKg !== undefined) { 
                localVarFormParams.append('MassAddedLastYearInKg', massAddedLastYearInKg as any);
            }
    
            if (commissioningDate !== undefined) { 
                localVarFormParams.append('CommissioningDate', commissioningDate as any);
            }
    
            if (comments !== undefined) { 
                localVarFormParams.append('Comments', comments as any);
            }
    
            if (refrigerantTypeId !== undefined) { 
                localVarFormParams.append('RefrigerantTypeId', refrigerantTypeId as any);
            }
                if (existingFileNames) {
                existingFileNames.forEach((element) => {
                    localVarFormParams.append('ExistingFileNames', element as any);
                })
            }

                if (toBeDeletedFileNames) {
                toBeDeletedFileNames.forEach((element) => {
                    localVarFormParams.append('ToBeDeletedFileNames', element as any);
                })
            }

                if (files) {
                files.forEach((element) => {
                    localVarFormParams.append('Files', element as any);
                })
            }

    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EquipmentsApi - functional programming interface
 * @export
 */
export const EquipmentsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EquipmentsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [dateOfChange] 
         * @param {string} [typeOfChangeId] 
         * @param {string} [newOperatorName] 
         * @param {string} [newCoolantId] 
         * @param {string} [technicianCertificateNumber] 
         * @param {string} [comments] 
         * @param {string} [equipmentId] 
         * @param {Array<File>} [files] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiEquipmentsCreateActivityPost(dateOfChange?: string, typeOfChangeId?: string, newOperatorName?: string, newCoolantId?: string, technicianCertificateNumber?: string, comments?: string, equipmentId?: string, files?: Array<File>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiEquipmentsCreateActivityPost(dateOfChange, typeOfChangeId, newOperatorName, newCoolantId, technicianCertificateNumber, comments, equipmentId, files, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [search] 
         * @param {boolean} [isArchived] 
         * @param {string} [sortingPropertyName] 
         * @param {boolean} [sortingIsDescending] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiEquipmentsGet(pageNumber?: number, pageSize?: number, search?: string, isArchived?: boolean, sortingPropertyName?: string, sortingIsDescending?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EquipmentDtoOrdinalPaginatedList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiEquipmentsGet(pageNumber, pageSize, search, isArchived, sortingPropertyName, sortingIsDescending, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiEquipmentsIdActivitiesGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EquipmentActivityDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiEquipmentsIdActivitiesGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiEquipmentsIdArchivePost(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiEquipmentsIdArchivePost(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiEquipmentsIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiEquipmentsIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiEquipmentsIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EquipmentDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiEquipmentsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} certificateNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiEquipmentsIdTechnicianCertificateNumberCertificateNumberGet(id: string, certificateNumber: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TechnicianForActivityDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiEquipmentsIdTechnicianCertificateNumberCertificateNumberGet(id, certificateNumber, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [companyBranchId] 
         * @param {string} [typeOfEquipmentId] 
         * @param {string} [typeOfEquipmentOther] 
         * @param {string} [typeOfCoolingSystemId] 
         * @param {string} [typeOfCoolingSystemOther] 
         * @param {string} [manufacturer] 
         * @param {string} [type] 
         * @param {string} [model] 
         * @param {string} [serialNumber] 
         * @param {number} [yearOfProduction] 
         * @param {string} [dateOfPurchase] 
         * @param {string} [refrigerantTypeId] 
         * @param {number} [massOfRefrigerantKg] 
         * @param {string} [purposeOfEquipmentId] 
         * @param {number} [coolingTemperature] 
         * @param {number} [coolingEffectKw] 
         * @param {number} [compressorConnectionPowerKw] 
         * @param {number} [liquidCollectorVolume] 
         * @param {number} [massAddedLastYearInKg] 
         * @param {string} [commissioningDate] 
         * @param {string} [comments] 
         * @param {Array<File>} [files] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiEquipmentsPost(companyBranchId?: string, typeOfEquipmentId?: string, typeOfEquipmentOther?: string, typeOfCoolingSystemId?: string, typeOfCoolingSystemOther?: string, manufacturer?: string, type?: string, model?: string, serialNumber?: string, yearOfProduction?: number, dateOfPurchase?: string, refrigerantTypeId?: string, massOfRefrigerantKg?: number, purposeOfEquipmentId?: string, coolingTemperature?: number, coolingEffectKw?: number, compressorConnectionPowerKw?: number, liquidCollectorVolume?: number, massAddedLastYearInKg?: number, commissioningDate?: string, comments?: string, files?: Array<File>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiEquipmentsPost(companyBranchId, typeOfEquipmentId, typeOfEquipmentOther, typeOfCoolingSystemId, typeOfCoolingSystemOther, manufacturer, type, model, serialNumber, yearOfProduction, dateOfPurchase, refrigerantTypeId, massOfRefrigerantKg, purposeOfEquipmentId, coolingTemperature, coolingEffectKw, compressorConnectionPowerKw, liquidCollectorVolume, massAddedLastYearInKg, commissioningDate, comments, files, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [id] 
         * @param {string} [companyBranchId] 
         * @param {string} [typeOfEquipmentId] 
         * @param {string} [typeOfEquipmentOther] 
         * @param {string} [typeOfCoolingSystemId] 
         * @param {string} [typeOfCoolingSystemOther] 
         * @param {string} [manufacturer] 
         * @param {string} [type] 
         * @param {string} [model] 
         * @param {string} [serialNumber] 
         * @param {number} [yearOfProduction] 
         * @param {string} [dateOfPurchase] 
         * @param {number} [massOfRefrigerantKg] 
         * @param {string} [purposeOfEquipmentId] 
         * @param {number} [coolingTemperature] 
         * @param {number} [coolingEffectKw] 
         * @param {number} [compressorConnectionPowerKw] 
         * @param {number} [liquidCollectorVolume] 
         * @param {number} [massAddedLastYearInKg] 
         * @param {string} [commissioningDate] 
         * @param {string} [comments] 
         * @param {string} [refrigerantTypeId] 
         * @param {Array<string>} [existingFileNames] 
         * @param {Array<string>} [toBeDeletedFileNames] 
         * @param {Array<File>} [files] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiEquipmentsPut(id?: string, companyBranchId?: string, typeOfEquipmentId?: string, typeOfEquipmentOther?: string, typeOfCoolingSystemId?: string, typeOfCoolingSystemOther?: string, manufacturer?: string, type?: string, model?: string, serialNumber?: string, yearOfProduction?: number, dateOfPurchase?: string, massOfRefrigerantKg?: number, purposeOfEquipmentId?: string, coolingTemperature?: number, coolingEffectKw?: number, compressorConnectionPowerKw?: number, liquidCollectorVolume?: number, massAddedLastYearInKg?: number, commissioningDate?: string, comments?: string, refrigerantTypeId?: string, existingFileNames?: Array<string>, toBeDeletedFileNames?: Array<string>, files?: Array<File>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiEquipmentsPut(id, companyBranchId, typeOfEquipmentId, typeOfEquipmentOther, typeOfCoolingSystemId, typeOfCoolingSystemOther, manufacturer, type, model, serialNumber, yearOfProduction, dateOfPurchase, massOfRefrigerantKg, purposeOfEquipmentId, coolingTemperature, coolingEffectKw, compressorConnectionPowerKw, liquidCollectorVolume, massAddedLastYearInKg, commissioningDate, comments, refrigerantTypeId, existingFileNames, toBeDeletedFileNames, files, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * EquipmentsApi - factory interface
 * @export
 */
export const EquipmentsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EquipmentsApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [dateOfChange] 
         * @param {string} [typeOfChangeId] 
         * @param {string} [newOperatorName] 
         * @param {string} [newCoolantId] 
         * @param {string} [technicianCertificateNumber] 
         * @param {string} [comments] 
         * @param {string} [equipmentId] 
         * @param {Array<File>} [files] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEquipmentsCreateActivityPost(dateOfChange?: string, typeOfChangeId?: string, newOperatorName?: string, newCoolantId?: string, technicianCertificateNumber?: string, comments?: string, equipmentId?: string, files?: Array<File>, options?: any): AxiosPromise<void> {
            return localVarFp.apiEquipmentsCreateActivityPost(dateOfChange, typeOfChangeId, newOperatorName, newCoolantId, technicianCertificateNumber, comments, equipmentId, files, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [search] 
         * @param {boolean} [isArchived] 
         * @param {string} [sortingPropertyName] 
         * @param {boolean} [sortingIsDescending] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEquipmentsGet(pageNumber?: number, pageSize?: number, search?: string, isArchived?: boolean, sortingPropertyName?: string, sortingIsDescending?: boolean, options?: any): AxiosPromise<EquipmentDtoOrdinalPaginatedList> {
            return localVarFp.apiEquipmentsGet(pageNumber, pageSize, search, isArchived, sortingPropertyName, sortingIsDescending, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEquipmentsIdActivitiesGet(id: string, options?: any): AxiosPromise<Array<EquipmentActivityDto>> {
            return localVarFp.apiEquipmentsIdActivitiesGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEquipmentsIdArchivePost(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiEquipmentsIdArchivePost(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEquipmentsIdDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiEquipmentsIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEquipmentsIdGet(id: string, options?: any): AxiosPromise<EquipmentDto> {
            return localVarFp.apiEquipmentsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} certificateNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEquipmentsIdTechnicianCertificateNumberCertificateNumberGet(id: string, certificateNumber: string, options?: any): AxiosPromise<TechnicianForActivityDto> {
            return localVarFp.apiEquipmentsIdTechnicianCertificateNumberCertificateNumberGet(id, certificateNumber, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [companyBranchId] 
         * @param {string} [typeOfEquipmentId] 
         * @param {string} [typeOfEquipmentOther] 
         * @param {string} [typeOfCoolingSystemId] 
         * @param {string} [typeOfCoolingSystemOther] 
         * @param {string} [manufacturer] 
         * @param {string} [type] 
         * @param {string} [model] 
         * @param {string} [serialNumber] 
         * @param {number} [yearOfProduction] 
         * @param {string} [dateOfPurchase] 
         * @param {string} [refrigerantTypeId] 
         * @param {number} [massOfRefrigerantKg] 
         * @param {string} [purposeOfEquipmentId] 
         * @param {number} [coolingTemperature] 
         * @param {number} [coolingEffectKw] 
         * @param {number} [compressorConnectionPowerKw] 
         * @param {number} [liquidCollectorVolume] 
         * @param {number} [massAddedLastYearInKg] 
         * @param {string} [commissioningDate] 
         * @param {string} [comments] 
         * @param {Array<File>} [files] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEquipmentsPost(companyBranchId?: string, typeOfEquipmentId?: string, typeOfEquipmentOther?: string, typeOfCoolingSystemId?: string, typeOfCoolingSystemOther?: string, manufacturer?: string, type?: string, model?: string, serialNumber?: string, yearOfProduction?: number, dateOfPurchase?: string, refrigerantTypeId?: string, massOfRefrigerantKg?: number, purposeOfEquipmentId?: string, coolingTemperature?: number, coolingEffectKw?: number, compressorConnectionPowerKw?: number, liquidCollectorVolume?: number, massAddedLastYearInKg?: number, commissioningDate?: string, comments?: string, files?: Array<File>, options?: any): AxiosPromise<string> {
            return localVarFp.apiEquipmentsPost(companyBranchId, typeOfEquipmentId, typeOfEquipmentOther, typeOfCoolingSystemId, typeOfCoolingSystemOther, manufacturer, type, model, serialNumber, yearOfProduction, dateOfPurchase, refrigerantTypeId, massOfRefrigerantKg, purposeOfEquipmentId, coolingTemperature, coolingEffectKw, compressorConnectionPowerKw, liquidCollectorVolume, massAddedLastYearInKg, commissioningDate, comments, files, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [id] 
         * @param {string} [companyBranchId] 
         * @param {string} [typeOfEquipmentId] 
         * @param {string} [typeOfEquipmentOther] 
         * @param {string} [typeOfCoolingSystemId] 
         * @param {string} [typeOfCoolingSystemOther] 
         * @param {string} [manufacturer] 
         * @param {string} [type] 
         * @param {string} [model] 
         * @param {string} [serialNumber] 
         * @param {number} [yearOfProduction] 
         * @param {string} [dateOfPurchase] 
         * @param {number} [massOfRefrigerantKg] 
         * @param {string} [purposeOfEquipmentId] 
         * @param {number} [coolingTemperature] 
         * @param {number} [coolingEffectKw] 
         * @param {number} [compressorConnectionPowerKw] 
         * @param {number} [liquidCollectorVolume] 
         * @param {number} [massAddedLastYearInKg] 
         * @param {string} [commissioningDate] 
         * @param {string} [comments] 
         * @param {string} [refrigerantTypeId] 
         * @param {Array<string>} [existingFileNames] 
         * @param {Array<string>} [toBeDeletedFileNames] 
         * @param {Array<File>} [files] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEquipmentsPut(id?: string, companyBranchId?: string, typeOfEquipmentId?: string, typeOfEquipmentOther?: string, typeOfCoolingSystemId?: string, typeOfCoolingSystemOther?: string, manufacturer?: string, type?: string, model?: string, serialNumber?: string, yearOfProduction?: number, dateOfPurchase?: string, massOfRefrigerantKg?: number, purposeOfEquipmentId?: string, coolingTemperature?: number, coolingEffectKw?: number, compressorConnectionPowerKw?: number, liquidCollectorVolume?: number, massAddedLastYearInKg?: number, commissioningDate?: string, comments?: string, refrigerantTypeId?: string, existingFileNames?: Array<string>, toBeDeletedFileNames?: Array<string>, files?: Array<File>, options?: any): AxiosPromise<void> {
            return localVarFp.apiEquipmentsPut(id, companyBranchId, typeOfEquipmentId, typeOfEquipmentOther, typeOfCoolingSystemId, typeOfCoolingSystemOther, manufacturer, type, model, serialNumber, yearOfProduction, dateOfPurchase, massOfRefrigerantKg, purposeOfEquipmentId, coolingTemperature, coolingEffectKw, compressorConnectionPowerKw, liquidCollectorVolume, massAddedLastYearInKg, commissioningDate, comments, refrigerantTypeId, existingFileNames, toBeDeletedFileNames, files, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiEquipmentsCreateActivityPost operation in EquipmentsApi.
 * @export
 * @interface EquipmentsApiApiEquipmentsCreateActivityPostRequest
 */
export interface EquipmentsApiApiEquipmentsCreateActivityPostRequest {
    /**
     * 
     * @type {string}
     * @memberof EquipmentsApiApiEquipmentsCreateActivityPost
     */
    readonly dateOfChange?: string

    /**
     * 
     * @type {string}
     * @memberof EquipmentsApiApiEquipmentsCreateActivityPost
     */
    readonly typeOfChangeId?: string

    /**
     * 
     * @type {string}
     * @memberof EquipmentsApiApiEquipmentsCreateActivityPost
     */
    readonly newOperatorName?: string

    /**
     * 
     * @type {string}
     * @memberof EquipmentsApiApiEquipmentsCreateActivityPost
     */
    readonly newCoolantId?: string

    /**
     * 
     * @type {string}
     * @memberof EquipmentsApiApiEquipmentsCreateActivityPost
     */
    readonly technicianCertificateNumber?: string

    /**
     * 
     * @type {string}
     * @memberof EquipmentsApiApiEquipmentsCreateActivityPost
     */
    readonly comments?: string

    /**
     * 
     * @type {string}
     * @memberof EquipmentsApiApiEquipmentsCreateActivityPost
     */
    readonly equipmentId?: string

    /**
     * 
     * @type {Array<File>}
     * @memberof EquipmentsApiApiEquipmentsCreateActivityPost
     */
    readonly files?: Array<File>
}

/**
 * Request parameters for apiEquipmentsGet operation in EquipmentsApi.
 * @export
 * @interface EquipmentsApiApiEquipmentsGetRequest
 */
export interface EquipmentsApiApiEquipmentsGetRequest {
    /**
     * 
     * @type {number}
     * @memberof EquipmentsApiApiEquipmentsGet
     */
    readonly pageNumber?: number

    /**
     * 
     * @type {number}
     * @memberof EquipmentsApiApiEquipmentsGet
     */
    readonly pageSize?: number

    /**
     * 
     * @type {string}
     * @memberof EquipmentsApiApiEquipmentsGet
     */
    readonly search?: string

    /**
     * 
     * @type {boolean}
     * @memberof EquipmentsApiApiEquipmentsGet
     */
    readonly isArchived?: boolean

    /**
     * 
     * @type {string}
     * @memberof EquipmentsApiApiEquipmentsGet
     */
    readonly sortingPropertyName?: string

    /**
     * 
     * @type {boolean}
     * @memberof EquipmentsApiApiEquipmentsGet
     */
    readonly sortingIsDescending?: boolean
}

/**
 * Request parameters for apiEquipmentsIdActivitiesGet operation in EquipmentsApi.
 * @export
 * @interface EquipmentsApiApiEquipmentsIdActivitiesGetRequest
 */
export interface EquipmentsApiApiEquipmentsIdActivitiesGetRequest {
    /**
     * 
     * @type {string}
     * @memberof EquipmentsApiApiEquipmentsIdActivitiesGet
     */
    readonly id: string
}

/**
 * Request parameters for apiEquipmentsIdArchivePost operation in EquipmentsApi.
 * @export
 * @interface EquipmentsApiApiEquipmentsIdArchivePostRequest
 */
export interface EquipmentsApiApiEquipmentsIdArchivePostRequest {
    /**
     * 
     * @type {string}
     * @memberof EquipmentsApiApiEquipmentsIdArchivePost
     */
    readonly id: string
}

/**
 * Request parameters for apiEquipmentsIdDelete operation in EquipmentsApi.
 * @export
 * @interface EquipmentsApiApiEquipmentsIdDeleteRequest
 */
export interface EquipmentsApiApiEquipmentsIdDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof EquipmentsApiApiEquipmentsIdDelete
     */
    readonly id: string
}

/**
 * Request parameters for apiEquipmentsIdGet operation in EquipmentsApi.
 * @export
 * @interface EquipmentsApiApiEquipmentsIdGetRequest
 */
export interface EquipmentsApiApiEquipmentsIdGetRequest {
    /**
     * 
     * @type {string}
     * @memberof EquipmentsApiApiEquipmentsIdGet
     */
    readonly id: string
}

/**
 * Request parameters for apiEquipmentsIdTechnicianCertificateNumberCertificateNumberGet operation in EquipmentsApi.
 * @export
 * @interface EquipmentsApiApiEquipmentsIdTechnicianCertificateNumberCertificateNumberGetRequest
 */
export interface EquipmentsApiApiEquipmentsIdTechnicianCertificateNumberCertificateNumberGetRequest {
    /**
     * 
     * @type {string}
     * @memberof EquipmentsApiApiEquipmentsIdTechnicianCertificateNumberCertificateNumberGet
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof EquipmentsApiApiEquipmentsIdTechnicianCertificateNumberCertificateNumberGet
     */
    readonly certificateNumber: string
}

/**
 * Request parameters for apiEquipmentsPost operation in EquipmentsApi.
 * @export
 * @interface EquipmentsApiApiEquipmentsPostRequest
 */
export interface EquipmentsApiApiEquipmentsPostRequest {
    /**
     * 
     * @type {string}
     * @memberof EquipmentsApiApiEquipmentsPost
     */
    readonly companyBranchId?: string

    /**
     * 
     * @type {string}
     * @memberof EquipmentsApiApiEquipmentsPost
     */
    readonly typeOfEquipmentId?: string

    /**
     * 
     * @type {string}
     * @memberof EquipmentsApiApiEquipmentsPost
     */
    readonly typeOfEquipmentOther?: string

    /**
     * 
     * @type {string}
     * @memberof EquipmentsApiApiEquipmentsPost
     */
    readonly typeOfCoolingSystemId?: string

    /**
     * 
     * @type {string}
     * @memberof EquipmentsApiApiEquipmentsPost
     */
    readonly typeOfCoolingSystemOther?: string

    /**
     * 
     * @type {string}
     * @memberof EquipmentsApiApiEquipmentsPost
     */
    readonly manufacturer?: string

    /**
     * 
     * @type {string}
     * @memberof EquipmentsApiApiEquipmentsPost
     */
    readonly type?: string

    /**
     * 
     * @type {string}
     * @memberof EquipmentsApiApiEquipmentsPost
     */
    readonly model?: string

    /**
     * 
     * @type {string}
     * @memberof EquipmentsApiApiEquipmentsPost
     */
    readonly serialNumber?: string

    /**
     * 
     * @type {number}
     * @memberof EquipmentsApiApiEquipmentsPost
     */
    readonly yearOfProduction?: number

    /**
     * 
     * @type {string}
     * @memberof EquipmentsApiApiEquipmentsPost
     */
    readonly dateOfPurchase?: string

    /**
     * 
     * @type {string}
     * @memberof EquipmentsApiApiEquipmentsPost
     */
    readonly refrigerantTypeId?: string

    /**
     * 
     * @type {number}
     * @memberof EquipmentsApiApiEquipmentsPost
     */
    readonly massOfRefrigerantKg?: number

    /**
     * 
     * @type {string}
     * @memberof EquipmentsApiApiEquipmentsPost
     */
    readonly purposeOfEquipmentId?: string

    /**
     * 
     * @type {number}
     * @memberof EquipmentsApiApiEquipmentsPost
     */
    readonly coolingTemperature?: number

    /**
     * 
     * @type {number}
     * @memberof EquipmentsApiApiEquipmentsPost
     */
    readonly coolingEffectKw?: number

    /**
     * 
     * @type {number}
     * @memberof EquipmentsApiApiEquipmentsPost
     */
    readonly compressorConnectionPowerKw?: number

    /**
     * 
     * @type {number}
     * @memberof EquipmentsApiApiEquipmentsPost
     */
    readonly liquidCollectorVolume?: number

    /**
     * 
     * @type {number}
     * @memberof EquipmentsApiApiEquipmentsPost
     */
    readonly massAddedLastYearInKg?: number

    /**
     * 
     * @type {string}
     * @memberof EquipmentsApiApiEquipmentsPost
     */
    readonly commissioningDate?: string

    /**
     * 
     * @type {string}
     * @memberof EquipmentsApiApiEquipmentsPost
     */
    readonly comments?: string

    /**
     * 
     * @type {Array<File>}
     * @memberof EquipmentsApiApiEquipmentsPost
     */
    readonly files?: Array<File>
}

/**
 * Request parameters for apiEquipmentsPut operation in EquipmentsApi.
 * @export
 * @interface EquipmentsApiApiEquipmentsPutRequest
 */
export interface EquipmentsApiApiEquipmentsPutRequest {
    /**
     * 
     * @type {string}
     * @memberof EquipmentsApiApiEquipmentsPut
     */
    readonly id?: string

    /**
     * 
     * @type {string}
     * @memberof EquipmentsApiApiEquipmentsPut
     */
    readonly companyBranchId?: string

    /**
     * 
     * @type {string}
     * @memberof EquipmentsApiApiEquipmentsPut
     */
    readonly typeOfEquipmentId?: string

    /**
     * 
     * @type {string}
     * @memberof EquipmentsApiApiEquipmentsPut
     */
    readonly typeOfEquipmentOther?: string

    /**
     * 
     * @type {string}
     * @memberof EquipmentsApiApiEquipmentsPut
     */
    readonly typeOfCoolingSystemId?: string

    /**
     * 
     * @type {string}
     * @memberof EquipmentsApiApiEquipmentsPut
     */
    readonly typeOfCoolingSystemOther?: string

    /**
     * 
     * @type {string}
     * @memberof EquipmentsApiApiEquipmentsPut
     */
    readonly manufacturer?: string

    /**
     * 
     * @type {string}
     * @memberof EquipmentsApiApiEquipmentsPut
     */
    readonly type?: string

    /**
     * 
     * @type {string}
     * @memberof EquipmentsApiApiEquipmentsPut
     */
    readonly model?: string

    /**
     * 
     * @type {string}
     * @memberof EquipmentsApiApiEquipmentsPut
     */
    readonly serialNumber?: string

    /**
     * 
     * @type {number}
     * @memberof EquipmentsApiApiEquipmentsPut
     */
    readonly yearOfProduction?: number

    /**
     * 
     * @type {string}
     * @memberof EquipmentsApiApiEquipmentsPut
     */
    readonly dateOfPurchase?: string

    /**
     * 
     * @type {number}
     * @memberof EquipmentsApiApiEquipmentsPut
     */
    readonly massOfRefrigerantKg?: number

    /**
     * 
     * @type {string}
     * @memberof EquipmentsApiApiEquipmentsPut
     */
    readonly purposeOfEquipmentId?: string

    /**
     * 
     * @type {number}
     * @memberof EquipmentsApiApiEquipmentsPut
     */
    readonly coolingTemperature?: number

    /**
     * 
     * @type {number}
     * @memberof EquipmentsApiApiEquipmentsPut
     */
    readonly coolingEffectKw?: number

    /**
     * 
     * @type {number}
     * @memberof EquipmentsApiApiEquipmentsPut
     */
    readonly compressorConnectionPowerKw?: number

    /**
     * 
     * @type {number}
     * @memberof EquipmentsApiApiEquipmentsPut
     */
    readonly liquidCollectorVolume?: number

    /**
     * 
     * @type {number}
     * @memberof EquipmentsApiApiEquipmentsPut
     */
    readonly massAddedLastYearInKg?: number

    /**
     * 
     * @type {string}
     * @memberof EquipmentsApiApiEquipmentsPut
     */
    readonly commissioningDate?: string

    /**
     * 
     * @type {string}
     * @memberof EquipmentsApiApiEquipmentsPut
     */
    readonly comments?: string

    /**
     * 
     * @type {string}
     * @memberof EquipmentsApiApiEquipmentsPut
     */
    readonly refrigerantTypeId?: string

    /**
     * 
     * @type {Array<string>}
     * @memberof EquipmentsApiApiEquipmentsPut
     */
    readonly existingFileNames?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof EquipmentsApiApiEquipmentsPut
     */
    readonly toBeDeletedFileNames?: Array<string>

    /**
     * 
     * @type {Array<File>}
     * @memberof EquipmentsApiApiEquipmentsPut
     */
    readonly files?: Array<File>
}

/**
 * EquipmentsApi - object-oriented interface
 * @export
 * @class EquipmentsApi
 * @extends {BaseAPI}
 */
export class EquipmentsApi extends BaseAPI {
    /**
     * 
     * @param {EquipmentsApiApiEquipmentsCreateActivityPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EquipmentsApi
     */
    public apiEquipmentsCreateActivityPost(requestParameters: EquipmentsApiApiEquipmentsCreateActivityPostRequest = {}, options?: AxiosRequestConfig) {
        return EquipmentsApiFp(this.configuration).apiEquipmentsCreateActivityPost(requestParameters.dateOfChange, requestParameters.typeOfChangeId, requestParameters.newOperatorName, requestParameters.newCoolantId, requestParameters.technicianCertificateNumber, requestParameters.comments, requestParameters.equipmentId, requestParameters.files, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EquipmentsApiApiEquipmentsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EquipmentsApi
     */
    public apiEquipmentsGet(requestParameters: EquipmentsApiApiEquipmentsGetRequest = {}, options?: AxiosRequestConfig) {
        return EquipmentsApiFp(this.configuration).apiEquipmentsGet(requestParameters.pageNumber, requestParameters.pageSize, requestParameters.search, requestParameters.isArchived, requestParameters.sortingPropertyName, requestParameters.sortingIsDescending, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EquipmentsApiApiEquipmentsIdActivitiesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EquipmentsApi
     */
    public apiEquipmentsIdActivitiesGet(requestParameters: EquipmentsApiApiEquipmentsIdActivitiesGetRequest, options?: AxiosRequestConfig) {
        return EquipmentsApiFp(this.configuration).apiEquipmentsIdActivitiesGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EquipmentsApiApiEquipmentsIdArchivePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EquipmentsApi
     */
    public apiEquipmentsIdArchivePost(requestParameters: EquipmentsApiApiEquipmentsIdArchivePostRequest, options?: AxiosRequestConfig) {
        return EquipmentsApiFp(this.configuration).apiEquipmentsIdArchivePost(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EquipmentsApiApiEquipmentsIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EquipmentsApi
     */
    public apiEquipmentsIdDelete(requestParameters: EquipmentsApiApiEquipmentsIdDeleteRequest, options?: AxiosRequestConfig) {
        return EquipmentsApiFp(this.configuration).apiEquipmentsIdDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EquipmentsApiApiEquipmentsIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EquipmentsApi
     */
    public apiEquipmentsIdGet(requestParameters: EquipmentsApiApiEquipmentsIdGetRequest, options?: AxiosRequestConfig) {
        return EquipmentsApiFp(this.configuration).apiEquipmentsIdGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EquipmentsApiApiEquipmentsIdTechnicianCertificateNumberCertificateNumberGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EquipmentsApi
     */
    public apiEquipmentsIdTechnicianCertificateNumberCertificateNumberGet(requestParameters: EquipmentsApiApiEquipmentsIdTechnicianCertificateNumberCertificateNumberGetRequest, options?: AxiosRequestConfig) {
        return EquipmentsApiFp(this.configuration).apiEquipmentsIdTechnicianCertificateNumberCertificateNumberGet(requestParameters.id, requestParameters.certificateNumber, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EquipmentsApiApiEquipmentsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EquipmentsApi
     */
    public apiEquipmentsPost(requestParameters: EquipmentsApiApiEquipmentsPostRequest = {}, options?: AxiosRequestConfig) {
        return EquipmentsApiFp(this.configuration).apiEquipmentsPost(requestParameters.companyBranchId, requestParameters.typeOfEquipmentId, requestParameters.typeOfEquipmentOther, requestParameters.typeOfCoolingSystemId, requestParameters.typeOfCoolingSystemOther, requestParameters.manufacturer, requestParameters.type, requestParameters.model, requestParameters.serialNumber, requestParameters.yearOfProduction, requestParameters.dateOfPurchase, requestParameters.refrigerantTypeId, requestParameters.massOfRefrigerantKg, requestParameters.purposeOfEquipmentId, requestParameters.coolingTemperature, requestParameters.coolingEffectKw, requestParameters.compressorConnectionPowerKw, requestParameters.liquidCollectorVolume, requestParameters.massAddedLastYearInKg, requestParameters.commissioningDate, requestParameters.comments, requestParameters.files, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EquipmentsApiApiEquipmentsPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EquipmentsApi
     */
    public apiEquipmentsPut(requestParameters: EquipmentsApiApiEquipmentsPutRequest = {}, options?: AxiosRequestConfig) {
        return EquipmentsApiFp(this.configuration).apiEquipmentsPut(requestParameters.id, requestParameters.companyBranchId, requestParameters.typeOfEquipmentId, requestParameters.typeOfEquipmentOther, requestParameters.typeOfCoolingSystemId, requestParameters.typeOfCoolingSystemOther, requestParameters.manufacturer, requestParameters.type, requestParameters.model, requestParameters.serialNumber, requestParameters.yearOfProduction, requestParameters.dateOfPurchase, requestParameters.massOfRefrigerantKg, requestParameters.purposeOfEquipmentId, requestParameters.coolingTemperature, requestParameters.coolingEffectKw, requestParameters.compressorConnectionPowerKw, requestParameters.liquidCollectorVolume, requestParameters.massAddedLastYearInKg, requestParameters.commissioningDate, requestParameters.comments, requestParameters.refrigerantTypeId, requestParameters.existingFileNames, requestParameters.toBeDeletedFileNames, requestParameters.files, options).then((request) => request(this.axios, this.basePath));
    }
}
