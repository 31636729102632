import { Col, Input, Row, Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import { RequestsApi } from "api/api";
import {
  ListRequestDto,
  ListRequestDtoPaginatedList,
  RequestStatus,
} from "api/models";
import { Button } from "components/buttons/buttons";
import { ExportButtonPageHeader } from "components/buttons/export-button/export-button";
import { Cards } from "components/cards/frame/cards-frame";
import OrdinalNumber from "components/common/OrdinalNumber";
import { PageHeader } from "components/page-headers/page-headers";
import renderStatusBadge from "components/tags/requestStatusTag";
import { sortDirections } from "constants/constants";
import {
  CardToolbox,
  Main,
  ProfileTableStyleWrapper,
  TableWrapper,
} from "container/styled";
import { useTableSorting } from "hooks/useTableSorting";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import { hasPermission } from "utility/accessibility/hasPermission";

const requestsApi = new RequestsApi();

const intialData = {
  hasNextPage: false,
  hasPreviousPage: false,
  items: [],
  pageIndex: 1,
  totalCount: 0,
  totalPages: 0,
};

export const RequestsPage = () => {
  const location = useLocation();
  const isListingArchivedRequests = location.pathname === "/archived-requests";
  const { t } = useTranslation();
  const searchTimeout = useRef<any>();

  const [requestsData, setRequestsData] =
    useState<ListRequestDtoPaginatedList>(intialData);
  const [requestsLoading, setRequestsLoading] = useState(false);
  const { onSorterChange, sorting } = useTableSorting();
  const [query, setQuery] = useState({
    search: "",
    pageNumber: 1,
    pageSize: 10,
    listArchived: isListingArchivedRequests,
  });

  useEffect(() => {
    fetchRequests();
  }, [query, sorting]);

  const onSearchChange = (value: string) => {
    clearTimeout(searchTimeout.current);

    searchTimeout.current = setTimeout(() => {
      setQuery({ ...query, pageNumber: 1, search: value });
    }, 300);
  };

  const onPaginationChange = (pageNumber: number) => {
    setQuery((prevQuery) => ({ ...prevQuery, pageNumber }));
  };

  const onShowSizeChange = (pageNumber: number, pageSize: number) => {
    setQuery((prevQuery) => ({ ...prevQuery, pageNumber, pageSize }));
  };

  const fetchRequests = async () => {
    try {
    
      setRequestsLoading(true);
      const response = await requestsApi.apiRequestsGet({
        ...query,
        ...sorting,
      });

      setRequestsData(response.data);
    } catch (err) {
    } finally {
      setRequestsLoading(false);
    }
  };

  const columns: ColumnsType<any> = [
    {
      title: t("global.ordinal-number", "No."),
      dataIndex: "ordinalNumber",
      key: "ordinalNumber",
      sorter: false,
    },
    {
      title: t("requests:table.request-id", "Request Id"),
      dataIndex: "requestId",
      key: "requestId",
      sorter: true,
      sortDirections,
    },
    {
      title: t("requests:table.date", "Date"),
      dataIndex: "createdAt",
      key: "createdAt",
      sorter: true,
      sortDirections,
    },
    {
      title: t("global.name", "Name"),
      dataIndex: "name",
      key: "name",
      sorter: true,
      sortDirections,
    },
    {
      title: t("global.municipality", "Municipality"),
      dataIndex: "municipality",
      key: "municipality",
      sorter: true,
      sortDirections,
    },
    {
      title: t("requests:table.type-of-request", "Type of request"),
      dataIndex: "requestType",
      key: "requestType",
      sorter: true,
      sortDirections,
    },
  ];

  if (isListingArchivedRequests) {
    columns.push({
      title: t("global.status", "Status"),
      dataIndex: "status",
      key: "status",
      sorter: true,
      sortDirections,
    });
  }

  const canReviewRequests = isListingArchivedRequests
    ? hasPermission("requests:view-details") || hasPermission("requests:view-archived-request")
    : hasPermission("requests:approve");
  if (canReviewRequests) {
    columns.push({
      title: t("global.actions", "Actions"),
      dataIndex: "action",
      key: "action",
      sorter: false,
      fixed: "right",
    });
  }

  const tableData = (requestsData?.items ?? []).map((item: ListRequestDto) => {
    const {
      id,
      idNumber,
      requestId,
      ordinalNumber,
      name,
      municipality,
      requestType,
      requestTypeDesc,
      status,
      statusDesc,
      createdAt,
    } = item;
    return {
      key: id,
      idNumber,
      requestId,
      ordinalNumber: <OrdinalNumber value={ordinalNumber} />,
      name,
      municipality,
      requestType: t(
        `requests:request-type-${requestType}`,
        requestTypeDesc as string
      ),
      createdAt: moment(createdAt).format("MM.DD.yyyy"),
      status: (
        <div className="table-status">
          {renderStatusBadge(
            status as RequestStatus,
            t(`requests:status.${status}`, statusDesc as string)
          )}
        </div>
      ),
      action: canReviewRequests && (
        <div
          key={id}
          className="table-actions"
          style={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Button className="btn-icon" type="primary" to="#" shape="circle">
            <Link to={`/requests/${id}`}>
              {isListingArchivedRequests
                ? t("archived-requests:details", "Details")
                : t("requests:review", "Review")}
            </Link>
          </Button>
        </div>
      ),
    };
  });

  return (
    <>
      <CardToolbox>
        <PageHeader
          ghost
          title={
            isListingArchivedRequests
              ? t("archived-requests.title", "Archived Requests")
              : t("requests.title", "Requests")
          }
          buttons={[<ExportButtonPageHeader key="1" title={
            isListingArchivedRequests
              ? t("archived-requests.title", "Archived Requests")
              : t("requests.title", "Requests")
          } data={tableData} columns={['ordinalNumber', 'idNumber', 'createdAt', 'name', 'municipality']} />,]}
          subTitle={
            <div>
              <Input
                style={{ height: "38px" }}
                onChange={(param) => {
                  onSearchChange(param.target.value);
                }}
                placeholder={t(
                  "requests:search-by",
                  "Search by name, municipality.."
                )}
                width="100%"
              />
            </div>
          }
        />
      </CardToolbox>

      <Main>
        <Row gutter={15}>
          <Col md={24}>
            <Cards headless>
              <ProfileTableStyleWrapper>
                <div className="contact-table">
                  <TableWrapper className="table-responsive">
                    <Table
                      loading={requestsLoading}
                      dataSource={tableData}
                      columns={columns}
                      showSorterTooltip={false}
                      rowKey="key"
                      pagination={{
                        pageSize: query.pageSize,
                        current: requestsData.pageIndex,
                        total: requestsData.totalCount,
                        showSizeChanger: true,
                        pageSizeOptions: [10, 50, 100, 1000],
                        onChange: onPaginationChange,
                        onShowSizeChange: onShowSizeChange,
                        showTotal: (total, range) =>
                          `${range[0]}-${range[1]} of ${total} items`,
                      }}
                      onChange={(_, __, sorter) => onSorterChange(sorter)}
                    />
                  </TableWrapper>
                </div>
              </ProfileTableStyleWrapper>
            </Cards>
          </Col>
        </Row>
      </Main>
    </>
  );
};
