import { Col, Input, Popconfirm, Row, Table } from "antd";
import {
  OrganizationsApi,
  OrganizationsApiApiOrganizationsGetRequest,
} from "api/api";
import { OrganizationDto, OrganizationDtoPaginatedList } from "api/models";
import { Button } from "components/buttons/buttons";
import { Cards } from "components/cards/frame/cards-frame";
import { PageHeader } from "components/page-headers/page-headers";
import {
  CardToolbox,
  Main,
  ProfileTableStyleWrapper,
  TableWrapper,
} from "container/styled";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { hasPermission } from "utility/accessibility/hasPermission";
//@ts-ignore
import FeatherIcon from "feather-icons-react";
import { CreateInstitutionModal } from "pages/institutions/components/CreateInstitutionModal";
import openNotificationWithIcon from "utility/notification";
import InstitutionAdminFilters from "pages/institutions/components/InstitutionAdminFilters";
import { ExportButtonPageHeader } from "components/buttons/export-button/export-button";
import OrdinalNumber from "components/common/OrdinalNumber";

const organizationsApi = new OrganizationsApi();

const intialData = {
  hasNextPage: false,
  hasPreviousPage: false,
  items: [],
  pageIndex: 1,
  totalCount: 0,
  totalPages: 0,
};

type ModalStateType = {
  addModalVisible: boolean;
  editModalVisible: boolean;
  institutionToEdit: OrganizationDto | null;
};

export const InstitutionsPage = () => {
  const { t } = useTranslation();
  const searchTimeout = useRef<any>();

  const [institutionsData, setInstitutionsData] =
    useState<OrganizationDtoPaginatedList>(intialData);
  const [institutionsLoading, setInstitutionsLoading] = useState(false);
  const [modalState, setModalState] = useState<ModalStateType>({
    addModalVisible: false,
    editModalVisible: false,
    institutionToEdit: null,
  });
  const [query, setQuery] =
    useState<OrganizationsApiApiOrganizationsGetRequest>({
      search: "",
      pageNumber: 1,
      pageSize: 10,
    });

  useEffect(() => {
    fetchInstitutions();
  }, [query]);

  const onSearchChange = (value: string) => {
    clearTimeout(searchTimeout.current);

    searchTimeout.current = setTimeout(() => {
      setQuery({ ...query, pageNumber: 1, search: value });
    }, 300);
  };

  const onPaginationChange = (pageNumber: number) => {
    setQuery((prevQuery) => ({ ...prevQuery, pageNumber }));
  };

  const onShowSizeChange = (pageNumber: number, pageSize: number) => {
    setQuery((prevQuery) => ({ ...prevQuery, pageNumber, pageSize }));
  };

  const fetchInstitutions = async () => {
    try {
      setInstitutionsLoading(true);
      const response = await organizationsApi.apiOrganizationsGet(query);

      setInstitutionsData(response.data);
    } catch (err) {
    } finally {
      setInstitutionsLoading(false);
    }
  };

  const handleCreateInstitutionClick = () => {
    setModalState((prev) => ({
      ...prev,
      addModalVisible: true,
      editModalVisible: false,
    }));
  };

  const handleEditClick = (institution: OrganizationDto) => () => {
    setModalState((prev) => ({
      ...prev,
      editModalVisible: true,
      institutionToEdit: institution,
    }));
  };

  const onChangeQuery = (query: OrganizationsApiApiOrganizationsGetRequest) => {
    setQuery((prevQuery) => ({ ...prevQuery, ...query }));
  };

  const handleDeleteInstitutionClick = async (id: string | undefined) => {
    try {
      if (!id) return;
      await organizationsApi.apiOrganizationsIdDelete({ id });
      openNotificationWithIcon(
        "success",
        t("institutions:success-deleted", "Institution deleted successfully!")
      );
      fetchInstitutions();
    } catch (err) {
      console.log({ err });
    }
  };

  const handleSuccessAddEditInstitution = () => {
    hideInstitutionModal();
    fetchInstitutions();
  };

  const hideInstitutionModal = () => {
    setModalState({
      institutionToEdit: null,
      addModalVisible: false,
      editModalVisible: false,
    });
  };

  const columns = [
    {
      title: t("global.ordinal-number", "No."),
      dataIndex: "_ordinalNumber",
      key: "_ordinalNumber",
      sorter: false,
    },
    {
      title: t("global.name", "Name"),
      dataIndex: "name",
      key: "name",
    },
    {
      title: t("global.municipality", "Municipality"),
      dataIndex: "municipality",
      key: "municipality",
    },
    {
      title: t("global.place", "Place"),
      dataIndex: "place",
      key: "place",
    },
    {
      title: t("global.actions", "Actions"),
      dataIndex: "action",
      key: "action",
      width: "90px",
      style: {
        background: "red",
      },
    },
  ];

  const tableData = (institutionsData?.items ?? []).map(
    (item: OrganizationDto) => {
      const { id, ordinalNumber, name, municipality, place } = item;
      return {
        key: id,
        ordinalNumber: ordinalNumber,
        _ordinalNumber: <OrdinalNumber value={ordinalNumber} />,
        name,
        municipality,
        place,
        action: (
          <div
            key={id}
            className="table-actions"
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button className="btn-icon" type="primary" to="#" shape="circle">
              <Link onClick={handleEditClick(item)} to="#">
                <FeatherIcon icon="edit" size={16} />
              </Link>
            </Button>
            {hasPermission("institutions:delete") ? (
              <>
                <Popconfirm
                  title={t(
                    "institutions.delete-institution-confirmation",
                    "Are you sure delete this Institution?"
                  )}
                  onConfirm={() => handleDeleteInstitutionClick(id)}
                  okText={t("global.yes", "Yes")}
                  cancelText={t("global.no", "No")}
                >
                  <Button
                    className="btn-icon"
                    type="danger"
                    to="#"
                    shape="circle"
                  >
                    <FeatherIcon icon="trash-2" size={16} />
                  </Button>
                </Popconfirm>
              </>
            ) : null}
          </div>
        ),
      };
    }
  );

  return (
    <>
      <CardToolbox>
        <PageHeader
          ghost
          title={t("institutions.title", "Institutions")}
          buttons={[
            <ExportButtonPageHeader key="1" title={t("institutions.title", "Institutions")} data={tableData} columns={['ordinalNumber', 'name', 'municipality', 'place']} />,
            hasPermission("institutions:add") && (
              <Button
                onClick={handleCreateInstitutionClick}
                className="btn-add_new"
                type="primary"
                key="add-language"
              >
                {t("institutions.add", "+ Add New Institutions")}
              </Button>
            )
          ]}
          subTitle={
            <div
              style={{
                flexDirection: "row",
                display: "flex",
              }}
            >
              <Input
                style={{ height: "38px", width: 200 }}
                onChange={(param) => {
                  onSearchChange(param.target.value);
                }}
                placeholder={t(
                  "institutions:search-by",
                  "Search by name, municipality.."
                )}
              />
              <InstitutionAdminFilters onChangeQuery={onChangeQuery} />
            </div>
          }
        />
      </CardToolbox>

      <Main>
        <Row gutter={15}>
          <Col md={24}>
            <Cards headless>
              <ProfileTableStyleWrapper>
                <div className="contact-table">
                  <TableWrapper className="table-responsive">
                    <Table
                      loading={institutionsLoading}
                      dataSource={tableData}
                      columns={columns}
                      showSorterTooltip={false}
                      rowKey="key"
                      pagination={{
                        pageSize: query.pageSize,
                        current: institutionsData.pageIndex,
                        total: institutionsData.totalCount,
                        showSizeChanger: true,
                        pageSizeOptions: [10, 50, 100, 1000],
                        onChange: onPaginationChange,
                        onShowSizeChange: onShowSizeChange,
                        showTotal: (total, range) =>
                          `${range[0]}-${range[1]} of ${total} items`,
                      }}
                    />
                  </TableWrapper>
                </div>
              </ProfileTableStyleWrapper>
            </Cards>
          </Col>
        </Row>
      </Main>

      <CreateInstitutionModal
        id={modalState.institutionToEdit?.id}
        isVisible={modalState.addModalVisible}
        onHide={hideInstitutionModal}
        onSubmitSuccess={handleSuccessAddEditInstitution}
      />
      <CreateInstitutionModal
        id={modalState.institutionToEdit?.id}
        isVisible={modalState.editModalVisible}
        onHide={hideInstitutionModal}
        onSubmitSuccess={handleSuccessAddEditInstitution}
        institutionToEdit={modalState.institutionToEdit}
      />
    </>
  );
};
