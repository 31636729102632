import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";

const basePath = window.location.hostname === 'localhost' ? "https://localhost:7111/" : "/";

const options = {
  order: ["navigator", "localStorage"],
  caches: ["localStorage"],
  fallbackLng: [],
  load: "currentOnly",
  saveMissing: true, // send not translated keys to endpoint
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
  backend: {
    loadPath: basePath + "api/translations/{{lng}}",
    addPath: basePath + "api/translations/en",
    crossDomain: false,
    },
    nsSeparator: false,
  keySeparator: false,
};

i18n.use(Backend).use(LanguageDetector).use(initReactI18next).init(options);
