import { Avatar } from "antd";
import FeatherIcon from "feather-icons-react";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Dropdown } from "../../dropdown/dropdown";
import Heading from "../../heading/heading";
import { Popover } from "../../popup/popup";
import { InfoWraper, NavAuth, UserDropDwon } from "./auth-info-style";
import { LanguagesApi, SyncApi, UsersApi } from "api/api";
import { useTranslation } from "react-i18next";
import { theme } from "config/theme/themeVariables";
import isSuperAdmin from "utility/isSuperAdmin";
import openNotificationWithIcon from "utility/notification";

const languagesApi = new LanguagesApi();
const userApi = new UsersApi();

function AuthInfo() {
  const isSuperAdministrator = isSuperAdmin();
  const navigate = useNavigate();
  const syncApi = new SyncApi();

  const { t, i18n } = useTranslation();

  const [languages, setLanguages] = useState([]);
  const [syncRecordsCount, setSyncRecordsCount] = useState(0);

  const userName = JSON.parse(localStorage.getItem("user"))?.userName;

  const getLanguages = async () => {
    const { data } = await languagesApi.apiLanguagesGet();
    const lng = localStorage.getItem("i18nextLng");

    setLanguages(data);

    if (lng === "en-US") {
      // eslint-disable-next-line array-callback-return
      data?.map((res) => {
        if (res.isDefault) {
          i18n.changeLanguage(res.i18nCode.code);
        }
      });
    }
  };

  useEffect(() => {
    getLanguages();
    isSuperAdministrator && getSyncInfo();
  }, []);

  const SignOut = (e) => {
    e.preventDefault();
    localStorage.removeItem("user");
    navigate("/login");
  };

  const getSyncInfo = async () => {
    try {
      const response = await syncApi.apiSyncGet();
      setSyncRecordsCount(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const triggerSyncJob = async () => {
    try {
      await syncApi.apiSyncTriggerFailedSyncHangfirePost();
      openNotificationWithIcon(
        "success",
        t("sync-job:success", "Syncing was trigger successfully in background!")
      );
    } catch (error) {
      console.error(error);
    }
  }

  const onFlagChangeHandle = (value) => {
    try {
      i18n.changeLanguage(value);
      const languageObj =
        languages.find((x) => x?.i18nCode?.code === value) || null;

      if (!languageObj) return;

      userApi.usersSetDefaultLanguagePut({
        updateLanguageRequest: { languageId: languageObj?.id },
      });
    } catch (err) {}
  };

  const userContent = (
    <UserDropDwon>
      <div className="user-dropdwon">
        <figure className="user-dropdwon__info">
          <figcaption>
            <Heading as="h5">{userName || "John Doe"}</Heading>
          </figcaption>
        </figure>
        <ul className="user-dropdwon__links">
          <li>
            <Link to="profile">
              <FeatherIcon icon="user" /> Profile
            </Link>
          </li>
        </ul>
        <Link className="user-dropdwon__bottomAction" onClick={SignOut}>
          <FeatherIcon icon="log-out" /> Sign Out
        </Link>
      </div>
    </UserDropDwon>
  );

  const country = (
    <NavAuth>
      {languages.map((language) => {
        const isDefaultLanguage = language?.i18nCode?.code === i18n?.language;
        return (
          <Link
            onClick={() => onFlagChangeHandle(language?.i18nCode?.code)}
            to="#"
            key={language?.i18nCode?.code}
            style={{
              backgroundColor: isDefaultLanguage ? theme["linkActive"] : "",
            }}
          >
            <span className={isDefaultLanguage ? "ant-menu-item-selected" : ""}>
              {language?.i18nCode?.name}
            </span>
          </Link>
        );
      })}
    </NavAuth>
  );

  return (
    <InfoWraper>
      {/* <Message /> */}
      {/* <Notification /> */}

      {/* <Settings /> */}
      {/* <Support /> */}

      {isSuperAdministrator && (
        <div className="nav-author">
          <div className="icon-container" onClick={triggerSyncJob}>
            <FeatherIcon size={20} icon="refresh-cw" />
            {syncRecordsCount > 0 && (
              <span className="sync-count">{syncRecordsCount > 15 ? '15+' : syncRecordsCount}</span>
            )}
          </div>
        </div>
      )}
      <div className="nav-author">
        <Dropdown placement="bottomRight" content={country}>
          <Link
            to="#"
            className="head-example"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "row",
            }}
          >
            <FeatherIcon icon="globe" />
            <p style={{ fontWeight: 500, margin: 0, paddingLeft: 5 }}>
              {localStorage.getItem("i18nextLng").toUpperCase()}
            </p>
          </Link>
        </Dropdown>
      </div>

      <div className="nav-author">
        <Popover placement="bottomRight" content={userContent} action="click">
          <Link to="#" className="head-example">
            <Avatar src="https://cdn0.iconfinder.com/data/icons/user-pictures/100/matureman1-512.png" />
          </Link>
        </Popover>
      </div>
    </InfoWraper>
  );
}

export default AuthInfo;
