/* tslint:disable */
/* eslint-disable */
/**
 * KGH PROJECT API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CompanyByEntityReportDtoOrdinalPaginatedList } from '../models';
// @ts-ignore
import { GuidReportResponseDtoOrdinalPaginatedList } from '../models';
// @ts-ignore
import { Int32ReportResponseDtoOrdinalPaginatedList } from '../models';
/**
 * ReportsApi - axios parameter creator
 * @export
 */
export const ReportsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsCompaniesByEntityGet: async (from?: string, to?: string, pageNumber?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Reports/companies-by-entity`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (from !== undefined) {
                localVarQueryParameter['From'] = (from as any instanceof Date) ?
                    (from as any).toISOString() :
                    from;
            }

            if (to !== undefined) {
                localVarQueryParameter['To'] = (to as any instanceof Date) ?
                    (to as any).toISOString() :
                    to;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['PageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
        * 
        * @param {any} [checkCertificateNumberAvailabilityQuery] 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        apiAnnualReportByServiceTechnicianPost: async (checkCertificateNumberAvailabilityQuery?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ServiceTechnicianReport`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(checkCertificateNumberAvailabilityQuery, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },

        /**
     * 
     * @param {any} [checkCertificateNumberAvailabilityQuery] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
        apiImportExportSubstancesReportPost: async (checkCertificateNumberAvailabilityQuery?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ImportExportSubstancesReport`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(checkCertificateNumberAvailabilityQuery, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },


        /**
    * 
    * @param {string} id 
    * @param {*} [options] Override http request option.
    * @throws {RequiredError}
    */
        apiAnnualReportByServiceTechnicianIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiAnnualReportByServiceTechnicianIdDelete', 'id', id)
            const localVarPath = `/api/ServiceTechnicianReport/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },



        /**
    * 
    * @param {string} id 
    * @param {*} [options] Override http request option.
    * @throws {RequiredError}
    */
        apiAnnualReportOnImportExportSubstancesIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiAnnualReportByServiceTechnicianIdDelete', 'id', id)
            const localVarPath = `/api/ImportExportSubstancesReport/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },

        /**
         * 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsEquipmentsByCoolingSystemGet: async (from?: string, to?: string, pageNumber?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Reports/equipments-by-cooling-system`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (from !== undefined) {
                localVarQueryParameter['From'] = (from as any instanceof Date) ?
                    (from as any).toISOString() :
                    from;
            }

            if (to !== undefined) {
                localVarQueryParameter['To'] = (to as any instanceof Date) ?
                    (to as any).toISOString() :
                    to;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['PageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsEquipmentsByMunicipalityGet: async (from?: string, to?: string, pageNumber?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Reports/equipments-by-municipality`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (from !== undefined) {
                localVarQueryParameter['From'] = (from as any instanceof Date) ?
                    (from as any).toISOString() :
                    from;
            }

            if (to !== undefined) {
                localVarQueryParameter['To'] = (to as any instanceof Date) ?
                    (to as any).toISOString() :
                    to;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['PageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsEquipmentsByPurposeGet: async (from?: string, to?: string, pageNumber?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Reports/equipments-by-purpose`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (from !== undefined) {
                localVarQueryParameter['From'] = (from as any instanceof Date) ?
                    (from as any).toISOString() :
                    from;
            }

            if (to !== undefined) {
                localVarQueryParameter['To'] = (to as any instanceof Date) ?
                    (to as any).toISOString() :
                    to;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['PageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },

        /**
    * 
    * @param {string} [from] 
    * @param {string} [to] 
    * @param {number} [pageNumber] 
    * @param {number} [pageSize] 
    * @param {*} [options] Override http request option.
    * @throws {RequiredError}
    */
        apiMVTEOAnnualReportByServiceTechnicianGet: async (search?: string, pageNumber?: number, pageSize?: number,sortingPropertyName?: string, sortingIsDescending?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ServiceTechnicianReport/summary`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (search !== undefined) {
                localVarQueryParameter['Search'] = (search as any instanceof Date) ?
                    (search as any).toISOString() :
                    search;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['PageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (sortingPropertyName !== undefined) {
                localVarQueryParameter['SortBy'] = sortingPropertyName;
            }

            if (sortingIsDescending !== undefined) {
                if(sortingIsDescending){
                    localVarQueryParameter['SortType'] = "desc";
                }else{
                    localVarQueryParameter['SortType'] = "asc";
                }
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },


        /**
* 
* @param {string} [from] 
* @param {string} [to] 
* @param {number} [pageNumber] 
* @param {number} [pageSize] 
* @param {*} [options] Override http request option.
* @throws {RequiredError}
*/
        apiMVTEOAnnualReportOnImportExportSubstancesGet: async (search?: string, pageNumber?: number, pageSize?: number,sortingPropertyName?: string, sortingIsDescending?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ImportExportSubstancesReport/summary`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (search !== undefined) {
                localVarQueryParameter['Search'] = (search as any instanceof Date) ?
                    (search as any).toISOString() :
                    search;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['PageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (sortingPropertyName !== undefined) {
                localVarQueryParameter['SortBy'] = sortingPropertyName;
            }

            if (sortingIsDescending !== undefined) {
                if(sortingIsDescending){
                    localVarQueryParameter['SortType'] = "desc";
                }else{
                    localVarQueryParameter['SortType'] = "asc";
                }
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },

        /**
        * 
        * @param {string} [search] 
        * @param {number} [pageNumber] 
        * @param {number} [pageSize] 
        * @param {string} [sortingPropertyName] 
        * @param {boolean} [sortingIsDescending] 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        apiReportsAnnualReportByServiceGet: async (search?: string, pageNumber?: number, pageSize?: number,sortingPropertyName?: string, sortingIsDescending?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ServiceTechnicianReport`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (search !== undefined) {
                localVarQueryParameter['Search'] = (search as any instanceof Date) ?
                    (search as any).toISOString() :
                    search;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['PageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (sortingPropertyName !== undefined) {
                localVarQueryParameter['Sorting.PropertyName'] = sortingPropertyName;
            }

            if (sortingIsDescending !== undefined) {
                localVarQueryParameter['Sorting.IsDescending'] = sortingIsDescending;
            }


            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },

        /**
      * 
      * @param {string} [search] 
      * @param {number} [pageNumber] 
      * @param {number} [pageSize] 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      */
        apiReportsImportExportSubstancesGet: async (search?: string, pageNumber?: number, pageSize?: number,sortingPropertyName?: string, sortingIsDescending?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ImportExportSubstancesReport`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (search !== undefined) {
                localVarQueryParameter['Search'] = (search as any instanceof Date) ?
                    (search as any).toISOString() :
                    search;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['PageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (sortingPropertyName !== undefined) {
                localVarQueryParameter['Sorting.PropertyName'] = sortingPropertyName;
            }

            if (sortingIsDescending !== undefined) {
                localVarQueryParameter['Sorting.IsDescending'] = sortingIsDescending;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },


        /**
         * 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsRefrigerantsByEntityGet: async (from?: string, to?: string, pageNumber?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Reports/refrigerants-by-entity`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (from !== undefined) {
                localVarQueryParameter['From'] = (from as any instanceof Date) ?
                    (from as any).toISOString() :
                    from;
            }

            if (to !== undefined) {
                localVarQueryParameter['To'] = (to as any instanceof Date) ?
                    (to as any).toISOString() :
                    to;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['PageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsServiceCompaniesGet: async (from?: string, to?: string, pageNumber?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Reports/service-companies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (from !== undefined) {
                localVarQueryParameter['From'] = (from as any instanceof Date) ?
                    (from as any).toISOString() :
                    from;
            }

            if (to !== undefined) {
                localVarQueryParameter['To'] = (to as any instanceof Date) ?
                    (to as any).toISOString() :
                    to;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['PageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsTechniciansByEntityGet: async (from?: string, to?: string, pageNumber?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Reports/technicians-by-entity`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (from !== undefined) {
                localVarQueryParameter['From'] = (from as any instanceof Date) ?
                    (from as any).toISOString() :
                    from;
            }

            if (to !== undefined) {
                localVarQueryParameter['To'] = (to as any instanceof Date) ?
                    (to as any).toISOString() :
                    to;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['PageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsTechniciansByQualificationsGet: async (from?: string, to?: string, pageNumber?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Reports/technicians-by-qualifications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (from !== undefined) {
                localVarQueryParameter['From'] = (from as any instanceof Date) ?
                    (from as any).toISOString() :
                    from;
            }

            if (to !== undefined) {
                localVarQueryParameter['To'] = (to as any instanceof Date) ?
                    (to as any).toISOString() :
                    to;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['PageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsTechniciansByTrainingCenterGet: async (from?: string, to?: string, pageNumber?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Reports/technicians-by-training-center`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (from !== undefined) {
                localVarQueryParameter['From'] = (from as any instanceof Date) ?
                    (from as any).toISOString() :
                    from;
            }

            if (to !== undefined) {
                localVarQueryParameter['To'] = (to as any instanceof Date) ?
                    (to as any).toISOString() :
                    to;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['PageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },

        /**
      * 
      * @param {string} id 
      * @param {any} [updateUserCommand] 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      */
        apiReportsTechniciansByIdPut: async (updateUserCommand?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            // assertParamExists('ServiceTechnicianReport', 'id', )
            const localVarPath = `/api/ServiceTechnicianReport`
            // .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(updateUserCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },

        /**
      * 
      * @param {string} id 
      * @param {any} [updateUserCommand] 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      */
        apiAnnualReportOnImportExportSubstancesIdPut: async (updateUserCommand?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            // assertParamExists('ServiceTechnicianReport', 'id', )
            const localVarPath = `/api/ImportExportSubstancesReport`
            // .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(updateUserCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },

        /**
        * 
        * @param {string} id 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */

        apiReportsTechniciansByTrainingCenterGetById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('ServiceTechnicianReport', 'id', id)
            const localVarPath = `/api/ServiceTechnicianReport/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },

        /**
        * 
        * @param {string} id 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */

        apiAnnualReportOnImportExportSubstancesGetById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('ImportExportSubstancesReport', 'id', id)
            const localVarPath = `/api/ImportExportSubstancesReport/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReportsApi - functional programming interface
 * @export
 */
export const ReportsApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = ReportsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReportsCompaniesByEntityGet(from?: string, to?: string, pageNumber?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyByEntityReportDtoOrdinalPaginatedList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportsCompaniesByEntityGet(from, to, pageNumber, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReportsEquipmentsByCoolingSystemGet(from?: string, to?: string, pageNumber?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Int32ReportResponseDtoOrdinalPaginatedList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportsEquipmentsByCoolingSystemGet(from, to, pageNumber, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReportsEquipmentsByMunicipalityGet(from?: string, to?: string, pageNumber?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Int32ReportResponseDtoOrdinalPaginatedList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportsEquipmentsByMunicipalityGet(from, to, pageNumber, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReportsEquipmentsByPurposeGet(from?: string, to?: string, pageNumber?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Int32ReportResponseDtoOrdinalPaginatedList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportsEquipmentsByPurposeGet(from, to, pageNumber, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },

        /**
        * 
        * @param {string} [from] 
        * @param {string} [to] 
        * @param {number} [pageNumber] 
        * @param {number} [pageSize] 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        async apiMVTEOAnnualReportByServiceTechnicianGet(search?: string, pageNumber?: any, pageSize?: number,sortingPropertyName?: string, sortingIsDescending?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMVTEOAnnualReportByServiceTechnicianGet(search, pageNumber, pageSize,sortingPropertyName,sortingIsDescending, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },

        /**
       * 
       * @param {string} [from] 
       * @param {string} [to] 
       * @param {number} [pageNumber] 
       * @param {number} [pageSize] 
       * @param {*} [options] Override http request option.
       * @throws {RequiredError}
       */
        async apiMVTEOAnnualReportOnImportExportSubstancesGet(search?: string, pageNumber?: any, pageSize?: number, sortingPropertyName?: string, sortingIsDescending?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMVTEOAnnualReportOnImportExportSubstancesGet(search, pageNumber, pageSize,sortingPropertyName, sortingIsDescending, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },


        /**
       * 
       * @param {string} [from] 
       * @param {string} [to] 
       * @param {number} [pageNumber] 
       * @param {number} [pageSize] 
       * @param {*} [options] Override http request option.
       * @throws {RequiredError}
       */
        async apiReportsAnnualReportByServiceGet(search?: string, pageNumber?: number, pageSize?: number,sortingPropertyName?: string, sortingIsDescending?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Int32ReportResponseDtoOrdinalPaginatedList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportsAnnualReportByServiceGet(search, pageNumber, pageSize,sortingPropertyName, sortingIsDescending, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },

        /**
   * 
   * @param {string} [from] 
   * @param {string} [to] 
   * @param {number} [pageNumber] 
   * @param {number} [pageSize] 
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   */
        async apiReportsImportExportSubstancesGet(search?: string, pageNumber?: number, pageSize?: number,sortingPropertyName?: string, sortingIsDescending?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Int32ReportResponseDtoOrdinalPaginatedList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportsImportExportSubstancesGet(search, pageNumber, pageSize,sortingPropertyName, sortingIsDescending, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },


        /**
         * 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReportsRefrigerantsByEntityGet(from?: string, to?: string, pageNumber?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyByEntityReportDtoOrdinalPaginatedList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportsRefrigerantsByEntityGet(from, to, pageNumber, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReportsServiceCompaniesGet(from?: string, to?: string, pageNumber?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyByEntityReportDtoOrdinalPaginatedList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportsServiceCompaniesGet(from, to, pageNumber, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReportsTechniciansByEntityGet(from?: string, to?: string, pageNumber?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GuidReportResponseDtoOrdinalPaginatedList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportsTechniciansByEntityGet(from, to, pageNumber, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReportsTechniciansByQualificationsGet(from?: string, to?: string, pageNumber?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GuidReportResponseDtoOrdinalPaginatedList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportsTechniciansByQualificationsGet(from, to, pageNumber, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReportsTechniciansByTrainingCenterGet(from?: string, to?: string, pageNumber?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GuidReportResponseDtoOrdinalPaginatedList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportsTechniciansByTrainingCenterGet(from, to, pageNumber, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },

        /**
        * 
        * @param {string} [from] 
        * @param {string} [to] 
        * @param {number} [pageNumber] 
        * @param {number} [pageSize] 
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        async apiReportsTechniciansByTrainingCenterGetById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportsTechniciansByTrainingCenterGetById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },

        /**
      * 
      * @param {string} [from] 
      * @param {string} [to] 
      * @param {number} [pageNumber] 
      * @param {number} [pageSize] 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      */
        async apiAnnualReportOnImportExportSubstancesGetById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAnnualReportOnImportExportSubstancesGetById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },

        /**
       * 
       * @param {string} id 
       * @param {any} [updateUserCommand] 
       * @param {*} [options] Override http request option.
       * @throws {RequiredError}
       */
        async apiReportsTechniciansByIdPut(updateUserCommand?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportsTechniciansByIdPut(updateUserCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },

        /**
  * 
  * @param {string} id 
  * @param {any} [updateUserCommand] 
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
        async apiAnnualReportOnImportExportSubstancesIdPut(updateUserCommand?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAnnualReportOnImportExportSubstancesIdPut(updateUserCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },

        /**
       * 
       * @param {any} [checkCertificateNumberAvailabilityQuery] 
       * @param {*} [options] Override http request option.
       * @throws {RequiredError}
       */
        async apiAnnualReportByServiceTechnicianPost(checkCertificateNumberAvailabilityQuery?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<any>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAnnualReportByServiceTechnicianPost(checkCertificateNumberAvailabilityQuery, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },

        /**
* 
* @param {any} [checkCertificateNumberAvailabilityQuery] 
* @param {*} [options] Override http request option.
* @throws {RequiredError}
*/
        async apiImportExportSubstancesReportPost(checkCertificateNumberAvailabilityQuery?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<any>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiImportExportSubstancesReportPost(checkCertificateNumberAvailabilityQuery, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },


        /**
       * 
       * @param {string} id 
       * @param {*} [options] Override http request option.
       * @throws {RequiredError}
       */
        async apiAnnualReportByServiceTechnicianIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAnnualReportByServiceTechnicianIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },


        /**
       * 
       * @param {string} id 
       * @param {*} [options] Override http request option.
       * @throws {RequiredError}
       */
        async apiAnnualReportOnImportExportSubstancesIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAnnualReportOnImportExportSubstancesIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ReportsApi - factory interface
 * @export
 */
export const ReportsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReportsApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsCompaniesByEntityGet(from?: string, to?: string, pageNumber?: number, pageSize?: number, options?: any): AxiosPromise<CompanyByEntityReportDtoOrdinalPaginatedList> {
            return localVarFp.apiReportsCompaniesByEntityGet(from, to, pageNumber, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsEquipmentsByCoolingSystemGet(from?: string, to?: string, pageNumber?: number, pageSize?: number, options?: any): AxiosPromise<Int32ReportResponseDtoOrdinalPaginatedList> {
            return localVarFp.apiReportsEquipmentsByCoolingSystemGet(from, to, pageNumber, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsEquipmentsByMunicipalityGet(from?: string, to?: string, pageNumber?: number, pageSize?: number, options?: any): AxiosPromise<Int32ReportResponseDtoOrdinalPaginatedList> {
            return localVarFp.apiReportsEquipmentsByMunicipalityGet(from, to, pageNumber, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsEquipmentsByPurposeGet(from?: string, to?: string, pageNumber?: number, pageSize?: number, options?: any): AxiosPromise<Int32ReportResponseDtoOrdinalPaginatedList> {
            return localVarFp.apiReportsEquipmentsByPurposeGet(from, to, pageNumber, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsRefrigerantsByEntityGet(from?: string, to?: string, pageNumber?: number, pageSize?: number, options?: any): AxiosPromise<CompanyByEntityReportDtoOrdinalPaginatedList> {
            return localVarFp.apiReportsRefrigerantsByEntityGet(from, to, pageNumber, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsServiceCompaniesGet(from?: string, to?: string, pageNumber?: number, pageSize?: number, options?: any): AxiosPromise<CompanyByEntityReportDtoOrdinalPaginatedList> {
            return localVarFp.apiReportsServiceCompaniesGet(from, to, pageNumber, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsTechniciansByEntityGet(from?: string, to?: string, pageNumber?: number, pageSize?: number, options?: any): AxiosPromise<GuidReportResponseDtoOrdinalPaginatedList> {
            return localVarFp.apiReportsTechniciansByEntityGet(from, to, pageNumber, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsTechniciansByQualificationsGet(from?: string, to?: string, pageNumber?: number, pageSize?: number, options?: any): AxiosPromise<GuidReportResponseDtoOrdinalPaginatedList> {
            return localVarFp.apiReportsTechniciansByQualificationsGet(from, to, pageNumber, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsTechniciansByTrainingCenterGet(from?: string, to?: string, pageNumber?: number, pageSize?: number, options?: any): AxiosPromise<GuidReportResponseDtoOrdinalPaginatedList> {
            return localVarFp.apiReportsTechniciansByTrainingCenterGet(from, to, pageNumber, pageSize, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiReportsCompaniesByEntityGet operation in ReportsApi.
 * @export
 * @interface ReportsApiApiReportsCompaniesByEntityGetRequest
 */
export interface ReportsApiApiReportsCompaniesByEntityGetRequest {
    /**
     * 
     * @type {string}
     * @memberof ReportsApiApiReportsCompaniesByEntityGet
     */
    readonly from?: string

    /**
     * 
     * @type {string}
     * @memberof ReportsApiApiReportsCompaniesByEntityGet
     */
    readonly to?: string

    /**
     * 
     * @type {number}
     * @memberof ReportsApiApiReportsCompaniesByEntityGet
     */
    readonly pageNumber?: number

    /**
     * 
     * @type {number}
     * @memberof ReportsApiApiReportsCompaniesByEntityGet
     */
    readonly pageSize?: number
}

/**
 * Request parameters for apiReportsEquipmentsByCoolingSystemGet operation in ReportsApi.
 * @export
 * @interface ReportsApiApiReportsEquipmentsByCoolingSystemGetRequest
 */
export interface ReportsApiApiReportsEquipmentsByCoolingSystemGetRequest {
    /**
     * 
     * @type {string}
     * @memberof ReportsApiApiReportsEquipmentsByCoolingSystemGet
     */
    readonly from?: string

    /**
     * 
     * @type {string}
     * @memberof ReportsApiApiReportsEquipmentsByCoolingSystemGet
     */
    readonly to?: string

    /**
     * 
     * @type {number}
     * @memberof ReportsApiApiReportsEquipmentsByCoolingSystemGet
     */
    readonly pageNumber?: number

    /**
     * 
     * @type {number}
     * @memberof ReportsApiApiReportsEquipmentsByCoolingSystemGet
     */
    readonly pageSize?: number
}

/**
 * Request parameters for apiReportsEquipmentsByMunicipalityGet operation in ReportsApi.
 * @export
 * @interface ReportsApiApiReportsEquipmentsByMunicipalityGetRequest
 */
export interface ReportsApiApiReportsEquipmentsByMunicipalityGetRequest {
    /**
     * 
     * @type {string}
     * @memberof ReportsApiApiReportsEquipmentsByMunicipalityGet
     */
    readonly from?: string

    /**
     * 
     * @type {string}
     * @memberof ReportsApiApiReportsEquipmentsByMunicipalityGet
     */
    readonly to?: string

    /**
     * 
     * @type {number}
     * @memberof ReportsApiApiReportsEquipmentsByMunicipalityGet
     */
    readonly pageNumber?: number

    /**
     * 
     * @type {number}
     * @memberof ReportsApiApiReportsEquipmentsByMunicipalityGet
     */
    readonly pageSize?: number
}

/**
 * Request parameters for apiReportsEquipmentsByPurposeGet operation in ReportsApi.
 * @export
 * @interface ReportsApiApiReportsEquipmentsByPurposeGetRequest
 */
export interface ReportsApiApiReportsEquipmentsByPurposeGetRequest {
    /**
     * 
     * @type {string}
     * @memberof ReportsApiApiReportsEquipmentsByPurposeGet
     */
    readonly from?: string

    /**
     * 
     * @type {string}
     * @memberof ReportsApiApiReportsEquipmentsByPurposeGet
     */
    readonly to?: string

    /**
     * 
     * @type {number}
     * @memberof ReportsApiApiReportsEquipmentsByPurposeGet
     */
    readonly pageNumber?: number

    /**
     * 
     * @type {number}
     * @memberof ReportsApiApiReportsEquipmentsByPurposeGet
     */
    readonly pageSize?: number
}

/**
 * Request parameters for apiReportsRefrigerantsByEntityGet operation in ReportsApi.
 * @export
 * @interface ReportsApiApiReportsRefrigerantsByEntityGetRequest
 */
export interface ReportsApiApiReportsRefrigerantsByEntityGetRequest {
    /**
     * 
     * @type {string}
     * @memberof ReportsApiApiReportsRefrigerantsByEntityGet
     */
    readonly from?: string

    /**
     * 
     * @type {string}
     * @memberof ReportsApiApiReportsRefrigerantsByEntityGet
     */
    readonly to?: string

    /**
     * 
     * @type {number}
     * @memberof ReportsApiApiReportsRefrigerantsByEntityGet
     */
    readonly pageNumber?: number

    /**
     * 
     * @type {number}
     * @memberof ReportsApiApiReportsRefrigerantsByEntityGet
     */
    readonly pageSize?: number
}

/**
 * Request parameters for apiReportsServiceCompaniesGet operation in ReportsApi.
 * @export
 * @interface ReportsApiApiReportsServiceCompaniesGetRequest
 */
export interface ReportsApiApiReportsServiceCompaniesGetRequest {
    /**
     * 
     * @type {string}
     * @memberof ReportsApiApiReportsServiceCompaniesGet
     */
    readonly from?: string

    /**
     * 
     * @type {string}
     * @memberof ReportsApiApiReportsServiceCompaniesGet
     */
    readonly to?: string

    /**
     * 
     * @type {number}
     * @memberof ReportsApiApiReportsServiceCompaniesGet
     */
    readonly pageNumber?: number

    /**
     * 
     * @type {number}
     * @memberof ReportsApiApiReportsServiceCompaniesGet
     */
    readonly pageSize?: number
}

/**
 * Request parameters for apiReportsTechniciansByEntityGet operation in ReportsApi.
 * @export
 * @interface ReportsApiApiReportsTechniciansByEntityGetRequest
 */
export interface ReportsApiApiReportsTechniciansByEntityGetRequest {
    /**
     * 
     * @type {string}
     * @memberof ReportsApiApiReportsTechniciansByEntityGet
     */
    readonly from?: string

    /**
     * 
     * @type {string}
     * @memberof ReportsApiApiReportsTechniciansByEntityGet
     */
    readonly to?: string

    /**
     * 
     * @type {number}
     * @memberof ReportsApiApiReportsTechniciansByEntityGet
     */
    readonly pageNumber?: number

    /**
     * 
     * @type {number}
     * @memberof ReportsApiApiReportsTechniciansByEntityGet
     */
    readonly pageSize?: number
}

/**
 * Request parameters for apiReportsTechniciansByQualificationsGet operation in ReportsApi.
 * @export
 * @interface ReportsApiApiReportsTechniciansByQualificationsGetRequest
 */
export interface ReportsApiApiReportsTechniciansByQualificationsGetRequest {
    /**
     * 
     * @type {string}
     * @memberof ReportsApiApiReportsTechniciansByQualificationsGet
     */
    readonly from?: string

    /**
     * 
     * @type {string}
     * @memberof ReportsApiApiReportsTechniciansByQualificationsGet
     */
    readonly to?: string

    /**
     * 
     * @type {number}
     * @memberof ReportsApiApiReportsTechniciansByQualificationsGet
     */
    readonly pageNumber?: number

    /**
     * 
     * @type {number}
     * @memberof ReportsApiApiReportsTechniciansByQualificationsGet
     */
    readonly pageSize?: number
}


/**
 * Request parameters for apiReportsTechniciansByQualificationsGet operation in ReportsApi.
 * @export
 * @interface ReportsApiAnnualReportByServiceTechGetRequest
 */
export interface ReportsApiAnnualReportByServiceTechGetRequest {
    /**
     * 
     * @type {string}
     * @memberof ReportsApiAnnualReportByServiceTechGetRequest
     */
    readonly from?: string

    /**
     * 
     * @type {string}
     * @memberof ReportsApiAnnualReportByServiceTechGetRequest
     */
    readonly to?: string

    /**
     * 
     * @type {number}
     * @memberof ReportsApiAnnualReportByServiceTechGetRequest
     */
    readonly pageNumber?: number

    /**
     * 
     * @type {number}
     * @memberof ReportsApiAnnualReportByServiceTechGetRequest
     */
    readonly pageSize?: number

      /**
     * 
     * @type {string}
     * @memberof ReportsApiAnnualReportByServiceTechGetRequest
     */
      readonly search?: string


        /**
     * 
     * @type {string}
     * @memberof ReportsApiAnnualReportByServiceTechGetRequest
     */
        readonly sortingPropertyName?: string

        /**
         * 
         * @type {boolean}
         * @memberof ReportsApiAnnualReportByServiceTechGetRequest
         */
        readonly sortingIsDescending?: boolean
}



/**
 * Request parameters for apiReportsTechniciansByTrainingCenterGet operation in ReportsApi.
 * @export
 * @interface ReportsApiApiReportsTechniciansByTrainingCenterGetRequest
 */
export interface ReportsApiApiReportsTechniciansByTrainingCenterGetRequest {
    /**
     * 
     * @type {string}
     * @memberof ReportsApiApiReportsTechniciansByTrainingCenterGet
     */
    readonly from?: string

    /**
     * 
     * @type {string}
     * @memberof ReportsApiApiReportsTechniciansByTrainingCenterGet
     */
    readonly to?: string

    /**
     * 
     * @type {number}
     * @memberof ReportsApiApiReportsTechniciansByTrainingCenterGet
     */
    readonly pageNumber?: number

    /**
     * 
     * @type {number}
     * @memberof ReportsApiApiReportsTechniciansByTrainingCenterGet
     */
    readonly pageSize?: number
}

/**
 * ReportsApi - object-oriented interface
 * @export
 * @class ReportsApi
 * @extends {BaseAPI}
 */
export class ReportsApi extends BaseAPI {
    /**
     * 
     * @param {ReportsApiApiReportsCompaniesByEntityGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public apiReportsCompaniesByEntityGet(requestParameters: ReportsApiApiReportsCompaniesByEntityGetRequest = {}, options?: AxiosRequestConfig) {
        return ReportsApiFp(this.configuration).apiReportsCompaniesByEntityGet(requestParameters.from, requestParameters.to, requestParameters.pageNumber, requestParameters.pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ReportsApiApiReportsEquipmentsByCoolingSystemGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public apiReportsEquipmentsByCoolingSystemGet(requestParameters: ReportsApiApiReportsEquipmentsByCoolingSystemGetRequest = {}, options?: AxiosRequestConfig) {
        return ReportsApiFp(this.configuration).apiReportsEquipmentsByCoolingSystemGet(requestParameters.from, requestParameters.to, requestParameters.pageNumber, requestParameters.pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ReportsApiApiReportsEquipmentsByMunicipalityGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public apiReportsEquipmentsByMunicipalityGet(requestParameters: ReportsApiApiReportsEquipmentsByMunicipalityGetRequest = {}, options?: AxiosRequestConfig) {
        return ReportsApiFp(this.configuration).apiReportsEquipmentsByMunicipalityGet(requestParameters.from, requestParameters.to, requestParameters.pageNumber, requestParameters.pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ReportsApiApiReportsEquipmentsByPurposeGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public apiReportsEquipmentsByPurposeGet(requestParameters: ReportsApiApiReportsEquipmentsByPurposeGetRequest = {}, options?: AxiosRequestConfig) {
        return ReportsApiFp(this.configuration).apiReportsEquipmentsByPurposeGet(requestParameters.from, requestParameters.to, requestParameters.pageNumber, requestParameters.pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
    * 
    * @param {any} requestParameters Request parameters.
    * @param {*} [options] Override http request option.
    * @throws {RequiredError}
    * @memberof ReportsApi
    */
    public apiMVTEOAnnualReportByServiceTechnicianGet(requestParameters: any = {}, options?: AxiosRequestConfig) {
        return ReportsApiFp(this.configuration).apiMVTEOAnnualReportByServiceTechnicianGet(requestParameters.search, requestParameters.pageNumber, requestParameters.pageSize,requestParameters.sortingPropertyName, requestParameters.sortingIsDescending, options).then((request) => request(this.axios, this.basePath));
    }


    /**
    * 
    * @param {any} requestParameters Request parameters.
    * @param {*} [options] Override http request option.
    * @throws {RequiredError}
    * @memberof ReportsApi
    */
    public apiMVTEOAnnualReportOnImportExportSubstancesGet(requestParameters: any = {}, options?: AxiosRequestConfig) {
        return ReportsApiFp(this.configuration).apiMVTEOAnnualReportOnImportExportSubstancesGet(requestParameters.search, requestParameters.pageNumber, requestParameters.pageSize,requestParameters.sortingPropertyName, requestParameters.sortingIsDescending, options).then((request) => request(this.axios, this.basePath));
    }


    /**
   * 
   * @param {ReportsApiAnnualReportByServiceTechGetRequest} requestParameters Request parameters.
   * 
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReportsApi
   */
    public apiReportsAnnualReportByServiceGet(requestParameters: ReportsApiAnnualReportByServiceTechGetRequest = {}, options?: AxiosRequestConfig) {
        return ReportsApiFp(this.configuration).apiReportsAnnualReportByServiceGet(requestParameters.search, requestParameters.pageNumber, requestParameters.pageSize,requestParameters.sortingPropertyName, requestParameters.sortingIsDescending, options).then((request) => request(this.axios, this.basePath));
    }

    /**
 * 
 * @param {any} requestParameters Request parameters.
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
 * @memberof ReportsApi
 */
    public apiReportsImportExportSubstancesGet(requestParameters: any = {}, options?: AxiosRequestConfig) {
        return ReportsApiFp(this.configuration).apiReportsImportExportSubstancesGet(requestParameters.search, requestParameters.pageNumber, requestParameters.pageSize,requestParameters.sortingPropertyName, requestParameters.sortingIsDescending, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ReportsApiApiReportsRefrigerantsByEntityGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public apiReportsRefrigerantsByEntityGet(requestParameters: ReportsApiApiReportsRefrigerantsByEntityGetRequest = {}, options?: AxiosRequestConfig) {
        return ReportsApiFp(this.configuration).apiReportsRefrigerantsByEntityGet(requestParameters.from, requestParameters.to, requestParameters.pageNumber, requestParameters.pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ReportsApiApiReportsServiceCompaniesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public apiReportsServiceCompaniesGet(requestParameters: ReportsApiApiReportsServiceCompaniesGetRequest = {}, options?: AxiosRequestConfig) {
        return ReportsApiFp(this.configuration).apiReportsServiceCompaniesGet(requestParameters.from, requestParameters.to, requestParameters.pageNumber, requestParameters.pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ReportsApiApiReportsTechniciansByEntityGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public apiReportsTechniciansByEntityGet(requestParameters: ReportsApiApiReportsTechniciansByEntityGetRequest = {}, options?: AxiosRequestConfig) {
        return ReportsApiFp(this.configuration).apiReportsTechniciansByEntityGet(requestParameters.from, requestParameters.to, requestParameters.pageNumber, requestParameters.pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ReportsApiApiReportsTechniciansByQualificationsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public apiReportsTechniciansByQualificationsGet(requestParameters: ReportsApiApiReportsTechniciansByQualificationsGetRequest = {}, options?: AxiosRequestConfig) {
        return ReportsApiFp(this.configuration).apiReportsTechniciansByQualificationsGet(requestParameters.from, requestParameters.to, requestParameters.pageNumber, requestParameters.pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ReportsApiApiReportsTechniciansByTrainingCenterGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public apiReportsTechniciansByTrainingCenterGet(requestParameters: ReportsApiApiReportsTechniciansByTrainingCenterGetRequest = {}, options?: AxiosRequestConfig) {
        return ReportsApiFp(this.configuration).apiReportsTechniciansByTrainingCenterGet(requestParameters.from, requestParameters.to, requestParameters.pageNumber, requestParameters.pageSize, options).then((request) => request(this.axios, this.basePath));
    }
    /**
  * 
  * @param {any} requestParameters Request parameters.
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  * @memberof ReportsApi
  */
    public apiAnnualReportByServiceTechnicianPost(requestParameters: any = {}, options?: AxiosRequestConfig) {
        return ReportsApiFp(this.configuration).apiAnnualReportByServiceTechnicianPost(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }


    /**
   * 
   * @param {any} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReportsApi
   */
    public apiImportExportSubstancesReportPost(requestParameters: any = {}, options?: AxiosRequestConfig) {
        return ReportsApiFp(this.configuration).apiImportExportSubstancesReportPost(requestParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
   * 
   * @param {any} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CompanyBranchesApi
   */
    public apiAnnualReportByServiceTechnicianIdDelete(requestParameters: any, options?: AxiosRequestConfig) {
        return ReportsApiFp(this.configuration).apiAnnualReportByServiceTechnicianIdDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }


    /**
   * 
   * @param {any} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CompanyBranchesApi
   */
    public apiAnnualReportOnImportExportSubstancesIdDelete(requestParameters: any, options?: AxiosRequestConfig) {
        return ReportsApiFp(this.configuration).apiAnnualReportOnImportExportSubstancesIdDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
   * 
   * @param {any} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReportsApi
   */
    public apiReportsTechniciansByTrainingCenterGetById(requestParameters: any = {}, options?: AxiosRequestConfig) {
        return ReportsApiFp(this.configuration).apiReportsTechniciansByTrainingCenterGetById(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }



    /**
   * 
   * @param {any} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReportsApi
   */
    public apiAnnualReportOnImportExportSubstancesGetById(requestParameters: any = {}, options?: AxiosRequestConfig) {
        return ReportsApiFp(this.configuration).apiAnnualReportOnImportExportSubstancesGetById(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }


    /**
 * 
 * @param {any} requestParameters Request parameters.
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
 * @memberof ReportsApi
 */
    public apiReportsTechniciansByIdPut(requestParameters: any, options?: AxiosRequestConfig) {
        return ReportsApiFp(this.configuration).apiReportsTechniciansByIdPut(requestParameters.updateUserCommand, options).then((request) => request(this.axios, this.basePath));
    }


    /**
* 
* @param {any} requestParameters Request parameters.
* @param {*} [options] Override http request option.
* @throws {RequiredError}
* @memberof ReportsApi
*/
    public apiAnnualReportOnImportExportSubstancesIdPut(requestParameters: any, options?: AxiosRequestConfig) {
        return ReportsApiFp(this.configuration).apiAnnualReportOnImportExportSubstancesIdPut(requestParameters.updateUserCommand, options).then((request) => request(this.axios, this.basePath));
    }
}
