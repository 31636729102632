import { AxiosInstance, AxiosRequestConfig } from "axios";
import { showServerErrors } from "utility/showServerErrors";

const axiosIntercept = (axios: AxiosInstance) => {
  // Request interceptor
  axios.interceptors.request.use(
    (config: AxiosRequestConfig) => {
      // Modify the request config here, such as adding headers or authentication tokens
      const user = JSON.parse(localStorage.getItem("user") as any);

      if (user && user.accessToken) {
        const token = user?.accessToken;
        config.headers = {
          ...config.headers,
          Authorization: `Bearer ${token}`,
        };
      }
      return config;
    },
    (error) => {
      // Handle request errors here
      return Promise.reject(error);
    }
  );

  // Response interceptor
  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      // Handle response errors here
      showServerErrors(error);
      return Promise.reject(error);
    }
  );
};

export default axiosIntercept;
