import { Form, Input, Button } from "antd";
import Heading from "components/heading/heading";
import { AuthWrapper } from "pages/authentication/style";
import { UsersApi } from "api/api";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import openNotificationWithIcon from "utility/notification";

const SetNewPasswordPage = (props: any) => {
  const { t } = useTranslation();
  const { isOnBoardingMode = true } = props;
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const usersApi = new UsersApi();

  const handleFormSubmit = async () => {
    setLoading(true);
    const payload = form.getFieldsValue();

    try {
      await usersApi.usersSetNewPasswordPost({
        setNewPasswordRequest: payload,
      });
      openNotificationWithIcon(
        "success",
        t("set-new-password:notification.success.title", "Success"),
        t("set-new-password:notification.success.description", "Description")
      );
      navigate("/");
    } catch (error) {
      // Handle error
    } finally {
      setLoading(false);
    }
  };

  return (
    <AuthWrapper style={!isOnBoardingMode ? {
      padding: '0px !important'
    } : {}}>
      <div className="auth-contents">
        <Form
          name="setNewPassword"
          form={form}
          onFinish={handleFormSubmit}
          layout="vertical"
        >
          {isOnBoardingMode && (
            <>
              <Heading as="h3">
                {t("setNewPassowrd.title", "Welcome to")}{" "}
                <span className="color-secondary">KGH!</span>
              </Heading>
              <p style={{ marginBottom: 50 }}>
                {t(
                  "setNewPassword.description",
                  "Lorem ipsum dolor sit amet consectetur adipisicing elit. Laboriosam optio quam adipisci, nemo placeat ut ipsam vel praesentium porro commodi."
                )}
              </p>
            </>
          )}
          <Form.Item
            name="currentPassword"
            rules={[
              {
                required: true,
                message: t(
                  "setNewPassword.currentPasswordRequired",
                  "Current Password is required"
                ),
              },
            ]}
            label={t("setNewPassword.currentPasswordLabel", "Current password")}
            style={{ marginBottom: 50 }}
          >
            <Input.Password
              placeholder={t(
                "setNewPassword.currentPasswordPlaceholder",
                "Current password"
              )}
            />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: t(
                  "setNewPassword.newPasswordRequired",
                  "The password must be at least 8 characters long and contain an uppercase letter, a lowercase letter, a digit, and a special character."
                ),
                min: 8,
                pattern:
                  /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]).{8,}$/,
              },
            ]}
            label={t("setNewPassword.newPasswordLabel", "New password")}
          >
            <Input.Password
              placeholder={t(
                "setNewPassword.newPasswordPlaceholder",
                "New password"
              )}
            />
          </Form.Item>
          <Form.Item
            name="confirmPassword"
            label={t("setNewPassword.confirmPasswordLabel", "Confirm password")}
            rules={[
              {
                message: t(
                  "setNewPassword.confirmPasswordRequired",
                  "Confirm password is required"
                ),
                required: true,
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(
                      t(
                        "setNewPassword.confirmPasswordMismatch",
                        "The new password that you entered does not match!"
                      )
                    )
                  );
                },
              }),
            ]}
          >
            <Input.Password
              placeholder={t(
                "setNewPassword.confirmPasswordPlaceholder",
                "Confirm password"
              )}
            />
          </Form.Item>
          <Form.Item style={{ marginTop: 50 }}>
            <Button
              className="btn-signin"
              htmlType="submit"
              loading={loading}
              type="primary"
              size="large"
            >
              {t("setNewPassword.saveButton", "Save")}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </AuthWrapper >
  );
};

export default SetNewPasswordPage;
